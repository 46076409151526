import * as Sentry from "@sentry/react";

interface MonitoringService {
  init: () => void;
  logError: (error: Error) => void;
  logMessage: (message: string, id?: string) => void;
}

export const MonitoringService: MonitoringService = {
  init: () => {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      environment: process.env.NODE_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },

  logError: Sentry.captureException,
  logMessage: (text: string, correlationId?: string) => {
    Sentry.withScope((scope) => {
      scope.setTag("correlation_id", correlationId);
      Sentry.captureMessage(text);
    });
  },
};
