import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Page } from "@/application/ui/lib/Page.tsx";
import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage.tsx";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage.tsx";

import { theme } from "../../theme/theme";
import { InformationCard } from "./components/WritingTaskReportCard/InformationCard";
import { useReportDashboard } from "./hooks/useReportDashboard";

export const ReportDashboard = () => {
  const { reportDashboard, isError, isFetching } = useReportDashboard();
  const { t } = useTranslation();

  if (isError) {
    return (
      <Page>
        <Title>{t("accessDashboard")}</Title>
        <TimeoutPage />
      </Page>
    );
  }

  if (isFetching) {
    return (
      <Page>
        <Title>{t("accessDashboard")}</Title>
        <LoadingPage />
      </Page>
    );
  }

  return (
    <Page>
      <Title>{t("accessDashboard")}</Title>
      {reportDashboard?.groups.map((group) => (
        <Group key={group.id + group.name}>
          <GroupHeader>
            <GroupTitle id={group.id}>{group.name}</GroupTitle>
          </GroupHeader>
          <WritingTasks aria-label={t("writingTaskListing")}>
            {group.writingTasks.map((writingTask) => {
              return (
                <NavLink
                  aria-label={writingTask.name}
                  key={"navlink - " + writingTask.id}
                  to={`${group.id}/${writingTask.id}`}
                >
                  <InformationCard
                    taskId={writingTask.id}
                    key={writingTask.id}
                  />
                </NavLink>
              );
            })}
          </WritingTasks>
        </Group>
      ))}
    </Page>
  );
};

const Group = styled.section`
  padding: 10px 40px;
  gap: 24px;
`;

const GroupHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 23px;
`;

const GroupTitle = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${theme.typography.lg.medium}
  color: ${theme.colors.gray[900]};
  gap: 8px;
`;
const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
`;
