import { cloneDeep, isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import type { WritingTaskAssignments } from "@/application/domain/WritingTaskAssignments.ts";
import { LayoutContainer, Page } from "@/application/ui/assignment.layout.ts";
import { Breadcrumbs } from "@/application/ui/lib/Beadcrumbs.tsx";
import { NavigationDropdown } from "@/application/ui/lib/inputs/NavigationDropdown";
import { AssignmentSection } from "@/application/ui/pages/Revision/AssignmentSection.tsx";
import { Sidebar } from "@/application/ui/pages/Revision/components/Sidebar/Sidebar.tsx";
import { useSelectedRevisableAssignment } from "@/application/ui/pages/Revision/useSelectedRevisableAssignment.ts";
import { ReactComponent as AlertIcon } from "@/assets/alert-triangle.svg";

import { AssignmentClient } from "../../../../client/AssignmentClient";
import { tooltipStyle } from "../../../lib/tooltip/tooltipStyle";
import { theme } from "../../../theme/theme";
import { RevisableAndGradableAssignment } from "../service/RevisableAndGradableAssignment";

type AssignmentsFoundRevisionPageProps = {
  writingTaskWithAssignments: WritingTaskAssignments;
  studentId?: string;
  onAssignmentUpdated: () => void;
};

export const AssignmentsFoundRevisionPage = ({
  writingTaskWithAssignments,
  studentId,
  onAssignmentUpdated,
}: AssignmentsFoundRevisionPageProps) => {
  const { t } = useTranslation();
  const {
    revisableAssignment,
    currentStudent,
    orderedStudents,
    selectStudent,
    setRevisableAssignment,
  } = useSelectedRevisableAssignment(writingTaskWithAssignments, studentId);
  const [hasFlagged, setHasFlagged] = useState<boolean>(
    revisableAssignment.assignment.flagged,
  );

  const originalRevisableAssignment = RevisableAndGradableAssignment.init(
    writingTaskWithAssignments
      .getLastAssignmentForEachStudent()
      .getFor(currentStudent),
    writingTaskWithAssignments.writingTask.gradingScaleType,
  );

  const flagAssignment = () => {
    void AssignmentClient.flagAssignment(revisableAssignment.assignment.id);
    setHasFlagged(true);
  };

  return (
    <LayoutContainer>
      <Page>
        <Header>
          <Left>
            <Breadcrumbs
              entries={[
                {
                  label: writingTaskWithAssignments.homeroom.name,
                  link: "/",
                },
                { label: writingTaskWithAssignments.writingTask.name },
              ]}
            />
          </Left>
          <NavigationDropdown
            blurOnDisable
            options={orderedStudents.map((student) => ({
              label: student.name.fullName(),
              value: student.id,
            }))}
            onChange={(newValue: string | null) => {
              if (newValue) {
                selectStudent(newValue);
              }
            }}
            large
            name="students"
            value={currentStudent}
            previousButtonTitle={t("previousStudent")}
            nextButtonTitle={t("nextStudent")}
          />
          <FlagContainer>
            <FlagButton
              id="flag-assignment"
              disabled={hasFlagged}
              onClick={() => flagAssignment()}
            >
              <AlertIcon
                width={20}
                height={20}
                style={{
                  stroke: hasFlagged
                    ? theme.colors.gray[200]
                    : theme.colors.gray[700],
                }}
              />
            </FlagButton>
          </FlagContainer>
          <Tooltip
            anchorSelect="#flag-assignment"
            content={t("flag")}
            place="top"
            hidden={hasFlagged}
            offset={10}
            style={{
              ...tooltipStyle,
            }}
          />
        </Header>

        <AssignmentSection
          currentStudent={currentStudent}
          revisedAssignmentStatus={revisableAssignment.status}
          revisedAssignmentContentState={
            revisableAssignment.revisedAssignmentContentState
          }
          comments={cloneDeep(revisableAssignment.assignment.comments)}
          onContentStateChange={(revisedAssignmentContentState) =>
            setRevisableAssignment(
              revisableAssignment.updateContentState(
                revisedAssignmentContentState,
              ),
            )
          }
          onCommentsChange={(comments) =>
            setRevisableAssignment(revisableAssignment.updateComments(comments))
          }
        />
      </Page>

      <Sidebar
        revisableAssignment={revisableAssignment}
        evaluatedCriteria={
          writingTaskWithAssignments.writingTask.evaluatedCriteria
        }
        onChangeRevisableAssignment={setRevisableAssignment}
        onUpdateSuccess={onAssignmentUpdated}
        hasChanged={!isEqual(revisableAssignment, originalRevisableAssignment)}
      />
    </LayoutContainer>
  );
};

const FlagButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${theme.radius.round};
  aspect-ratio: 1;
  background-color: ${theme.colors.gray[700]}

  &:disabled {
    cursor: default;
  }
`;

const FlagContainer = styled.div`
  padding: 8px;

  display: flex;
  height: 24px;
  width: 24px;

  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.gray[200]};
`;

const Left = styled.div`
  display: flex;
  flex: 1;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 32px;
  align-items: center;

  background: ${theme.colors.grayBlue[50]};
  border-bottom: 1px solid ${theme.colors.gray[200]};
`;
