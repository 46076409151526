import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { WritingTaskAssignmentStatuses } from "@/application/domain/WritingTask/WritingTaskAssignmentStatuses.ts";

import { theme } from "../../../../../theme/theme.ts";

export interface ProgressBarStatuses {
  key: WritingTaskAssignmentStatuses;
  color: string;
  bgcolor: string;
}

export interface StatusesValues {
  key: WritingTaskAssignmentStatuses;
  value: number;
}

//TODO: rework to use AssignmentStatus
const statusesSettings: ProgressBarStatuses[] = [
  {
    key: WritingTaskAssignmentStatuses.NOT_SUBMITTED,
    bgcolor: theme.colors.gray[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.REDACTING,
    bgcolor: theme.colors.orangeDark[400],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.SUBMITTED,
    bgcolor: theme.colors.warning[300],
    color: theme.colors.base.black,
  },
  {
    key: WritingTaskAssignmentStatuses.AI_ERROR,
    bgcolor: theme.colors.error[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.CORRECTED,
    bgcolor: theme.colors.indigo[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.VALID,
    bgcolor: theme.colors.success[500],
    color: theme.colors.base.white,
  },
];

interface ProgressBarProps {
  statuses: StatusesValues[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BarLayout = styled.div`
  display: flex;
  height: 24px;
  margin-bottom: 10px;
`;

const BaseProgressBar = styled.div`
  display: flex;
  flex-grow: 1;
  height: 24px;
  > *:first-child:only-child {
    border-radius: ${theme.radius.xsmall};
  }
  > *:first-child:not(:only-child) {
    border-radius: ${theme.radius.xsmall} 0 0 ${theme.radius.xsmall};
  }
  > *:last-child:not(:only-child) {
    border-radius: 0 ${theme.radius.xsmall} ${theme.radius.xsmall} 0;
  }
`;

const StatusPartialBar = styled.div<{ $bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgcolor};
  ${theme.typography.xs.regular}
`;

const StatusValue = styled.div<{ $color: string }>`
  color: ${(props) => props.$color};
  ${theme.typography.xs.regular}
`;

const ProgressTotal = styled.div`
  ${theme.typography.xs.regular}
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const LegendIcon = styled.div<{ $color: string }>`
  width: 7px;
  height: 7px;
  border-radius: ${theme.radius.round};
  margin-right: 8px;
  background-color: ${(props) => props.$color};
  align-self: center;
`;

const StatusLegends = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LegendLabel = styled.div`
  ${theme.typography.xs.regular}
`;

const StatusLegend = styled.div`
  display: flex;
  flex: 33%;
`;

export const WritingTaskProgressBar = (props: ProgressBarProps) => {
  return (
    <Container>
      <BarLayout>
        <StatusesBar {...props} />
      </BarLayout>
      <ProgressBarLegend />
    </Container>
  );
};

const StatusesBar = ({ statuses }: ProgressBarProps) => {
  const { t } = useTranslation();
  const total = statuses.reduce((acc, status) => acc + status.value, 0);

  const statusesToDisplay = statuses.filter((x) => x.value > 0);
  return (
    <>
      <BaseProgressBar role="grid">
        {statusesToDisplay.map((status, index) => (
          <StatusPartialBar
            role="gridcell"
            aria-label={`${t(status.key)} : ${status.value}`}
            $bgcolor={
              statusesSettings.find((x) => x.key === status.key)!.bgcolor
            }
            key={index}
            style={{ width: `${(status.value / total) * 100}%` }}
          >
            <StatusValue
              $color={statusesSettings.find((x) => x.key === status.key)!.color}
            >
              {status.value}
            </StatusValue>
          </StatusPartialBar>
        ))}
      </BaseProgressBar>
      <ProgressTotal>{total}</ProgressTotal>
    </>
  );
};

const ProgressBarLegend = () => {
  const { t } = useTranslation();

  return (
    <StatusLegends>
      {statusesSettings.map((status, index) => (
        <StatusLegend key={index}>
          <LegendIcon $color={status.bgcolor} />
          <LegendLabel>{t(status.key)}</LegendLabel>
        </StatusLegend>
      ))}
    </StatusLegends>
  );
};
