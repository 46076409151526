import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { ConsultationAssignment } from "@/application/domain/Assignment.ts";
import {
  Header,
  LayoutContainer,
  Page,
} from "@/application/ui/assignment.layout.ts";
import { AudioPlayer } from "@/application/ui/lib/recording/components/AudioPlayer.tsx";
import { AssignmentSection } from "@/application/ui/pages/Revision/AssignmentSection.tsx";
import { ReadOnlyGradingScale } from "@/application/ui/pages/Revision/components/GradingScale/ReadOnlyGradingScale.tsx";
import { CommentTextArea } from "@/application/ui/pages/Revision/components/Sidebar/CommentTextArea/CommentTextArea.tsx";
import { ErrorSummary } from "@/application/ui/pages/Revision/components/Sidebar/ErrorSummary/ErrorSummary.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";
import { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { ReadOnlyGlobalGrade } from "@/application/ui/pages/StudentAssignment/ReadOnlyGlobalGrade.tsx";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";

import { theme } from "../../theme/theme";
import { AssignmentsProvider } from "../Revision/RevisionStates/AssignmentsProvider";

type Props = {
  assignment: ConsultationAssignment;
};

export const StudentAssignmentPageContent = ({ assignment }: Props) => {
  const { t } = useTranslation();

  const revisedAssignmentContentState = useMemo(
    () => RevisedAssignmentContentState.init(assignment),
    [assignment],
  );

  return (
    revisedAssignmentContentState && (
      <AssignmentsProvider>
        <LayoutContainer>
          <Page>
            <Header className="header">
              <WritingTaskInfo>
                <WritingTaskTitle>
                  {assignment?.writingTaskInformation.name}
                </WritingTaskTitle>
                <WritingTaskInstructions>
                  {assignment?.writingTaskInformation.instructions}
                </WritingTaskInstructions>
              </WritingTaskInfo>
            </Header>
            <AssignmentSection
              revisedAssignmentContentState={revisedAssignmentContentState}
              comments={assignment.comments}
            />
          </Page>

          <Container>
            {assignment.recordUrl && (
              <SidebarBox title={t("audioComment")}>
                <AudioPlayer url={assignment.recordUrl} />
              </SidebarBox>
            )}

            <CommentTextArea
              readonly
              key={assignment.id}
              comment={assignment.comment || ""}
            />
            <ReadOnlyGradingScale assignment={assignment} />
            <ErrorSummary
              revisedAssignmentContentState={revisedAssignmentContentState}
              commentsCount={assignment.comments.length}
            />

            <ReadOnlyGlobalGrade
              globalGrade={assignment?.globalGrade || null}
            />
          </Container>
        </LayoutContainer>
      </AssignmentsProvider>
    )
  );
};

const WritingTaskInfo = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "16px 40px",
  gap: 8,
});
const WritingTaskTitle = styled.div`
  ${theme.typography.sm.medium}
`;
const WritingTaskInstructions = styled.div`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]}
`;

const Container = styled.div`
  background-color: ${theme.colors.gray[100]};
  border-left: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadowAndBlur.xxlarge};
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  padding: 24px;
  height: 100vh;
  position: sticky;
  overflow-y: auto;
  right: 0;
  gap: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${(props) => scrollbarStyle(props.theme)}
`;
