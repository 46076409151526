import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";

import type { TaskType } from "@/application/domain/TaskType.ts";
import type { RedactionType } from "@/application/domain/WritingTask/RedactionType.ts";

interface Props {
  id: string;
  name: string;
  instructions: string;
  taskType: TaskType;
  redactionType: RedactionType;
  gradingScaleType: GradingScaleType;
  evaluatedCriteria: EvaluationCriteria;
  isOpen: boolean;
  numberOfWords?: number;
}

export class WritingTask {
  id;
  name;
  instructions;
  taskType;
  redactionType;
  gradingScaleType;
  evaluatedCriteria;
  isOpen;
  numberOfWords?;

  constructor({
    id,
    name,
    instructions,
    taskType,
    redactionType,
    gradingScaleType,
    evaluatedCriteria,
    isOpen,
    numberOfWords,
  }: Props) {
    this.id = id;
    this.name = name;
    this.instructions = instructions;
    this.taskType = taskType;
    this.redactionType = redactionType;
    this.gradingScaleType = gradingScaleType;
    this.evaluatedCriteria = evaluatedCriteria;
    this.isOpen = isOpen;
    this.numberOfWords = numberOfWords;
  }
}
