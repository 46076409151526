import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { ellipsisStyle } from "../../../theme/Ellipsis";
import { theme } from "../../../theme/theme";

const TaskInformation = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const TaskType = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  color: ${theme.colors.gray[500]};
  ${theme.typography.xs.bold}
`;

const TaskName = styled.h3`
  flex: 1;
  color: ${theme.colors.gray[900]};
  ${theme.typography.md.medium}
  ${ellipsisStyle({ numberOfRows: 1, lineHeightInPixels: 24 })}
`;

const TaskInstructions = styled.h3`
  flex: 1;
  color: ${theme.colors.gray[600]};
  ${theme.typography.xs.regular}
  ${ellipsisStyle({ numberOfRows: 2, lineHeightInPixels: 18 })}
`;

export const WritingTaskInformation = ({ children }: PropsWithChildren) => {
  return <TaskInformation>{children}</TaskInformation>;
};

WritingTaskInformation.Type = TaskType;
WritingTaskInformation.Instructions = TaskInstructions;
WritingTaskInformation.Name = TaskName;
