import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Bracket } from "@/application/domain/GlobalRanking";
import { BracketRangePresenter } from "@/application/ui/pages/GradeReport/presenters/BracketRangePresenter.ts";

import { theme } from "../../../theme/theme";

interface Props {
  bracket: Bracket;
  isLetterMode: boolean;
  isShowingStudents: boolean;
  selectStudent: (studentId: string) => void;
}

export const GlobalRankingBracket = ({
  bracket,
  isLetterMode,
  isShowingStudents,
  selectStudent,
}: Props) => {
  const { t } = useTranslation();

  const [height, setHeight] = useState(0);

  const studentListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShowingStudents)
      setHeight(studentListRef.current?.getBoundingClientRect().height ?? 0);
    else setHeight(0);
  }, [isShowingStudents, bracket]);

  const isNegativeBracket = bracket.range.max < 60;
  const studentCount = bracket.students.length;
  const studentText = t("student", { count: studentCount });

  const rangePresenter = new BracketRangePresenter(bracket.range, t);

  return (
    <BracketContainer
      data-testid={`bracket${bracket.range.letter}`}
      className={clsx({ isMajority: bracket.isMajority, isNegativeBracket })}
    >
      <PercentageRange>
        {rangePresenter.display(isLetterMode ? "letter" : "percent")}
      </PercentageRange>

      <StudentPercentageBarContainer>
        <StudentPercentageBarFilling
          data-testid="percentBar"
          $percentage={bracket.percentage}
          className={clsx({
            isMajority: bracket.isMajority,
            isNegativeBracket,
          })}
        />
      </StudentPercentageBarContainer>

      <NumberOfStudents>{`${studentText}`}</NumberOfStudents>

      <AnimatedStudentListContainer style={{ height }}>
        <StudentList
          ref={studentListRef}
          $isShowingStudents={isShowingStudents}
        >
          {isShowingStudents &&
            bracket.students.map((student) => (
              <StudentName
                key={student.name.fullName()}
                title={student.name.fullName()}
                onClick={() => selectStudent(student.id)}
              >
                {student.name.fullName()}
              </StudentName>
            ))}
        </StudentList>
      </AnimatedStudentListContainer>
    </BracketContainer>
  );
};

const BracketContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: ${theme.radius.large};
  padding: 12px 24px;
  gap: 12px;
  border: ${theme.borders.primary};
  background-color: ${theme.colors.base.white};

  &.isMajority {
    background-color: ${theme.colors.indigo[50]};
    border-color: ${theme.colors.indigo[300]};
  }

  &.isMajority.isNegativeBracket {
    background-color: ${theme.colors.error[50]};
    border-color: ${theme.colors.error[500]};
  }

  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`;

const PercentageRange = styled.p`
  text-align: center;
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.bold}
`;

const StudentPercentageBarContainer = styled.div`
  border-radius: ${theme.radius.large};
  height: 10px;
  align-self: stretch;
  background-color: ${theme.colors.gray[200]};
`;

const StudentPercentageBarFilling = styled.div<{ $percentage: number }>`
  border-radius: ${theme.radius.large};
  height: 100%;
  width: ${(props) => props.$percentage || 0}%;
  background-color: ${theme.colors.gray[600]};

  &.isMajority {
    background-color: ${theme.colors.indigo[500]};
  }

  &.isMajority.isNegativeBracket {
    background-color: ${theme.colors.error[500]};
  }
`;

const NumberOfStudents = styled.p`
  text-align: center;
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.medium}
`;

const AnimatedStudentListContainer = styled.div`
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;

const StudentList = styled.div<{ $isShowingStudents: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const StudentName = styled.p`
  padding: 16px 24px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.regular}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
