import type { EvaluationCriterion } from "@api/grade/model/GradedCriteria";
import { useTranslation } from "react-i18next";

import { Dropdown } from "../../../lib/inputs/Dropdown";

export type Props = {
  criterion: EvaluationCriterion[];
  onChange?: (newValue: string | null) => void;
  value?: string | undefined;
};

export const EvaluatedCriteriaDropdown = ({
  criterion,
  onChange,
  value,
}: Props) => {
  const { t } = useTranslation();

  const criteriaOptions = criterion.map((criteria) => {
    return { label: t(criteria), value: criteria.toString() };
  });
  if (criteriaOptions.length !== 1)
    criteriaOptions.unshift({
      label: t("writingTaskGradeReport.all"),
      value: "all",
    });

  return (
    <Dropdown
      selectedItemLabelPrefix={`${t("writingTaskGradeReport.criterias")} :`}
      options={criteriaOptions}
      defaultValue={criteriaOptions[0].value}
      name="criterionFilter"
      onChange={onChange}
      value={value ?? ""}
    />
  );
};
