export type Character = {
  lowercase: string;
  uppercase: string;
};

export const characters: Character[] = [
  { lowercase: "à", uppercase: "À" },
  { lowercase: "â", uppercase: "Â" },
  { lowercase: "ç", uppercase: "Ç" },
  { lowercase: "è", uppercase: "È" },
  { lowercase: "é", uppercase: "É" },
  { lowercase: "ê", uppercase: "Ê" },
  { lowercase: "ë", uppercase: "Ë" },
  { lowercase: "î", uppercase: "Î" },
  { lowercase: "ï", uppercase: "Ï" },
  { lowercase: "ô", uppercase: "Ô" },
  { lowercase: "û", uppercase: "Û" },
  { lowercase: "ù", uppercase: "Ù" },
  { lowercase: "'", uppercase: "'" },
  { lowercase: ":", uppercase: ":" },
  { lowercase: ";", uppercase: ";" },
  { lowercase: "-", uppercase: "-" },
  { lowercase: ",", uppercase: "," },
  { lowercase: "(", uppercase: "(" },
  { lowercase: ")", uppercase: ")" },
  { lowercase: "«", uppercase: "«" },
  { lowercase: "»", uppercase: "»" },
  { lowercase: "!", uppercase: "!" },
  { lowercase: "?", uppercase: "?" },
];

export const casingCharacter: Character = {
  lowercase: "ABC",
  uppercase: "abc",
};
