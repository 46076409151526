import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import type { SubtypeDistribution } from "@/application/domain/GlobalRanking.ts";

import { theme } from "../../../theme/theme";
import { useFlags } from "../../App/FlagsProvider";
import { SubcriteriaPopup } from "./SubcriteriaPopup";

interface Props {
  subtype: SubtypeDistribution;
  total: number;
  isShowingStudents: boolean;
  isStudentMode: boolean;
  selectStudent: (studentId: string) => void;
}

export const SubCriterionCategory = ({
  subtype,
  total,
  isShowingStudents,
  isStudentMode,
  selectStudent,
}: Props) => {
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState(false);
  const [height, setHeight] = useState(0);
  const { spavoc } = useFlags();

  const studentListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShowingStudents)
      setHeight(studentListRef.current?.getBoundingClientRect().height ?? 0);
    else setHeight(0);
  }, [isShowingStudents, subtype]);

  const studentCount = subtype.userCounts.length;
  const studentText = t("student", { count: studentCount });

  return (
    <BracketContainer
      className="embla__slide"
      data-testid={subtype.subtypeName}
    >
      {spavoc ? (
        <Popover
          onClickOutside={() => setPopupVisible(false)}
          isOpen={popupVisible}
          positions={["right", "left", "top"]}
          padding={10}
          content={
            <SubcriteriaPopup
              onClose={() => setPopupVisible(false)}
              subcriteria={subtype.subtypeName}
            />
          }
        >
          <div onClick={() => setPopupVisible(true)}>
            <Title className={clsx({ selected: popupVisible })}>
              {t(subtype.subtypeName)}
            </Title>
            <Title className={clsx({ selected: popupVisible })}>
              {t(`subcriteria:${subtype.subtypeName}.label`)}
            </Title>
          </div>
        </Popover>
      ) : (
        <>
          <Title>{t(subtype.subtypeName)}</Title>
          <Title>{t(`subcriteria:${subtype.subtypeName}.label`)}</Title>
        </>
      )}

      <StudentPercentageBarContainer>
        <StudentPercentageBarFilling
          data-testid="percentBar"
          $percentage={(subtype.total / total) * 100}
        />
      </StudentPercentageBarContainer>
      <TotalNumberOfMistake>
        {t("subcriteriaClassment.error", { count: subtype.total })}
      </TotalNumberOfMistake>

      {!isStudentMode && (
        <NumberOfStudents>{`${studentText}`}</NumberOfStudents>
      )}

      {!isStudentMode && (
        <AnimatedStudentListContainer style={{ height }}>
          <StudentList
            ref={studentListRef}
            $isShowingStudents={isShowingStudents}
          >
            {isShowingStudents &&
              subtype.userCounts?.map((userCount) => (
                <StudentInfo
                  key={userCount.studentName.fullName() + userCount.count}
                >
                  <StudentName onClick={() => selectStudent(userCount.id)}>
                    {userCount.studentName.fullName()}
                  </StudentName>
                  <StudentCount>{userCount.count}</StudentCount>
                </StudentInfo>
              ))}
          </StudentList>
        </AnimatedStudentListContainer>
      )}
    </BracketContainer>
  );
};

const BracketContainer = styled.div`
  flex: 0 0 calc(20% - 50px - 64px / 5);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${theme.radius.large};
  padding: 12px 24px;
  gap: 12px;

  background-color: ${theme.colors.base.white};
  border: ${theme.borders.primary};

  box-shadow:
    0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px 0 rgba(16, 24, 40, 0.06);
`;

const Title = styled.p`
  text-align: center;
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.bold}
  &:hover {
    color: ${theme.colors.gray[800]};
  }
  &.selected {
    color: ${theme.colors.primary[600]};
    text-decoration: underline;
  }
`;

const TotalNumberOfMistake = styled.p`
  text-align: center;
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.medium}
`;

const StudentPercentageBarContainer = styled.div`
  border-radius: ${theme.radius.large};
  height: 10px;
  align-self: stretch;
  background-color: ${theme.colors.gray[200]};
`;

const StudentPercentageBarFilling = styled.div<{
  $percentage: number;
}>`
  border-radius: ${theme.radius.large};
  height: 100%;
  width: ${(props) => props.$percentage || 0}%;
  background-color: ${theme.colors.gray[600]};
`;

const NumberOfStudents = styled.p`
  text-align: center;
  width: fit-content;
  height: fit-content;
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.medium}
  padding: 6px 12px;
  border-radius: ${theme.radius.round};
  background-color: ${theme.colors.gray[100]};
`;

const AnimatedStudentListContainer = styled.div`
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;

const StudentList = styled.div<{ $isShowingStudents: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
`;
const StudentName = styled.p`
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.regular}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StudentCount = styled.p`
  color: ${theme.colors.gray[600]};
  ${theme.typography.sm.regular}
`;
