import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";

import { ClosedActions } from "./ClosedActions.tsx";
import { DraftActions } from "./DraftActions.tsx";
import { OpenActions } from "./OpenActions.tsx";

interface Props {
  writingTask: TeacherWritingTask;
  homeroomId: string;
}

export const CardActionsFactory = ({ writingTask, homeroomId }: Props) => {
  const canCloseTask =
    writingTask.canClose && writingTask.typeStatus === "open";

  switch (writingTask.typeStatus) {
    case "closed":
      return (
        <ClosedActions
          homeroomId={homeroomId}
          writingTask={writingTask}
          canCloseTask={canCloseTask}
        />
      );
    case "open":
      return (
        <OpenActions
          homeroomId={homeroomId}
          writingTask={writingTask}
          canCloseTask={canCloseTask}
        />
      );
    case "draft":
      return <DraftActions writingTask={writingTask} />;
    default:
      break;
  }
};
