import type { AuthenticationResult } from "@azure/msal-browser";
import { EventType, PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "@/application/authentication/msal/authConfig.ts";

export const InitMsalInstance = (
  tenantId: string,
  applicationId: string,
): PublicClientApplication => {
  const msalInstance = new PublicClientApplication(
    msalConfig(tenantId, applicationId),
  );
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount());
  }

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      event.payload &&
      (event.payload as AuthenticationResult)["account"]
    ) {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};
