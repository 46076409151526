import { EvaluationCriterion } from "@api/grade/model/GradedCriteria";

import { SubCriterionDistribution } from "@/application/ui/pages/GradeReport/components/SubCriterionDistribution";
import { useSubCriterionRanking } from "@/application/ui/pages/GradeReport/hooks/useSubCriterionRanking";

import { useGrades } from "../GradeStates/useGrades";

export type SubCriterionDistributionProps = {
  currentStudentId?: string;
  selectStudent: (studentId: string) => void;
};

const CRITERIA_NO_SUB = [
  EvaluationCriterion.ADAPTATION.toString(),
  EvaluationCriterion.COHERENCE.toString(),
];

export const SubCriterionChart = ({
  currentStudentId,
  selectStudent,
}: SubCriterionDistributionProps) => {
  const { gradeContextDto, taskId } = useGrades();
  const { isStudentMode, selectedCriteria } = gradeContextDto;

  const { correctionDistribution } = useSubCriterionRanking(
    taskId,
    selectedCriteria,
    isStudentMode && !!currentStudentId ? currentStudentId : undefined,
  );

  if (CRITERIA_NO_SUB.includes(selectedCriteria)) {
    return;
  }

  return (
    <SubCriterionDistribution
      correctionDistribution={correctionDistribution}
      isStudentMode={isStudentMode}
      selectStudent={selectStudent}
    />
  );
};
