import styled from "styled-components";

import { ReactComponent as AnimatedWaveform } from "@/assets/animated-waveform.svg";

import { theme } from "../../../theme/theme";

export const RecordingBox = styled.div`
  flex: 1;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: ${theme.radius.round};
  border: 2px solid ${theme.colors.primary["700"]};
  background: ${theme.colors.base.white};

  &.completed {
    border-color: transparent;
    background-color: ${theme.colors.primary["700"]};
    color: ${theme.colors.base.white};
  }
`;

export const RecordingLabel = styled.span`
  color: ${theme.colors.gray[500]};
  ${theme.typography.sm.regular}
  &.completed {
    color: ${theme.colors.base.white};
  }
`;

export const ControlButton = styled.button`
  display: flex;
  cursor: pointer;

  color: ${theme.colors.primary["700"]};

  &.completed {
    color: ${theme.colors.base.white};
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

export const RecordingWaveform = styled(AnimatedWaveform)`
  color: ${theme.colors.primary[700]};
`;
