import type { FlagsConfig } from "@api/FlagsConfig";

import { HttpClient } from "../../client/HttpClient";

export type AuthConfig = {
  tenantId: string;
  applicationId: string;
  entraAuthScope: string;
};

export const EnvConfigClient = {
  authConfig: async () => {
    const { data } = await HttpClient.get<AuthConfig>(`/envConfig/auth`);
    return data;
  },

  flagsConfig: async () => {
    const { data } = await HttpClient.get<FlagsConfig>(`/envConfig/flags`);
    return data;
  },
};
