import { useMutation, useQueryClient } from "@tanstack/react-query";

import { TeacherDashboardClient } from "@/application/client/TeacherDashboardClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

interface CreateWritingTask {
  formData: FormData;
  homeroomIds: string[];
}

export const useWritingTaskCreation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createWritingTask, isLoading } = useMutation({
    mutationFn: ({ homeroomIds, formData }: CreateWritingTask) =>
      TeacherDashboardClient.createWritingTask(homeroomIds, formData),

    onSuccess: () => queryClient.invalidateQueries(TeacherQueryKeys.dashboard),
  });

  return { createWritingTask, isLoading };
};
