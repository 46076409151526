import clsx from "clsx";
import styled from "styled-components";

import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { ReactComponent as ChevronLeft } from "@/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@/assets/chevron-right.svg";

import { StudentNameVisibilityIcon } from "../../pages/Revision/components/Sidebar/ErrorSummary/RevisionVisibilityIcons";
import { useAssignments } from "../../pages/Revision/RevisionStates/useAssignments";
import { theme } from "../../theme/theme";
import type { DropdownProps } from "./Dropdown";
import { Dropdown } from "./Dropdown";

interface Props extends DropdownProps {
  previousButtonTitle?: string;
  nextButtonTitle?: string;
  onChange: (newValue: string | null) => void;
  large?: boolean;
}

export const NavigationDropdown = ({
  previousButtonTitle,
  nextButtonTitle,
  options,
  value,
  onChange,
  large,
  ...rest
}: Props) => {
  let currentIndex = options.findIndex((option) => option.value === value);
  currentIndex = currentIndex >= 0 ? currentIndex : 0;

  const selectPrevious = () => {
    onChange(options[currentIndex - 1].value);
  };

  const selectNext = () => {
    onChange(options[currentIndex + 1].value);
  };

  const { showStudentName } = useAssignments();

  return (
    <Navigation>
      <NavigationLeftButton
        size="sm"
        disabled={currentIndex < 1}
        variant="contained"
        hierarchy="neutral"
        onClick={selectPrevious}
        title={previousButtonTitle}
      >
        <ChevronLeft />
      </NavigationLeftButton>
      <StyledDropdown
        className={clsx({ large })}
        options={options}
        onChange={onChange}
        value={value}
        size="md"
        disabled={!showStudentName}
        blurOnDisable
        icon={StudentNameVisibilityIcon()}
        edges="straight"
        {...rest}
      />
      <NavigationRightButton
        disabled={currentIndex === -1 || currentIndex >= options.length - 1}
        size="sm"
        variant="contained"
        hierarchy="neutral"
        onClick={selectNext}
        title={nextButtonTitle}
      >
        <ChevronRight />
      </NavigationRightButton>
    </Navigation>
  );
};

const StyledDropdown = styled(Dropdown)`
  width: 250px;

  &.large {
    width: 300px;
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

const NavigationLeftButton = styled(IconButton)`
  border-radius: ${theme.radius.large} 0 0 ${theme.radius.large};
  padding: 10px 13px !important;
`;

const NavigationRightButton = styled(IconButton)`
  border-radius: 0 ${theme.radius.large} ${theme.radius.large} 0;
  padding: 10px 13px !important;
`;
