import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { theme } from "../../../../theme/theme";

interface Props {
  title: string;
  value?: string;
}

export const InformationField = ({ title, value }: Props) => {
  return (
    <InformationCell>
      <InformationTitle>{title}</InformationTitle>
      <InformationValue>{value || <Skeleton />}</InformationValue>
    </InformationCell>
  );
};

const InformationCell = styled.div`
  padding-bottom: 16px;
`;

const InformationTitle = styled.p`
  ${theme.typography.xs.medium}
  color: ${theme.colors.gray[500]};
`;

const InformationValue = styled.p`
  width: auto;
  ${theme.typography.xs.bold}
  color: ${theme.colors.gray[500]};
`;
