import { Page } from "@/application/ui/lib/Page.tsx";
import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { RevisionsStatePage } from "@/application/ui/pages/RevisionRequest/RevisionsStatePage.tsx";

export const RevisionRequestPage = () => {
  return (
    <Page>
      <Title>Test</Title>
      <RevisionsStatePage />
    </Page>
  );
};
