import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type {
  AssignmentStatus,
  TeacherComment,
} from "@/application/domain/Assignment.ts";
import { AssignmentEditor } from "@/application/ui/pages/Revision/AssignmentEditor.tsx";
import type { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { theme } from "../../theme/theme";

interface AssignmentSectionProps {
  currentStudent?: string;
  revisedAssignmentStatus?: AssignmentStatus;
  revisedAssignmentContentState: RevisedAssignmentContentState;
  comments: TeacherComment[];
  onContentStateChange?: (
    revisedAssignmentContentState: RevisedAssignmentContentState,
  ) => void;
  onCommentsChange?: (comments: TeacherComment[]) => void;
}

export const AssignmentSection = ({
  currentStudent,
  revisedAssignmentContentState,
  revisedAssignmentStatus,
  comments,
  onContentStateChange,
  onCommentsChange,
}: AssignmentSectionProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Container>
        <AssignmentEditor
          currentStudent={currentStudent}
          revisedAssignmentContentState={revisedAssignmentContentState}
          revisedAssignmentStatus={revisedAssignmentStatus}
          comments={comments}
          onContentStateChange={(revisedAssignmentContentState) =>
            onContentStateChange?.(revisedAssignmentContentState)
          }
          onCommentsChange={(comments) => onCommentsChange?.(comments)}
        />
      </Container>

      <Statistics>
        {`${t("totalWordCount", {
          count: revisedAssignmentContentState.countWords(),
        })}, 
        ${t("totalSentenceCount", {
          count: revisedAssignmentContentState.countSentences(),
        })}`}
      </Statistics>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.gray[50]};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  padding: 1rem 0 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const Statistics = styled.div`
  padding: 0.5rem;
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[500]};
  background-color: ${theme.colors.gray[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${theme.colors.gray[200]};
  box-sizing: border-box;
`;
