import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  formatDuration,
  formatTimerProgress,
} from "@/application/services/DurationFormatter.ts";
import {
  Content,
  ControlButton,
  RecordingBox,
} from "@/application/ui/lib/recording/components/AudioStyled.ts";
import { useAudioPlayer } from "@/application/ui/lib/recording/hooks/useAudioPlayer.ts";
import { ReactComponent as AnimatedWaveform } from "@/assets/animated-waveform.svg";
import { ReactComponent as PlayIcon } from "@/assets/play-circle.svg";
import { ReactComponent as StopIcon } from "@/assets/stop-circle.svg";
import { ReactComponent as Waveform } from "@/assets/waveform.svg";

import { theme } from "../../../theme/theme";

interface Props {
  url: string;
}

export const AudioPlayer = ({ url }: Props) => {
  const { t } = useTranslation();
  const { timer, play, reset, durationInSeconds, isPlaying, audioProps } =
    useAudioPlayer(url);

  return (
    <>
      <audio {...audioProps} />

      <RecordingBox className="completed">
        {!isPlaying && (
          <>
            <ControlButton className="completed" onClick={play}>
              <PlayIcon title={t("playRecord")} />
            </ControlButton>

            <Content>
              <Waveform title={t("audioIndicator")} />

              {durationInSeconds && (
                <CompletedText>
                  {formatDuration(durationInSeconds)}
                </CompletedText>
              )}
            </Content>
          </>
        )}

        {isPlaying && (
          <>
            <ControlButton className="completed" onClick={reset}>
              <StopIcon title={t("stopRecord")} />
            </ControlButton>

            <Content>
              <AnimatedWaveform title={t("playingRecord")} />

              {durationInSeconds && (
                <CompletedText>
                  {formatTimerProgress(timer, durationInSeconds)}
                </CompletedText>
              )}
            </Content>
          </>
        )}
      </RecordingBox>
    </>
  );
};

const CompletedText = styled.span`
  color: ${theme.colors.base.white};
`;
