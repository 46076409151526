import { SecondaryFiveOrthographyValuedGradeMapper } from '../../orthographyGrade/strategies/secondaryFive/SecondaryFiveOrthographyValuedGradeMapper';
import { SecondaryTwoOrthographyValuedGradeMapper } from '../../orthographyGrade/strategies/secondaryTwo/SecondaryTwoOrthographyValuedGradeMapper';
import { SecondaryFiveSyntaxValuedGradeMapper } from '../../syntaxGrade/strategies/secondaryFive/SecondaryFiveSyntaxValuedGradeMapper';
import { SecondaryTwoSyntaxValuedGradeMapper } from '../../syntaxGrade/strategies/secondaryTwo/SecondaryTwoSyntaxValuedGradeMapper';
import { EvaluationCriterion } from '../GradedCriteria';
import type { GradingScaleType } from '../GradingScaleType';
import { DefaultValuedGradeMapper } from './DefaultValuedGradeMapper';
import type { ValuedGradeMapper } from './ValuedGradeMapper';

export class ValuedGradeMapperFactory {
  static withContext(
    type: GradingScaleType,
    criterion: EvaluationCriterion,
  ): ValuedGradeMapper {
    switch (true) {
      case type === 'secondary_five' &&
        criterion === EvaluationCriterion.SYNTAX:
      case type === 'secondary_five_ministry' &&
        criterion === EvaluationCriterion.SYNTAX:
        return new SecondaryFiveSyntaxValuedGradeMapper();
      case type === 'secondary_five_ministry' &&
        criterion === EvaluationCriterion.ORTHOGRAPHY:
      case type === 'secondary_five' &&
        criterion === EvaluationCriterion.ORTHOGRAPHY:
        return new SecondaryFiveOrthographyValuedGradeMapper();
      case type === 'secondary_two' && criterion === EvaluationCriterion.SYNTAX:
        return new SecondaryTwoSyntaxValuedGradeMapper();
      case type === 'secondary_two' &&
        criterion === EvaluationCriterion.ORTHOGRAPHY:
        return new SecondaryTwoOrthographyValuedGradeMapper();
      default:
        return new DefaultValuedGradeMapper();
    }
  }
}
