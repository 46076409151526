import "react-tooltip/dist/react-tooltip.css";

import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import { getCriteriaByIndex } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import type { GradeLetter } from "@emilia/backend/src/common/grade/model/Grade";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle.ts";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";
import type { RevisableAndGradableAssignment } from "@/application/ui/pages/Revision/service/RevisableAndGradableAssignment.ts";

import { theme } from "../../../../theme/theme";
import { DetailedGradingScale } from "./DetailedGradingScale";

const possibleGrades: GradeLetter[] = ["A", "B", "C", "D", "E"];

const possibleCriteria: Set<EvaluationCriterion> = new Set<EvaluationCriterion>(
  [
    EvaluationCriterion.ADAPTATION,
    EvaluationCriterion.COHERENCE,
    EvaluationCriterion.VOCABULARY,
    EvaluationCriterion.SYNTAX,
    EvaluationCriterion.ORTHOGRAPHY,
  ],
);

type GradingScaleProps = {
  revisableAssignment: RevisableAndGradableAssignment;
  evaluatedCriteria: EvaluationCriteria;
  onChangeEditableCriterion?: (
    criterion: EvaluationCriterion,
    grade: GradeLetter,
  ) => void;
};

export const GradingScale = ({
  revisableAssignment,
  evaluatedCriteria,
  onChangeEditableCriterion,
}: GradingScaleProps) => {
  const { t } = useTranslation();

  return (
    <SidebarBox
      title={t("gradingScale")}
      action={
        <DetailedGradingScale
          gradingScaleType={revisableAssignment.gradingScaleModel.type}
        />
      }
    >
      <Tooltip anchorSelect=".not-selected" place="bottom" style={tooltipStyle}>
        {t("notEvaluated")}
      </Tooltip>

      <Grid>
        <GridRow className="heading">
          <GridCell></GridCell>
          <GridCell>A</GridCell>
          <GridCell>B</GridCell>
          <GridCell>C</GridCell>
          <GridCell>D</GridCell>
          <GridCell>E</GridCell>
        </GridRow>
        {[...possibleCriteria].map((criterion, index) => (
          <GridRow
            key={criterion}
            className={
              !getCriteriaByIndex(evaluatedCriteria, index)
                ? "not-selected"
                : criterion === EvaluationCriterion.SYNTAX &&
                    !revisableAssignment.isSyntaxGraded(evaluatedCriteria)
                  ? "syntax-not-selected"
                  : ""
            }
          >
            <CriterionGridCell role="gridcell">
              {t(criterion)}
            </CriterionGridCell>

            {possibleGrades.map((possibleGrade) => (
              <GradingCell
                key={criterion + possibleGrade}
                role="gridcell"
                aria-label={`${criterion}-${possibleGrade}`}
                aria-selected={
                  getCriteriaByIndex(evaluatedCriteria, index) &&
                  revisableAssignment.isGradeLetterForCriterion(
                    criterion,
                    possibleGrade,
                    evaluatedCriteria,
                  )
                }
                onClick={() =>
                  onChangeEditableCriterion?.(criterion, possibleGrade)
                }
              />
            ))}
          </GridRow>
        ))}
      </Grid>
    </SidebarBox>
  );
};

const Grid = styled.div`
  flex: 1;
  color: ${theme.colors.gray[500]};
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr);
  height: 32px;
  background-color: ${theme.colors.base.white};
  transition: background-color 0.5s ease-out;

  &.heading ~ div {
    border-top: 1px solid ${theme.colors.gray[300]};
  }
  padding: 2px;

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  &[aria-disabled="false"] > [aria-selected="false"]:hover {
    cursor: pointer;
    background-color: ${theme.colors.gray[100]};
  }

  &.syntax-not-selected {
    background: ${theme.colors.primary[50]};
  }

  &.not-selected {
    background: ${theme.colors.gray[100]};
    color: ${theme.colors.gray[500]};
  }
`;

const CriterionGridCell = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
`;

const GridCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GradingCell = styled.div`
  border-radius: ${theme.radius.medium};
  &:hover {
    background-color: ${theme.colors.gray[100]};
  }

  &[aria-selected="true"] {
    background-color: ${theme.colors.primary[300]};
  }
`;
