import type {
  Category,
  RevisionCategoryGroups,
} from "@emilia/backend/src/common/mistakes/domain/model/Category";

import {
  NotVisibleIcon,
  VisibleIcon,
} from "../../../../../lib/visibilityIcon/VisibilityIcon";
import { useAssignments } from "../../../RevisionStates/useAssignments";

export const CategoryVisibilityIcon = (category: Category) => {
  const { showCategory } = useAssignments();

  return showCategory(category)
    ? VisibleCategory(category)
    : NotVisibleCategory(category);
};

export const GroupVisibilityIcon = (
  group: RevisionCategoryGroups,
  title: string,
) => {
  const { showGroup } = useAssignments();

  return showGroup(group)
    ? VisibleGroup(group, title)
    : NotVisibleGroup(group, title);
};

export const CommentsVisibilityIcon = () => {
  const { showComments, onCommentsClick } = useAssignments();

  return showComments
    ? VisibleIcon("commentsCategory", onCommentsClick)
    : NotVisibleIcon("commentsCategory", onCommentsClick);
};

export const RevisionVisibilityIcon = () => {
  const { showRevision, onRevisionClick } = useAssignments();

  return showRevision
    ? VisibleIcon("revisionsCategory", onRevisionClick)
    : NotVisibleIcon("revisionsCategory", onRevisionClick);
};

export const StudentNameVisibilityIcon = () => {
  const { showStudentName, onNameClick } = useAssignments();

  return showStudentName
    ? VisibleIcon("studentName", onNameClick)
    : NotVisibleIcon("studentName", onNameClick);
};

const VisibleCategory = (category: Category) => {
  const { onCategoryClick } = useAssignments();

  return VisibleIcon("revisionCategory" + category, () =>
    onCategoryClick(category),
  );
};

const NotVisibleCategory = (category: Category) => {
  const { onCategoryClick } = useAssignments();

  return NotVisibleIcon("revisionCategory" + category, () =>
    onCategoryClick(category),
  );
};

const VisibleGroup = (group: RevisionCategoryGroups, title: string) => {
  const { onGroupClick } = useAssignments();

  return VisibleIcon("revisionGroup" + title, () => onGroupClick(group));
};

const NotVisibleGroup = (group: RevisionCategoryGroups, title: string) => {
  const { onGroupClick } = useAssignments();

  return NotVisibleIcon("revisionGroup" + title, () => onGroupClick(group));
};
