import { useContext } from "react";

import { AssignmentsContext } from "./AssignmentsProvider";

export const useAssignments = (): AssignmentsContext => {
  const context = useContext(AssignmentsContext);

  if (!context) {
    throw new Error("AssignmentProvider must be instanciated before use");
  }

  return {
    onGroupClick: context.onGroupClick,
    onCategoryClick: context.onCategoryClick,
    onCommentsClick: context.onCommentsClick,
    onRevisionClick: context.onRevisionClick,
    showCategory: context.showCategory,
    showGroup: context.showGroup,
    showComments: context.showComments,
    showRevision: context.showRevision,
    isRevisionVisible: context.isRevisionVisible,
    onModeChange: context.onModeChange,
    currentMode: context.currentMode,
    showStudentName: context.showStudentName,
    onNameClick: context.onNameClick,
  };
};
