import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";

import type {
  Assignment,
  StudentInformation,
} from "@/application/domain/Assignment.ts";
import type { StudentId } from "@/application/domain/Student.ts";

type StudentLastAssignment = Record<StudentId, Assignment>;

export interface TaskProps {
  id: string;
  name: string;
  gradingScaleType: GradingScaleType;
  evaluatedCriteria: EvaluationCriteria;
}

interface Props {
  homeroom: {
    id: string;
    name: string;
  };
  writingTask: TaskProps;
  assignments: Assignment[];
}

export class WritingTaskAssignments {
  homeroom;
  writingTask;
  assignments;

  constructor({ homeroom, writingTask, assignments }: Props) {
    this.homeroom = homeroom;
    this.writingTask = writingTask;
    this.assignments = assignments;
  }

  getLastAssignmentForEachStudent(): StudentsLastAssignmentDictionary {
    const dictionary = this.assignments.reduce(
      (acc: StudentLastAssignment, current) => {
        if (!acc[current.studentInformation.id]) {
          acc[current.studentInformation.id] = current;
        }

        return acc;
      },
      {},
    );

    return new StudentsLastAssignmentDictionary(dictionary);
  }
}

export class StudentsLastAssignmentDictionary {
  constructor(private assignmentsByStudents: StudentLastAssignment) {}

  getFor(studentId: StudentId): Assignment {
    return this.assignmentsByStudents[studentId];
  }

  orderStudentsByName(): StudentInformation[] {
    const studentInformations = Object.values(this.assignmentsByStudents).map(
      (x) => x.studentInformation,
    );
    const sortedInformations = studentInformations.sort((a, b) => {
      return a.name.fullName().localeCompare(b.name.fullName());
    });
    return sortedInformations.map((info) => ({
      id: info.id,
      name: info.name,
    }));
  }
}
