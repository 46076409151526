import type { LetterWithVariantGrade } from '../../../../common/grade/model/Grade';
import type { TeacherCommentDto } from './FrontendGradeAssignmentDto';

export enum RevisionCategoryDto {
  GRAMMAR = 'grammar',
  SYNTAX = 'syntax',
  PUNCTUATION = 'punctuation',
  VOCABULARY = 'vocabulary',
  SPELLING = 'spelling',
  COHERENCE = 'coherence',
}

export enum EvaluationCriterionResponseDto {
  VOCABULARY = 'vocabulary',
  ADAPTATION = 'adaptation',
  COHERENCE = 'coherence',
  SYNTAX = 'syntax',
  ORTHOGRAPHY = 'orthography',
}

export class RevisionDto {
  id: string;
  original: string;
  revised: string;
  category: {
    mainCategory: RevisionCategoryDto;
    subCategory: string | null;
  };
  penalized: boolean;
  comment?: string;
}

export class GradedCriterionDto {
  grade: LetterWithVariantGrade;
  criterion: EvaluationCriterionResponseDto;
}

export class FrontendAssignmentResponseDto {
  id: string;
  studentId: string;
  student: {
    id: string;
    firstName: string;
    lastName: string;
  };
  content: string;
  status: string;
  revisions?: RevisionDto[];
  comments?: TeacherCommentDto[];
  gradedCriteria: GradedCriterionDto[];
  globalGrade?: LetterWithVariantGrade;
  globalGradePercent?: number;
  comment?: string;
  recordUrl: string | undefined;
  gradingScaleType?: string;
  flagged: boolean;
}

export type FrontendConsultationAssignmentResponseDto =
  FrontendAssignmentResponseDto & {
    writingTask: {
      id: string;
      name: string;
      instructions: string;
    };
  };
