import { useMemo } from "react";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import type { WritingTaskAssignmentStatuses } from "@/application/domain/WritingTask/WritingTaskAssignmentStatuses.ts";
import type { StatusesValues } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Content/WritingTaskProgressBar.tsx";
import { WritingTaskProgressBar } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Content/WritingTaskProgressBar.tsx";

interface Props {
  writingTask: TeacherWritingTask;
}

export const CardContentFactory = ({ writingTask }: Props) => {
  const statuses: StatusesValues[] = useMemo(() => {
    return Object.entries(writingTask.studentAssignmentStatusesCount ?? []).map(
      ([key, value]) => ({
        key: key as WritingTaskAssignmentStatuses,
        value,
      }),
    );
  }, [writingTask]);

  switch (writingTask.typeStatus) {
    case "closed":
    case "open":
    case "masked":
      return <WritingTaskProgressBar statuses={statuses} />;
    default:
      break;
  }
};
