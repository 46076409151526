import type { Ref } from "react";
import { forwardRef } from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import { Button, type ButtonProps } from "./Button";

export const IconButton = forwardRef(
  ({ children, ...rest }: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      <StyledButton ref={ref as any} {...rest}>
        {children}
      </StyledButton>
    );
  },
);

const StyledButton = styled(Button)`
  width: auto;
  aspect-ratio: 1;
  border-radius: ${theme.radius.round};

  &.neutral:enabled:hover {
    background-color: ${theme.colors.gray[100]} !important;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`;
