import { Grade } from './Grade';

export class ValuedGrade {
  constructor(
    public readonly grade: Grade,
    public readonly value: number,
  ) {
    if (value > 100 || value < 0)
      throw new Error('Invalid percentage. Should be between 0 and 100');
  }
}

export function toValuedGrade(grade: string, percent: number | null) {
  return new ValuedGrade(Grade.of(grade), percent ?? toGlobalMark(grade));
}

function toGlobalMark(letterGrade: string): number {
  switch (letterGrade) {
    case 'A+':
      return 95;
    case 'A':
      return 90;
    case 'A-':
      return 85;
    case 'B+':
      return 80;
    case 'B':
      return 75;
    case 'B-':
      return 70;
    case 'C+':
      return 65;
    case 'C':
      return 60;
    case 'C-':
      return 55;
    case 'D+':
      return 50;
    case 'D':
      return 40;
    case 'D-':
      return 35;
    default:
      return 0; // For grade 'E'
  }
}
