import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import words from "lodash/words";

import type {
  Assignment,
  RevisionMap,
  TeacherComment,
} from "@/application/domain/Assignment.ts";
import { segmentConverter } from "@/application/ui/pages/Revision/service/segmentConverter.ts";

export const generateId = (): string =>
  Math.floor(Math.random() * 10000000).toString(16);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class RevisableSegment<T extends RevisableSegment<any>> {
  public id: string;
  public segments: Segments;

  protected abstract createInstance(textWithRevisions: Segments): T;

  protected mergeStrings(textWithRevisions: Segments) {
    const clone = [...textWithRevisions];

    if (clone.length > 2) {
      for (let i = 0; i < clone.length; i++) {
        if (typeof clone[i] === "string" && typeof clone[i + 1] === "string") {
          (clone[i] as string) += clone[i + 1] as string;
          clone.splice(i + 1, 1);
          i--;
        }
      }
    }

    return clone;
  }

  countWords() {
    return words(this.toString()).length;
  }

  countSentences() {
    /*
     * L'algo actuel à été modifié avec CSA-434 suite à une demande de Stéphane.
     * Certains élèves n'ajoutent pas d'espaces après le point à la fin de leur phrase.
     * Cela a pour conséquence de compter moins de phrases qu'il y en a en réalité.
     * Et puisque certains critères sont calculés en fonction du nombre de phrase, cela pénalise les élèves.
     * La nouvelle version accepte donc les absences d'espaces après le point. Cela va entrainer des faux positifs,
     * J'ai sensibilisé Stéphane a cela. Il en est conscient...
     */
    return this.toString()
      .split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|!)(?!\.)/)
      .filter((sentence) => sentence.trim() !== "").length;
  }

  replaceAt(index: number, value: string | Revision): T {
    const clone = [...this.segments];
    clone[index] = value;

    return this.createInstance(clone);
  }

  insertRevisionAt(index: number, textWithRevisions: Segments): T {
    const clone = [...this.segments];
    clone.splice(index, 1, ...textWithRevisions);

    return this.createInstance(clone);
  }

  removeRevisionAt(index: number): T {
    const clone = [...this.segments];
    const elementToFlatten = clone[index];

    if (typeof elementToFlatten === "string") {
      throw new Error("Cannot flatten string");
    }

    clone.splice(index, 1, ...elementToFlatten.segments);

    return this.createInstance(clone);
  }

  toString(): string {
    return this.segments.map((segment) => segment.toString()).join("");
  }

  toRevisedString(): string {
    return this.segments
      .map((segment) => {
        if (segment instanceof Revision) {
          return segment.revisedText;
        }

        return segment.toString();
      })
      .join("");
  }

  countPenalizedErrorsForCategory(category: Category): number {
    return this.findBy(category).filter(({ penalized }) => penalized).length;
  }

  countUnpenalizedErrors(): number {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const revisions: Revision[] = this.segments.filter(
      (segment) => segment instanceof Revision,
    ) as Revision[];

    return revisions.filter(({ penalized }) => !penalized).length;
  }

  findBy(category: Category): Revision[] {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const revisions: Revision[] = this.segments.filter(
      (segment) => segment instanceof Revision,
    ) as Revision[];

    return revisions.reduce((acc, curr) => {
      const matchingCategory = curr.category === category;

      if (matchingCategory) {
        return acc.concat(curr).concat(curr.findBy(category));
      }

      return acc;
    }, [] as Revision[]);
  }
}

export class Revision extends RevisableSegment<Revision> {
  constructor(
    id: string,
    textWithRevisions: Segments,
    public revisedText: string | null = "",
    public category: Category = Category.UNSPECIFIED,
    public subCategory: string | null = null,
    public penalized: boolean,
  ) {
    super();
    this.id = id;
    this.segments = this.mergeStrings(textWithRevisions);
  }

  public static create(
    textWithRevisions: Segments,
    revisedText: string | null = "",
    category: Category = Category.UNSPECIFIED,
    subCategory: string | null = null,
  ): Revision {
    return new Revision(
      generateId(),
      textWithRevisions,
      revisedText,
      category,
      subCategory,
      true,
    );
  }

  public changeCategory(category: Category): Revision {
    if (category !== this.category) {
      return new Revision(
        this.id,
        this.segments,
        this.revisedText,
        category,
        null,
        this.penalized,
      );
    }

    return this;
  }

  togglePenalisation(): Revision {
    return new Revision(
      this.id,
      this.segments,
      this.revisedText,
      this.category,
      this.subCategory,
      !this.penalized,
    );
  }

  public changeRevisedText(revisedText: string): Revision {
    return new Revision(
      this.id,
      this.segments,
      revisedText,
      this.category,
      this.subCategory,
      this.penalized,
    );
  }

  protected createInstance(textWithRevisions: Segments): Revision {
    return new Revision(
      generateId(),
      textWithRevisions,
      this.revisedText,
      this.category,
      this.subCategory,
      this.penalized,
    );
  }
}

export type Segment = string | Revision;

export type Segments = Segment[];

export class RevisedAssignmentContentState extends RevisableSegment<RevisedAssignmentContentState> {
  constructor(textWithRevisions: Segments) {
    super();
    this.id = "top";
    this.segments = this.mergeStrings(textWithRevisions);
  }

  static init(
    assignment: Assignment | undefined,
  ): RevisedAssignmentContentState {
    return new RevisedAssignmentContentState(
      assignment ? segmentConverter.fromAssignment(assignment) : [],
    );
  }

  countNbPenalizedWordForCategory(category: Category) {
    return this.findBy(category)
      .filter(({ penalized }) => penalized)
      .reduce((acc, curr) => acc + curr.countWords(), 0);
  }

  toRevisedTextWithRevisions(): {
    revisedText: string;
    revisions: RevisionMap;
  } {
    let revisedText = "";
    const revisions: RevisionMap = {};

    for (const segment of this.segments) {
      if (segment instanceof Revision) {
        revisions[segment.id] = {
          revised: segment.revisedText!,
          original: segment.toString(),
          category: {
            mainCategory: segment.category,
            subCategory: segment.subCategory,
          },
          penalized: segment.penalized,
        };

        revisedText += `*{${segment.id}}*`;
      } else {
        revisedText += segment;
      }
    }

    return { revisedText, revisions };
  }

  calculateOffset(
    comments: TeacherComment[],
    categories: Category[],
    showRevisions: boolean,
  ): TeacherComment[] {
    comments = comments.map((c) => {
      c.startOffset = 0;
      c.endOffset = 0;
      return c;
    });

    if (comments.length === 0) {
      return [];
    }

    if (categories.length === 0) {
      return comments;
    }

    const correctedComments: TeacherComment[] = [];
    let indexInOriginal = 0;
    let offset = 0;
    let commentIndex = 0;
    let currentComment = comments[0];

    for (const segment of this.segments) {
      indexInOriginal += segment.toString().length;

      if (
        currentComment.startOffset === 0 &&
        indexInOriginal >= currentComment.startIndex
      ) {
        currentComment.startOffset = offset;
      }

      if (
        segment instanceof Revision &&
        categories.includes(segment.category) &&
        segment.penalized &&
        showRevisions
      ) {
        // L'objet segment peut contenir une liste de Segments
        // donc si on supporte les erreurs imbriquer il va falloir
        // calculer le offset avec une fonction recursive
        offset += segment.revisedText?.length ?? 0;
      }

      while (indexInOriginal >= currentComment.endIndex) {
        currentComment.endOffset = offset;
        correctedComments.push(currentComment);
        commentIndex++;

        if (commentIndex < comments.length) {
          currentComment = comments[commentIndex];
          currentComment.startOffset =
            indexInOriginal >= currentComment.startIndex ? offset : 0;
        } else {
          break;
        }
      }

      if (commentIndex >= comments.length) {
        break;
      }
    }

    return correctedComments;
  }

  protected createInstance(
    textWithRevisions: Segments,
  ): RevisedAssignmentContentState {
    return new RevisedAssignmentContentState(textWithRevisions);
  }
}
