import type { UserResponseDto } from "@users/UserResponseDto.ts";

import { LoggedInUserMapper } from "@/application/authentication/api/mapper/LoggedInUserMapper.ts";

import { AuthenticatedHttpClient } from "../../client/HttpClient.ts";

export const UserClient = {
  me: async () => {
    const { data } =
      await AuthenticatedHttpClient.get<UserResponseDto>(`/users/me`);
    return LoggedInUserMapper.fromDto(data);
  },
};
