import { useQuery } from "@tanstack/react-query";

import { TeacherDashboardClient } from "@/application/client/TeacherDashboardClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useTeacherHomeroomDashboard = (
  homeroomId: string,
  includeMasked: boolean,
) => {
  const { data: dashboard, isError } = useQuery({
    queryKey: [...TeacherQueryKeys.dashboard, homeroomId, includeMasked],
    queryFn: () =>
      TeacherDashboardClient.fetchHomerooms(homeroomId, includeMasked),
    retry: false,
  });

  return { dashboard, isError };
};
