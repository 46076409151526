import { EvaluationCriterion } from '../model/GradedCriteria';
import type { CriteriaWeighting } from './GlobalGradeComputingService';

export const SECONDARY_ONE_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.225,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.15,
  [EvaluationCriterion.SYNTAX]: 0.225,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const SECONDARY_TWO_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.25,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.1,
  [EvaluationCriterion.SYNTAX]: 0.25,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const SECONDARY_THREE_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.2666,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.0833,
  [EvaluationCriterion.SYNTAX]: 0.25,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const SECONDARY_FOUR_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.284,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.066,
  [EvaluationCriterion.SYNTAX]: 0.25,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const SECONDARY_FIVE_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.3,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.05,
  [EvaluationCriterion.SYNTAX]: 0.25,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const SECONDARY_FIVE_MINISTRY_CRITERIA_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.3,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.05,
  [EvaluationCriterion.SYNTAX]: 0.25,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const DEFAULT_WEIGHTING: CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: 0.2,
  [EvaluationCriterion.COHERENCE]: 0.2,
  [EvaluationCriterion.VOCABULARY]: 0.2,
  [EvaluationCriterion.SYNTAX]: 0.2,
  [EvaluationCriterion.ORTHOGRAPHY]: 0.2,
};

export const weightMap: { [key: string]: CriteriaWeighting } = {
  ['third_year']: DEFAULT_WEIGHTING,
  ['fourth_year']: DEFAULT_WEIGHTING,
  ['fifth_year']: DEFAULT_WEIGHTING,
  ['sixth_year']: DEFAULT_WEIGHTING,
  ['secondary_one']: SECONDARY_ONE_CRITERIA_WEIGHTING,
  ['secondary_two']: SECONDARY_TWO_CRITERIA_WEIGHTING,
  ['secondary_three']: SECONDARY_THREE_CRITERIA_WEIGHTING,
  ['secondary_four']: SECONDARY_FOUR_CRITERIA_WEIGHTING,
  ['secondary_five']: SECONDARY_FIVE_CRITERIA_WEIGHTING,
  ['secondary_five_ministry']: SECONDARY_FIVE_MINISTRY_CRITERIA_WEIGHTING,
};
