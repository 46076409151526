import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme.ts";

export const ClosedHeader = () => {
  const { t } = useTranslation();
  return <ClosedTag>{t("closeWritingTask.tag")}</ClosedTag>;
};

const ClosedTag = styled.div`
  background-color: ${theme.colors.gray[100]};
  border-radius: 16px;
  padding: 4px 12px;
  ${theme.typography.sm.medium};
  color: ${theme.colors.gray[700]};
`;
