import { createFont, fontWeight } from "@/application/ui/theme/typography.ts";

export const palette = {
  // ces couleurs proviennent du Design System de Figma
  // https://www.figma.com/file/3oN68JcskNU2ADGIEagknS/%E2%9D%96-Design-System?type=design&node-id=1525%3A271698&mode=dev
  base: {
    white: "#FFFFFF",
    black: "#000000",
  },
  primary: {
    25: "#F5FFF5",
    50: "#E5FFE7",
    100: "#C0FFC3",
    200: "#ADFFB1",
    300: "#8FFF94",
    400: "#00F061",
    500: "#00D958",
    600: "#00A844",
    700: "#319A44",
    800: "#298038",
    900: "#194D22",
  },
  yellow: {
    25: "#FEFDF0",
    50: "#FEFBE8",
    100: "#FEF7C3",
    200: "#FEEE95",
    300: "#FDE272",
    400: "#FAC515",
    500: "#EAAA08",
    600: "#CA8504",
    700: "#A15C07",
    800: "#854A0E",
    900: "#713B12",
  },
  grayBlue: {
    25: "#FCFCFD",
    50: "#F8F9FC",
    100: "#EAECF5",
    200: "#D5D9EB",
    300: "#B3B8DB",
    400: "#717BBC",
    500: "#4E5BA6",
    600: "#3E4784",
    700: "#363F72",
    800: "#293056",
    900: "#101323",
  },
  indigo: {
    25: "#F5F8FF",
    50: "#EEF4FF",
    100: "#E0EAFF",
    200: "#C7D7FE",
    300: "#A4BCFD",
    400: "#8098F9",
    500: "#6172F3",
    600: "#444CE7",
    700: "#3538CD",
    800: "#2D31A6",
    900: "#2D3282",
  },
  blue: {
    25: "#F5FAFF",
    50: "#EFF8FF",
    100: "#D1E9FF",
    200: "#B2DDFF",
    300: "#84CAFF",
    400: "#53B1FD",
    500: "#2E90FA",
    600: "#1570EF",
    700: "#175CD3",
    800: "#1849A9",
    900: "#194185",
  },
  blueLight: {
    25: "#F5FBFF",
    50: "#F0F9FF",
    100: "#E0F2FE",
    200: "#B9E6FE",
    300: "#7CD4FD",
    400: "#36BFFA",
    500: "#0BA5EC",
    600: "#0086C9",
    700: "#026AA2",
    800: "#065986",
    900: "#0B4A6F",
  },
  gray: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  orangeDark: {
    25: "#FFF9F5",
    50: "#FFF4ED",
    100: "#FFE6D5",
    200: "#FFD6AE",
    300: "#FF9C66",
    400: "#FF692E",
    500: "#FF4405",
    600: "#E62E05",
    700: "#BC1B06",
    800: "#97180C",
    900: "#771A0D",
  },
  pink: {
    25: "#FEF6FB",
    50: "#FDF2FA",
    100: "#FCE7F6",
    200: "#FCCEEE",
    300: "#FAA7E0",
    400: "#F670C7",
    500: "#EE46BC",
    600: "#DD2590",
    700: "#C11574",
    800: "#9E165F",
    900: "#851651",
  },
  teal: {
    25: "#F6FEFC",
    50: "#F0FDF9",
    100: "#CCFBEF",
    200: "#99F6E0",
    300: "#5FE9D0",
    400: "#2ED3B7",
    500: "#15B79E",
    600: "#0E9384",
    700: "#107569",
    800: "#125D56",
    900: "#134E48",
  },
  error: {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
  },
  success: {
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    300: "#6CE9A6",
    400: "#32D583",
    500: "#12B76A",
    600: "#039855",
    700: "#027A48",
    800: "#05603A",
    900: "#054F31",
  },
  warning: {
    25: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    600: "#DC6803",
    700: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
  },
  purple: {
    50: "#F4F3FF",
    700: "#5925DC",
  },
} as const;

const designSystemColors = {
  ...palette,
  primary: palette.primary,
  secondary: palette.grayBlue,
} as const;

const borderColors = {
  primary: designSystemColors.gray[200],
} as const;

export const theme = {
  colors: {
    ...designSystemColors,

    category: {
      grammar: designSystemColors.orangeDark[500],
      spelling: designSystemColors.blue[500],
      syntax: designSystemColors.teal[500],
      punctuation: designSystemColors.pink[500],
      vocabulary: designSystemColors.yellow[400],
    },
    text: {
      primary: designSystemColors.gray[700],
    },
    borders: {
      ...borderColors,
    },
  },
  borders: {
    primary: `1px solid ${borderColors.primary}`,
  },
  radius: {
    xsmall: "4px",
    small: "6px",
    medium: "8px",
    large: "12px",
    round: "999px",
  },
  typography: {
    xs: createFont({
      fontSize: 12,
      lineHeight: 18,
    }),
    sm: createFont({
      fontSize: 14,
      lineHeight: 20,
    }),
    md: createFont({
      fontSize: 16,
      lineHeight: 24,
    }),
    lg: createFont({
      fontSize: 18,
      lineHeight: 28,
    }),
    xl: createFont({
      fontSize: 20,
      lineHeight: 30,
    }),
    displayXs: createFont({
      fontSize: 24,
      lineHeight: 32,
    }),
    displaySm: createFont({
      fontSize: 30,
      lineHeight: 38,
    }),
    displayMd: createFont({
      fontSize: 36,
      lineHeight: 44,
    }),
    displayLg: createFont({
      fontSize: 48,
      lineHeight: 60,
    }),
    displayXl: createFont({
      fontSize: 60,
      lineHeight: 72,
    }),
    display2xl: createFont({
      fontSize: 72,
      lineHeight: 90,
    }),
  },
  fontWeight,
  shadowAndBlur: {
    xsmall: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    small:
      "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
    medium:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
    large:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
    xlarge:
      "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
    xxlarge: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    xxxlarge: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
  },
} as const;

export type Theme = typeof theme;
