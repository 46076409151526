import { useContext } from "react";

import type { GradeContextProps } from "@/application/ui/pages/GradeReport/GradeStates/GradeProvider.tsx";
import { GradeContext } from "@/application/ui/pages/GradeReport/GradeStates/GradeProvider.tsx";

export const useGrades = (): GradeContextProps => {
  const context = useContext(GradeContext);

  if (!context) {
    throw new Error("AssignmentProvider must be instanciated before use");
  }

  return {
    updateContext: context.updateContext,
    gradeContextDto: context.gradeContextDto,
    taskId: context.taskId,
    student: context.student,
    setStudent: context.setStudent,
    setTaskId: context.setTaskId,
  };
};
