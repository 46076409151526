import { Trans } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme";

export const BoldTrans = ({ translationKey }: { translationKey: string }) => {
  return <Trans i18nKey={translationKey} components={{ b: <Bold /> }} />;
};

const Bold = styled.p`
  ${theme.typography.sm.bold};
`;
