import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { ReactComponent as EyeIcon } from "@/assets/eye.svg";

import { BaseModal } from "../../../../../lib/form/BaseModal.tsx";
import { FeaturedIcon } from "../../../../../lib/icon/FeaturedIcon.tsx";
import { theme } from "../../../../../theme/theme.ts";
import { useDistributeWritingTask } from "../../../hooks/useDistributeWritingTask.ts";

interface Props {
  writingTask: TeacherWritingTask;
}

export const DraftActions = ({ writingTask }: Props) => {
  const { t } = useTranslation();
  const [open, isOpen] = useState(false);
  return (
    <>
      <Button hierarchy="secondary" size="sm" onClick={() => isOpen(true)}>
        {t("draftWritingTask.distribute.action")}
      </Button>
      <DistributeDraftConfirmationModal
        writingTask={writingTask}
        open={open}
        isOpen={isOpen}
      />
    </>
  );
};

type DistributeDraftProps = {
  writingTask: TeacherWritingTask;
  open: boolean;
  isOpen: (_val: boolean) => void;
};

const DistributeDraftConfirmationModal = ({
  isOpen,
  open,
  writingTask,
}: DistributeDraftProps) => {
  const { distribute } = useDistributeWritingTask();
  const { t } = useTranslation();

  return (
    <BaseModal
      open={open}
      icon={
        <FeaturedIcon color="success" size={20}>
          <EyeIcon />
        </FeaturedIcon>
      }
      onClose={() => isOpen(false)}
      title={t("draftWritingTask.distribute.modal.title")}
      description={
        <>
          <Text>{t("draftWritingTask.distribute.modal.description")}</Text>
          <Text className="bold">
            {t("draftWritingTask.distribute.modal.boldDescription")}
          </Text>
        </>
      }
    >
      <Button
        hierarchy="neutral"
        onClick={() => {
          isOpen(false);
        }}
      >
        {t("cancel")}
      </Button>
      <Button
        hierarchy="success"
        variant="contained"
        onClick={() => {
          isOpen(false);
          distribute(writingTask.id);
        }}
      >
        {t("confirm")}
      </Button>
    </BaseModal>
  );
};

const Text = styled.span`
  ${theme.typography.sm.regular};
  &.bold {
    ${theme.typography.sm.semibold};
  }
`;
