import { useContext } from "react";

import type { LoggedUser } from "@/application/authentication/domain/LoggedUser.ts";
import { UserContext } from "@/application/authentication/UserProvider.tsx";

interface UseLoggedUser {
  user: LoggedUser;
  logout: () => void;
}

export const useLoggedUser = (): UseLoggedUser => {
  const context = useContext(UserContext);

  if (!context.user) {
    throw new Error("Cannot use the logged user without user");
  }

  return {
    logout: () => void context.logout(),
    user: context.user,
  };
};
