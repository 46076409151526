import "react-loading-skeleton/dist/skeleton.css";

import { EvaluationCriterion } from "@api/grade/model/GradedCriteria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GradesFilter } from "@/application/ui/pages/GradeReport/GradeStates/GradeProvider.tsx";
import { useGrades } from "@/application/ui/pages/GradeReport/GradeStates/useGrades.ts";

import { SlidingToggleButton } from "../../lib/button/SlidingToggleButton";

export const GradeReportByGroup = () => {
  const { t } = useTranslation();
  const { gradeContextDto, updateContext } = useGrades();

  return (
    <>
      {gradeContextDto.selectedCriteria !== "all" && (
        <RankingInput>
          <SlidingToggleButton
            onClick={() => {
              updateContext(
                new GradesFilter(
                  gradeContextDto.isLetterMode,
                  gradeContextDto.isPanelOpened,
                  !gradeContextDto.isSubcriteriaMode,
                  gradeContextDto.isStudentMode,
                  gradeContextDto.selectedCriteria,
                ),
              );
            }}
            isToggled={gradeContextDto.isSubcriteriaMode}
            defaultText={t("writingTaskGradeReport.Ranking")}
            toggledText={t("writingTaskGradeReport.Subcrietria")}
            disabled={
              gradeContextDto.selectedCriteria ===
                EvaluationCriterion.ADAPTATION.toString() ||
              gradeContextDto.selectedCriteria ===
                EvaluationCriterion.COHERENCE.toString()
            }
            data-testid="subcriteriaModeButton"
          />
        </RankingInput>
      )}
    </>
  );
};

const RankingInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
