import {
  Category,
  RevisionCategoryGroups,
} from "@emilia/backend/src/common/mistakes/domain/model/Category";
import type { PropsWithChildren } from "react";
import { createContext, useState } from "react";

import { useLoggedUser } from "../../../../authentication/hooks/useLoggedUser";
import { AssignmentRevisionFilters } from "./AssignmentRevisionFilters";

export type CorrectionMode = "default" | "unpenalized" | "comment";

export type AssignmentsContext = {
  onGroupClick: (_: RevisionCategoryGroups) => void;
  showGroup: (_: RevisionCategoryGroups) => boolean;
  onCategoryClick: (_: Category) => void;
  showCategory: (_: Category) => boolean;
  onCommentsClick: () => void;
  showComments: boolean;
  onRevisionClick: () => void;
  showRevision: boolean;
  showStudentName: boolean;
  onNameClick: () => void;
  isRevisionVisible: () => boolean;
  onModeChange: (_: CorrectionMode) => void;
  currentMode: CorrectionMode;
};

export const AssignmentsContext = createContext<AssignmentsContext>({
  onGroupClick: () => {},
  showGroup: () => false,
  onCategoryClick: () => {},
  showCategory: () => false,
  onCommentsClick: () => {},
  showComments: false,
  onRevisionClick: () => {},
  showRevision: true,
  showStudentName: true,
  onNameClick: () => {},
  isRevisionVisible: () => true,
  onModeChange: () => {},
  currentMode: "default",
});

export const AssignmentsProvider = ({ children }: PropsWithChildren) => {
  const { user } = useLoggedUser();
  const [revisionFilters, setRevisionFilters] =
    useState<AssignmentRevisionFilters>(
      new AssignmentRevisionFilters(
        {
          [RevisionCategoryGroups.ORTHOGRAPHY]: true,
          [RevisionCategoryGroups.SYNTAX]: true,
        },
        {
          [Category.GRAMMAR]: true,
          [Category.SYNTAX]: true,
          [Category.PUNCTUATION]: true,
          [Category.SPELLING]: true,
          [Category.UNPENALIZED]: user.isTeacher(),
          [Category.UNSPECIFIED]: true,
          [Category.VOCABULARY]: true,
        },
      ),
    );
  const [showComments, setShowComments] = useState(true);
  const [showRevision, setShowRevision] = useState(user.isTeacher());
  const [currentMode, setCurrentContext] = useState<CorrectionMode>("default");
  const [showStudentName, setShowStudentName] = useState(true);

  const updateRevisionFilters = () => {
    setRevisionFilters(
      new AssignmentRevisionFilters(
        revisionFilters.groups,
        revisionFilters.categories,
      ),
    );
  };

  const onGroupClick = (group: RevisionCategoryGroups) => {
    revisionFilters.handleGroupVisibility(group);
    updateRevisionFilters();
  };

  const onCategoryClick = (category: Category) => {
    revisionFilters.handleCategoryVisibility(category);
    updateRevisionFilters();
  };

  const onCommentsClick = () => {
    setShowComments(!showComments);
  };

  const onRevisionClick = () => {
    setShowRevision(!showRevision);
  };

  const onNameClick = () => {
    setShowStudentName(!showStudentName);
  };

  const isRevisionVisible = () => {
    return currentMode === "default" || currentMode === "unpenalized";
  };

  const onModeChange = (mode: CorrectionMode) => {
    setCurrentContext(mode);
  };

  return (
    <AssignmentsContext.Provider
      value={{
        onCategoryClick,
        onCommentsClick,
        onGroupClick,
        onRevisionClick,
        showCategory: revisionFilters.showCategory,
        showComments,
        showGroup: revisionFilters.showGroup,
        showRevision,
        isRevisionVisible,
        onModeChange,
        currentMode,
        showStudentName,
        onNameClick,
      }}
    >
      {children}
    </AssignmentsContext.Provider>
  );
};
