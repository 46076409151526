import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { WritingTaskSummaryPanelClient } from "@/application/client/WritingTaskSummaryPanelClient.ts";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";

type UseUnlockAssignmentProps = {
  onSuccess?: () => Promise<void>;
  onError?: () => Promise<void>;
};

export function useUnlockAssignment({
  onSuccess,
  onError,
}: UseUnlockAssignmentProps) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (assignmentId: string) =>
      WritingTaskSummaryPanelClient.unlockAssignment(assignmentId),
    {
      onSuccess: async () => {
        await onSuccess?.();
        ToastService.showSuccess(t("unlockAssignmentSucceed"));
      },
      onError: async () => {
        await onError?.();
        ToastService.showError(t("failToUnlockAssignment"));
      },
    },
  );
  return { unlock: mutate, unlocking: isLoading };
}
