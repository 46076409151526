import { createContext, type PropsWithChildren, useState } from "react";
import { useParams } from "react-router-dom";

import type { GradedStudent } from "../../../../domain/gradedStudents";

export class GradesFilter {
  constructor(
    private letterMode: boolean = false,
    private panelOpened: boolean = false,
    private subcriteriaMode: boolean = false,
    private studentMode: boolean = false,
    private criteria: string = "all",
  ) {}

  public get isLetterMode() {
    return this.letterMode;
  }

  public get isPanelOpened() {
    return this.panelOpened;
  }
  public get isSubcriteriaMode() {
    return this.subcriteriaMode;
  }
  public get isStudentMode() {
    return this.studentMode;
  }
  public get selectedCriteria() {
    return this.criteria;
  }
}

export type GradeContextProps = {
  updateContext: (context: GradesFilter) => void;
  gradeContextDto: GradesFilter;
  student: GradedStudent | undefined;
  taskId: string;
  setStudent: (student?: GradedStudent) => void;
  setTaskId: (id: string) => void;
};

export const GradeContext = createContext<GradeContextProps>({
  updateContext: (_: GradesFilter) => {},
  gradeContextDto: new GradesFilter(),
  student: undefined,
  setStudent: (_?: GradedStudent) => {},
  setTaskId: (_: string) => {},
  taskId: "",
});

export const GradeProvider = ({ children }: PropsWithChildren) => {
  const { writingTaskId } = useParams();

  const [filter, setFilter] = useState<GradesFilter>(new GradesFilter());
  const [student, setStudent] = useState<GradedStudent | undefined>(undefined);
  const [taskId, setTaskId] = useState<string>(writingTaskId!);

  const updateContext = (c: GradesFilter) => {
    setFilter(c);
  };

  return (
    <GradeContext.Provider
      value={{
        updateContext,
        gradeContextDto: filter,
        student,
        taskId,
        setStudent,
        setTaskId,
      }}
    >
      {children}
    </GradeContext.Provider>
  );
};
