import type { Grade } from '../../../model/Grade';
import { ValuedGrade } from '../../../model/ValuedGrade';
import type { ValuedGradeMapper } from '../../../model/valuedGradeFactory/ValuedGradeMapper';

export class SecondaryOneSyntaxValuedGradeMapper implements ValuedGradeMapper {
  create(grade: Grade): ValuedGrade {
    switch (grade.toString()) {
      case 'A':
        return new ValuedGrade(grade, 100);
      case 'B':
        return new ValuedGrade(grade, 80);
      case 'C':
        return new ValuedGrade(grade, 64);
      case 'D':
        return new ValuedGrade(grade, 46);
      case 'E':
        return new ValuedGrade(grade, 18);
      case 'E--':
        return new ValuedGrade(grade, 0);
      default:
        throw new Error(
          `Cannot find value matching ${grade.toString()} syntax for secondary two `,
        );
    }
  }
}
