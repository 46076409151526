import { useQuery } from "@tanstack/react-query";

import { WritingTaskClient } from "@/application/client/WritingTaskClient.ts";

export const useDraftWritingTask = (writingTaskId: string) => {
  const {
    data: writingTask,
    isLoading,
    refetch,
  } = useQuery({
    enabled: writingTaskId !== undefined && writingTaskId !== "",
    queryKey: ["writingTasks", writingTaskId, "updatePanel"],
    queryFn: () => WritingTaskClient.getDraftById(writingTaskId),
  });

  return { writingTask, isLoading, refetch };
};
