import styled from "styled-components";

import { theme } from "../../../../../theme/theme";

const UnpenalizedSymbol = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: ${theme.colors.gray[400]};
`;

export const UnpenalizedIcon = () => {
  return (
    <UnpenalizedSymbol>
      <Dot />
      <Dot />
    </UnpenalizedSymbol>
  );
};
