import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../../theme/theme";

export interface Props {
  icon: JSX.Element;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  taskId: string;
}

export const CloseWritingTaskDialogContent = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Text>
        {t("closeWritingTaskConfirmationDialog.content.description.choices")}
      </Text>
      <List>
        <Text>
          <Bold>
            {t(
              "closeWritingTaskConfirmationDialog.content.title.closeAndSubmit",
            )}
          </Bold>
          {t(
            "closeWritingTaskConfirmationDialog.content.description.closeAndSubmit",
          )}
        </Text>
        <Text>
          <Bold>
            {t("closeWritingTaskConfirmationDialog.content.title.close")}
          </Bold>
          {t("closeWritingTaskConfirmationDialog.content.description.close")}
        </Text>
      </List>
    </Content>
  );
};

const Content = styled.div`
  color: ${theme.colors.gray[600]};
`;

const Bold = styled.span`
  ${theme.typography.sm.bold};
`;

const Text = styled.span`
  ${theme.typography.sm.regular};
`;

const List = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
