import type { Grade } from '../../../model/Grade';
import { ValuedGrade } from '../../../model/ValuedGrade';
import type { ValuedGradeMapper } from '../../../model/valuedGradeFactory/ValuedGradeMapper';

export class SecondaryFiveSyntaxValuedGradeMapper implements ValuedGradeMapper {
  create(grade: Grade): ValuedGrade {
    switch (grade.toString()) {
      case 'A+':
        return new ValuedGrade(grade, 100);
      case 'A':
        return new ValuedGrade(grade, 96);
      case 'A-':
        return new ValuedGrade(grade, 92);
      case 'A--':
        return new ValuedGrade(grade, 88);
      case 'B+':
        return new ValuedGrade(grade, 84);
      case 'B':
        return new ValuedGrade(grade, 80);
      case 'B-':
        return new ValuedGrade(grade, 76);
      case 'C+':
        return new ValuedGrade(grade, 72);
      case 'C':
        return new ValuedGrade(grade, 68);
      case 'C-':
        return new ValuedGrade(grade, 64);
      case 'C--':
        return new ValuedGrade(grade, 60);
      case 'D+':
        return new ValuedGrade(grade, 56);
      case 'D':
        return new ValuedGrade(grade, 48);
      case 'D-':
        return new ValuedGrade(grade, 40);

      case 'E+':
        return new ValuedGrade(grade, 28);

      case 'E':
        return new ValuedGrade(grade, 16);

      case 'E-':
        return new ValuedGrade(grade, 4);

      case 'E--':
        return new ValuedGrade(grade, 0);

      default:
        throw new Error(
          `Cannot find value matching ${grade.toString()} syntax for secondary five `,
        );
    }
  }
}
