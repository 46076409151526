import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { DefaultValuedGradeMapper } from '../../../model/valuedGradeFactory/DefaultValuedGradeMapper';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';

export class ElementaryOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: DefaultValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new DefaultValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);
    const grade = this.getRelatedOrthographyGrade(ratio);

    return this.valuedGradeMapper.create(grade);
  }

  private getRelatedOrthographyGrade(ratio: number): Grade {
    if (ratio <= 4) {
      return Grade.of('A');
    } else if (ratio <= 7) {
      return Grade.of('B');
    } else if (ratio <= 10) {
      return Grade.of('C');
    } else if (ratio <= 14) {
      return Grade.of('D');
    } else {
      return Grade.of('E');
    }
  }
}
