import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import fifth_year from "./assets/fifth_year.png";
import fourth_year from "./assets/fourth_year.png";
import secondary_five from "./assets/secondary_five.png";
import secondary_two from "./assets/secondary_two.png";
import sixth_year from "./assets/sixth_year.png";
import third_year from "./assets/third_year.png";

export interface DetailedGradingScaleModalRef {
  display: (scaleType: GradingScaleType) => void;
}

export const DetailedGradingScaleModal = React.forwardRef(
  (_props, ref: Ref<DetailedGradingScaleModalRef>) => {
    const { t } = useTranslation();

    const [open, isOpen] = useState<boolean>(false);
    const [gradingScaleType, setGradingScaleType] = useState<
      GradingScaleType | undefined
    >(undefined);

    useImperativeHandle(ref, () => ({
      display: (scaleType: GradingScaleType) => {
        isOpen(true);
        setGradingScaleType(scaleType);
      },
    }));

    const handleClose = () => {
      isOpen(false);
    };

    const getDetailedScaleTypeImage = () => {
      switch (gradingScaleType) {
        case "secondary_three":
        case "secondary_four":
        case "secondary_five":
        case "secondary_five_ministry":
          return secondary_five;
        case "secondary_one":
        case "secondary_two":
          return secondary_two;
        case "third_year":
          return third_year;
        case "fourth_year":
          return fourth_year;
        case "fifth_year":
          return fifth_year;
        case "sixth_year":
          return sixth_year;
        default:
          break;
      }
    };

    return (
      <Modal
        style={modalStyles}
        isOpen={open}
        onRequestClose={handleClose}
        ariaHideApp={false}
      >
        <div data-testid="detailedGradingScaleModal">
          <Header>
            <Title>{t("gradingScale")}</Title>
            <FloatingIconButton onClick={handleClose}>
              <XCloseIcon />
            </FloatingIconButton>
          </Header>
          <Content>
            {gradingScaleType && (
              <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <>
                    <ZoomControls>
                      <ZoomButton onClick={() => zoomIn()}>+</ZoomButton>
                      <ZoomButton onClick={() => zoomOut()}>-</ZoomButton>
                    </ZoomControls>
                    <TransformComponent wrapperStyle={{ cursor: "pointer" }}>
                      <ScaleImage
                        data-testid={gradingScaleType}
                        src={getDetailedScaleTypeImage()}
                        alt={gradingScaleType}
                      />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            )}
          </Content>
        </div>
      </Modal>
    );
  },
);

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(calc(-50% - 200px + 32px), -50%)",
    width: "850px",
    borderRadius: "12px",
    border: "1px",
    padding: "0",
    backgroundColor: theme.colors.base.white,
    color: theme.colors.base.black,
    boxShadow: "0 20px 24px -4px " + theme.colors.gray[900] + "08",
  },
  overlay: {
    background: theme.colors.gray[700] + "B3",
  },
};

const ZoomControls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const ZoomButton = styled.button`
  background: white;
  border: ${theme.borders.primary};
  border-radius: ${theme.radius.xsmall};
  margin: 2px 0;
  cursor: pointer;
  padding: 5px 10px;
`;

const ScaleImage = styled.img`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  position: relative;
  max-height: 80vh;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;

const Title = styled.span`
  ${theme.typography.lg.semibold}
`;

const FloatingIconButton = styled.button`
  align-self: flex-end;
  cursor: pointer;
`;
