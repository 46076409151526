import type { NumberRange } from '../../global/domain/model/NumberRange';
import type { Grade } from './Grade';

export class TotalNumberCountBasedGradeMatrix {
  private _matrix: Map<NumberRange, Map<NumberRange, Grade>>;

  constructor(matrix: Map<NumberRange, Map<NumberRange, Grade>>) {
    this._matrix = matrix;
  }

  determineGrade(totalWords: number, errorCount: number): Grade {
    for (const [wordCountRange, errorGradesMap] of this._matrix.entries()) {
      if (wordCountRange.contains(totalWords)) {
        return this.wordCountRange(errorGradesMap, errorCount);
      }
    }

    throw Error('cannot find rule fitting total word count');
  }

  private wordCountRange(
    errorGrades: Map<NumberRange, Grade>,
    errorCount: number,
  ) {
    for (const [errorRange, grade] of errorGrades.entries()) {
      if (errorRange.contains(errorCount)) {
        return grade;
      }
    }

    throw Error('cannot find rule fitting error count');
  }
}
