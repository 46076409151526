import { AudioRecord } from "@/application/domain/Record/AudioRecord.ts";

let mediaRecorder: MediaRecorder | null = null;
let audioChunksRef: Blob[] = [];

export type OnAudioCompleted = (config: AudioRecord) => void;

export const AudioRecordingService = {
  startRecording: async (onAudioCompleted: OnAudioCompleted) => {
    const recorder =
      mediaRecorder || (await configureRecorder(onAudioCompleted));

    if (recorder && recorder.state === "inactive") {
      recorder.start();
    } else {
      throw new Error("Recording already started");
    }
  },
  stopRecording: () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
  },
};

const configureRecorder = async (onAudioCompleted: OnAudioCompleted) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef, {
        type: AudioRecord.AUDIO_TYPE,
      });

      onAudioCompleted(new AudioRecord(audioBlob));

      stream
        .getTracks() // get all tracks from the MediaStream
        .forEach((track) => track.stop()); // stop each of them
      mediaRecorder = null;
      audioChunksRef = [];
    };

    return mediaRecorder;
  } catch (error) {
    console.error("Error accessing the microphone", error);
    return null;
  }
};
