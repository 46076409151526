import { useCallback, useEffect, useRef, useState } from "react";

import type {
  DeconstructedSelection,
  PointerCoordinates,
} from "@/application/ui/pages/Revision/hooks/textSelection.ts";
import { deconstructTextBasedOnAction } from "@/application/ui/pages/Revision/hooks/textSelection.ts";

export interface useTextSelectionProps {
  text: string;
  onSelect: (selection: DeconstructedSelection) => void;
}

export const useTextSelection = ({ text, onSelect }: useTextSelectionProps) => {
  const ref = useRef<HTMLElement>(null);

  const [mouseDownCoordinates, setMouseDownCoordinates] =
    useState<PointerCoordinates | null>(null);

  const onMouseDown = (e: MouseEvent) => {
    const { pageX, pageY } = e;
    setMouseDownCoordinates({ x: pageX, y: pageY });
  };

  const onMouseUp = useCallback(
    (e: MouseEvent) => {
      const mouseUpCoordinates: PointerCoordinates = {
        x: e.pageX,
        y: e.pageY,
      };

      const selection = deconstructTextBasedOnAction(
        text,
        mouseUpCoordinates,
        mouseDownCoordinates,
      );

      selection && onSelect(selection);

      setMouseDownCoordinates(null);
    },
    [mouseDownCoordinates, onSelect, text],
  );

  useEffect(() => {
    // ref.current pourrait ne plus pointer vers la même référence au moment ou l'on appel le destructeur
    const current = ref.current;

    current?.addEventListener("mousedown", onMouseDown);
    current?.addEventListener("mouseup", onMouseUp);

    return () => {
      current?.removeEventListener("mousedown", onMouseDown);
      current?.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseUp]);

  return ref;
};
