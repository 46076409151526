import clsx from "clsx";
import { useId, useRef, useState } from "react";
import styled from "styled-components";

import { BaseInput } from "@/application/ui/lib/inputs/inputStyles.ts";
import { theme } from "@/application/ui/theme/theme.ts";

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  size?: "sm";
  type?: "default";
  label?: string;
}

export const Input = ({
  size = "sm",
  type = "default",
  label,
  disabled,
  required,
  ...inputProps
}: Props) => {
  const id = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);

  return (
    <BaseInput.Container>
      {label && (
        <StyledLabel className={clsx({ required, disabled })} htmlFor={id}>
          {label}
        </StyledLabel>
      )}

      <BaseInput.InputBox
        className={clsx(`size-${size}`, { focused, disabled })}
        onClick={() => inputRef.current?.focus()}
      >
        <StyledInput
          id={id}
          type={type}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...inputProps}
          ref={inputRef}
          required={required}
        />
      </BaseInput.InputBox>
    </BaseInput.Container>
  );
};

const StyledInput = styled.input`
  ${({ theme }) => BaseInput.InputStyle(theme)}
`;

const StyledLabel = styled(BaseInput.Label)`
  &.disabled {
    color: ${theme.colors.gray[500]};
  }
`;
