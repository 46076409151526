import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import { msalInstance } from "../ui/pages/App/MsalProvider";

export const TokenFetcher = {
  getToken: async (scope: string) => {
    const accounts = msalInstance.getAllAccounts();

    const tokenRequest = {
      account: accounts[0],
      scopes: [scope],
    };
    try {
      const { accessToken } =
        await msalInstance.acquireTokenSilent(tokenRequest);

      return accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(tokenRequest);
      }
      if (error instanceof BrowserAuthError) {
        return null;
      }
      throw error;
    }
  },
};
