export class WriteAssignmentFormValue {
  private readonly _content: string;

  constructor(content: string) {
    this._content = content;
  }

  isSubmittable(): boolean {
    return !!this._content.trim().length;
  }
  get content(): string {
    return this._content;
  }

  setContent(content: string): WriteAssignmentFormValue {
    return new WriteAssignmentFormValue(content);
  }

  static init(content: string | undefined) {
    return new WriteAssignmentFormValue(content ?? "");
  }
}
