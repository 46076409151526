import { Input } from "./Input";

type Props = React.ComponentProps<typeof Input>;

export const NumberInput = ({ onChange, ...props }: Props) => {
  return (
    <Input
      {...props}
      onChange={(event) => {
        event.currentTarget.value = event.currentTarget.value.replace(
          /[^0-9]/g,
          "",
        );
        if (onChange !== undefined) {
          onChange(event);
        }
      }}
    />
  );
};
