import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';
import { SecondaryFiveOrthographyValuedGradeMapper } from './SecondaryFiveOrthographyValuedGradeMapper';

export class SecondaryFiveOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryFiveOrthographyValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryFiveOrthographyValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    let wordCount = textWithMistakes.countWords();
    if (wordCount > 500) {
      wordCount = 500;
    }
    const ratio = this.computeErrorRatio(textWithMistakes, wordCount);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio === 0:
        return Grade.of('A+');
      case orthographyErrorWordRatio <= 0.4:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 0.6:
        return Grade.of('A-');
      case orthographyErrorWordRatio <= 1.2:
        return Grade.of('B+');
      case orthographyErrorWordRatio <= 1.6:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 1.8:
        return Grade.of('B-');
      case orthographyErrorWordRatio <= 2.2:
        return Grade.of('C+');
      case orthographyErrorWordRatio <= 2.6:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 2.8:
        return Grade.of('C-');
      case orthographyErrorWordRatio <= 3:
        return Grade.of('D+');
      case orthographyErrorWordRatio <= 3.2:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 3.4:
        return Grade.of('D-');
      case orthographyErrorWordRatio <= 3.6:
        return Grade.of('D--');
      case orthographyErrorWordRatio <= 3.8:
        return Grade.of('E+');
      case orthographyErrorWordRatio <= 4:
        return Grade.of('E');
      case orthographyErrorWordRatio <= 4.2:
        return Grade.of('E-');
      default:
        return Grade.of('E--');
    }
  }
}
