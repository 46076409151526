import type { Assignment } from "@/application/domain/Assignment.ts";
import type { Segments } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { Revision } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

export const segmentConverter = {
  fromAssignment: (assignment: Assignment | undefined) => {
    if (!assignment) {
      return [];
    }

    const splitText = assignment.content.split(/(\*\{[\w\d-]+}\*)/);
    const interpolatedText: Segments = [];

    for (let i = 0; i < splitText.length; i++) {
      const isMarked = splitText[i].match(/\*\{([\w\d-]+)}\*/);

      if (isMarked) {
        const [, id] = isMarked;
        const { original, revised, category, penalized } =
          assignment.revisions![id];

        interpolatedText.push(
          new Revision(
            id,
            [original],
            revised,
            category.mainCategory,
            category.subCategory,
            penalized,
          ),
        );
      } else {
        if (splitText[i].length) {
          interpolatedText.push(splitText[i]);
        }
      }
    }

    return interpolatedText;
  },
};
