import clsx from "clsx";
import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { theme } from "../theme/theme";

const PANEL_WIDTH = 450;
export const ANIMATION_DURATION = 200;

type Props = PropsWithChildren<{
  opened: boolean;
  width?: number;
}>;

export const RightPanel = ({
  children,
  opened,
  width = PANEL_WIDTH,
}: Props) => {
  const [shouldRender, setShouldRender] = useState(opened);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (opened) {
      setShouldRender(true);
    } else {
      timeoutId = setTimeout(() => setShouldRender(false), ANIMATION_DURATION);
    }
    return () => clearTimeout(timeoutId);
  }, [opened]);

  return (
    <Panel width={width} className={clsx({ opened })}>
      {shouldRender && children}
    </Panel>
  );
};

const Panel = styled.section<{ width: number }>`
  background-color: ${theme.colors.gray[50]};
  border-left: 1px solid ${theme.colors.gray[200]};

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  right: -${(props) => props.width}px;
  transition: right ${ANIMATION_DURATION}ms ease-in-out;

  &.opened {
    right: 0;
    box-shadow: ${theme.shadowAndBlur.xxlarge};
  }
`;

RightPanel.Header = styled.section`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.gray[50]};
`;

RightPanel.Footer = styled.section`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.gray[50]};
`;

RightPanel.Content = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 24px;
  align-items: stretch;
  gap: 24px;
  overflow-y: auto;
  background: ${theme.colors.gray[25]};
  box-shadow: ${theme.shadowAndBlur.xlarge};
`;

RightPanel.Title = styled.h2`
  color: ${theme.colors.gray[900]};
  text-align: center;
  ${theme.typography.lg.bold}
`;

RightPanel.Subtitle = styled.span`
  color: ${theme.colors.gray[400]};
  text-align: center;
  ${theme.typography.md.medium}
`;
