import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PasswordLockIcon } from "@/assets/passcode-lock.svg";
import { ReactComponent as CloseIcon } from "@/assets/x-close.svg";

import { Button } from "../../lib/button/Button";
import { FeaturedIcon } from "../../lib/icon/FeaturedIcon";
import { theme } from "../../theme/theme";

interface Props {
  setDiscloseSecuredMode: (_: boolean) => void;
}
export const SecuredModeModal = ({ setDiscloseSecuredMode }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, isOpen] = useState<boolean>(true);

  const handleClose = () => {
    void document.documentElement.requestFullscreen();
    isOpen(false);
    setDiscloseSecuredMode(false);
  };

  return (
    <Modal style={modalStyles} isOpen={open} ariaHideApp={false}>
      <SecuredModeDisclosure>
        <DisclosureHeader>
          <DisclosureTitleContainer>
            <FeaturedIcon size={20} color="warning">
              <PasswordLockIcon />
            </FeaturedIcon>
            <DisclosureTitle>{t("securedMode")}</DisclosureTitle>
          </DisclosureTitleContainer>
          <CloseIcon onClick={() => navigate(`/etudiant`)} />
        </DisclosureHeader>
        <DisclosureContent>
          <Content>{t("securedModeDisclosure.description1")}</Content>
          <Content>{t("securedModeDisclosure.description2")}</Content>
          <Content>{t("securedModeDisclosure.description3")}</Content>
        </DisclosureContent>
        <DisclosureActions>
          <UnderstoodButton onClick={handleClose}>
            {t("securedModeDisclosure.understood")}
          </UnderstoodButton>
        </DisclosureActions>
      </SecuredModeDisclosure>
    </Modal>
  );
};

const UnderstoodButton = styled(Button)`
  width: 128px;
`;

const Content = styled.p`
  ${theme.typography.sm.regular};
  color: ${theme.colors.gray[600]};
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(calc(-50%), -50%)",
    width: "650px",
    borderRadius: "12px",
    border: "1px",
    padding: "0",
    backgroundColor: theme.colors.base.white,
    color: theme.colors.base.black,
    boxShadow: "0 20px 24px -4px " + theme.colors.gray[900] + "08",
  },
  overlay: {
    background: theme.colors.gray[700] + "B3",
  },
};

const DisclosureTitleContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const DisclosureTitle = styled.div`
  align-self: center;
  ${theme.typography.lg.semibold};
`;

const SecuredModeDisclosure = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
  background-color: ${theme.colors.base.white};
`;

const DisclosureHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisclosureContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DisclosureActions = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${theme.colors.gray[200]};
`;
