import type { ValuedGrade } from "@emilia/backend/src/common/grade/model/ValuedGrade";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { LoadingSpinner } from "@/application/ui/lib/spinner/LoadingSpinner.tsx";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle";

import { theme } from "../../../../theme/theme";

type SubmitButtonProps = {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  globalGrade: ValuedGrade | null;
};

export const SubmitButton = (props: SubmitButtonProps) => {
  const { onClick, disabled, loading, globalGrade } = props;

  const { t } = useTranslation();

  let content = (
    <>
      <span>{t("confirmGrade")}</span>
      <Badge role="status" aria-label="global-grade">
        {globalGrade?.grade?.toString() || "-"}
      </Badge>
    </>
  );

  if (loading) {
    content = <LoadingSpinner />;
  }

  return (
    <>
      <Button
        id="confirm-grade-button"
        onClick={onClick}
        disabled={disabled || loading}
        aria-label={t("confirmGrade")}
      >
        <SubmitButtonContent>{content}</SubmitButtonContent>
      </Button>
      <Tooltip
        anchorSelect="#confirm-grade-button"
        content={t("confirmGradeTooltip")}
        place="top"
        hidden={!disabled}
        offset={10}
        style={{
          ...tooltipStyle,
          width: "20.25rem",
        }}
      />
    </>
  );
};

const SubmitButtonContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const Badge = styled.span`
  display: flex;
  padding: 0.25rem 0.75rem;
  align-items: center;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.indigo[50]};
  color: ${theme.colors.gray[700]};
`;
