import styled from "styled-components";

import { UserDisplay } from "@/application/ui/lib/avatar/UserDisplay.ts";

import { theme } from "../../theme/theme";

interface UserDisplayInfo {
  imageUrl?: string;
  name: string;
}

interface Props {
  user: UserDisplayInfo;
}

export const Avatar = ({ user: { name, imageUrl } }: Props) => {
  return (
    <AvatarStyle className="avatar">
      {imageUrl ? (
        <img src={imageUrl} alt="User Avatar" />
      ) : (
        <div className="initials">{UserDisplay.getInitials(name)}</div>
      )}
    </AvatarStyle>
  );
};

const AvatarStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${theme.radius.round};
  background-color: ${theme.colors.gray[100]};
  color: white;
  text-transform: uppercase;
  ${theme.typography.md.semibold}

  & > .img {
    width: 100%;
    height: 100%;
    border-radius: ${theme.radius.round};
    object-fit: cover;
  }

  & > .initials {
    color: ${theme.colors.gray[800]};
  }
`;
