import { useQuery } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";

export const useWritingTaskWithAssignments = (writingTaskId: string) => {
  const {
    data: writingTaskWithAssignments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["writingTasks", writingTaskId, "assignments"],
    queryFn: () => AssignmentClient.getForWritingTask(writingTaskId),
  });

  return { writingTaskWithAssignments, isLoading, refetch };
};
