import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as EyeIcon } from "@/assets/eye-off.svg";

export const MaskedHeader = () => {
  const { t } = useTranslation();
  return (
    <MaskedTag>
      <Eye />
      {t("hiddenWritingTask.title")}
    </MaskedTag>
  );
};

const MaskedTag = styled.div`
  ${theme.typography.sm.medium};
  color: ${theme.colors.gray[700]};
  display: flex;
  background-color: ${theme.colors.gray[100]};
  border-radius: 16px;
  padding: 4px 12px;
  align-items: center;
  width: fit-content;
  height: fit-content;
  gap: 4px;
`;

const Eye = styled(EyeIcon)`
  height: 14px;
  width: 14px;
`;
