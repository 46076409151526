import styled from "styled-components";

import { theme } from "../theme/theme";

export const Card = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: ${theme.colors.base.white};
  border-radius: ${theme.radius.large};
  border: ${theme.borders.primary};
  box-shadow: ${theme.shadowAndBlur.small};
`;
