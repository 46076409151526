import type { CloseWritingTaskGroupSummaryResponse } from "@api/CloseWritingTaskGroupSummaryResponse.ts";

import { PersonName } from "../domain/PersonName";
import type { CloseWritingTaskGroupSummary } from "../domain/WritingTaskStatus/CloseWritingTaskGroupSummary";
import { AuthenticatedHttpClient } from "./HttpClient";

export const WritingTaskStatusClient = {
  getTaskClosingConcernedStudents: async (
    taskId: string,
  ): Promise<CloseWritingTaskGroupSummary> => {
    const { data } =
      await AuthenticatedHttpClient.get<CloseWritingTaskGroupSummaryResponse>(
        `/writing-tasks-status/${taskId}/closeTaskSummary`,
      );
    return data.map((x) => ({
      name: new PersonName(x.name.firstName, x.name.lastName),
    }));
  },

  distributeWritingTask: async (writingTaskId: string): Promise<void> => {
    await AuthenticatedHttpClient.post<void>(
      `/writing-tasks-status/${writingTaskId}/distribute`,
    );
  },

  openWritingTask: async (taskId: string): Promise<void> => {
    return await AuthenticatedHttpClient.post(
      `/writing-tasks-status/${taskId}/open`,
    );
  },

  closeWritingTask: async (taskId: string): Promise<void> => {
    return await AuthenticatedHttpClient.post(
      `/writing-tasks-status/${taskId}/close`,
    );
  },

  closeAndSubmitWritingTask: async (taskId: string): Promise<void> => {
    return await AuthenticatedHttpClient.post(
      `/writing-tasks-status/${taskId}/closeAndSubmit`,
    );
  },
};
