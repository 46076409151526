import type {
  AssignmentSummaryResponse,
  WritingTaskStudentSummaryResponse,
  WritingTaskSummaryResponse,
} from "@api/WritingTaskSummaryResponse.ts";

import { AssignmentStatus } from "@/application/domain/Assignment.ts";

import { PersonName } from "../domain/PersonName.ts";
import type {
  WritingTaskAssignmentSummary,
  WritingTaskStudentSummary,
} from "../domain/WritingTaskSummary.ts";
import { AuthenticatedHttpClient } from "./HttpClient.ts";

export class WritingTaskGroupSummaryClient {
  static getForWritingTask = async (
    taskId: string,
  ): Promise<WritingTaskStudentSummary[]> => {
    const { data } =
      await AuthenticatedHttpClient.get<WritingTaskSummaryResponse>(
        `/writing-tasks/${taskId}/summaries`,
      );
    const studentSummaries = data.groupSummary.map(
      StudentSummaryAssembler.fromDto,
    );
    studentSummaries.sort((a, b) => {
      return a.name.fullNameReversed().localeCompare(b.name.fullNameReversed());
    });
    return studentSummaries.map((student, index) => ({
      ...student,
      number: index + 1,
    }));
  };
}

const StudentSummaryAssembler = {
  fromDto: (
    dto: WritingTaskStudentSummaryResponse,
  ): Omit<WritingTaskStudentSummary, "number"> => ({
    id: dto.id,
    name: new PersonName(dto.name.firstName, dto.name.lastName),
    active: dto.active,
    assignment: dto.assignment
      ? AssignmentSummaryAssembler.fromDto(dto.assignment)
      : AssignmentSummaryAssembler.toEmptyAssignment(),
  }),
};

const AssignmentSummaryAssembler = {
  fromDto: (dto: AssignmentSummaryResponse): WritingTaskAssignmentSummary => ({
    id: dto.id,
    grade: dto.grade,
    published: dto.published,
    status: AssignmentStatus[dto.status as keyof typeof AssignmentStatus],
  }),
  toEmptyAssignment: (): WritingTaskAssignmentSummary => ({
    id: "",
    grade: null,
    published: false,
    status: AssignmentStatus.NOT_SUBMITTED,
  }),
};
