import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { HomeroomState } from "@/application/ui/pages/Dashboard/hooks/useSelectedGroup.ts";

import { theme } from "../../../theme/theme";

interface Props {
  homeroomsStates: HomeroomState[];
  onToggleState: (id: string) => void;
}

export const WritingTaskCreationPanelSelectedGroup = ({
  homeroomsStates,
  onToggleState,
}: Props) => {
  const { t } = useTranslation();
  return (
    <GroupContainer>
      <Title>{t("HomeroomGroups")}</Title>
      {homeroomsStates.map((state) => {
        return (
          <HomeroomContainer
            className={clsx({ selected: state.selected })}
            onClick={() => {
              onToggleState(state.id);
            }}
            key={state.id}
          >
            <HomeroomDescription>{state.name}</HomeroomDescription>
            {state.selected && (
              <input
                type="hidden"
                name="groupIds[]"
                hidden={true}
                value={state.id}
              />
            )}
          </HomeroomContainer>
        );
      })}
    </GroupContainer>
  );
};

const HomeroomContainer = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.radius.small};
  padding: 4px 8px;
  box-shadow: 0 0 0 1px ${theme.colors.borders.primary};

  &.selected {
    box-shadow: 0 0 0 2px ${theme.colors.primary[400]};
    background: ${theme.colors.primary[25]};
  }
`;

const HomeroomDescription = styled.mark`
  ${theme.typography.sm.medium}
  color: ${theme.colors.gray[700]};
  font-weight: 500;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.h2`
  ${theme.typography.sm.medium}
  font-weight: 500;
`;
