import { useMutation, useQueryClient } from "@tanstack/react-query";

import { TeacherDashboardClient } from "@/application/client/TeacherDashboardClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

interface UpdateWritingTask {
  formData: FormData;
}

export const useWritingTaskUpdate = (
  homeroomId: string,
  writingTaskId: string,
) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateWritingTask, isLoading } = useMutation({
    mutationFn: ({ formData }: UpdateWritingTask) =>
      TeacherDashboardClient.updateWritingTask(
        homeroomId,
        writingTaskId,
        formData,
      ),
    onSuccess: () => queryClient.invalidateQueries(TeacherQueryKeys.dashboard),
  });

  return { updateWritingTask, updateIsLoading: isLoading };
};
