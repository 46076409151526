import type { WritingTaskDashboardResponse } from "@emilia/backend/src/grade-report/controller/dto/writingTaskDashboardResponseDto.ts";

import type { WritingTaskDashboard } from "../domain/WritingTaskDashboard.ts";
import { AuthenticatedHttpClient } from "./HttpClient.ts";

export class WritingTaskGradeReportClient {
  static getWritingTasks = async (
    homeroomId?: string,
  ): Promise<WritingTaskDashboard | null> => {
    if (!homeroomId) {
      return null;
    }

    const { data } =
      await AuthenticatedHttpClient.get<WritingTaskDashboardResponse>(
        `/grade-report/writingTasks/${homeroomId}`,
      );
    return GradeReportAssembler.fromDto(data);
  };
}

const GradeReportAssembler = {
  fromDto: (dto: WritingTaskDashboardResponse): WritingTaskDashboard => {
    return {
      tasks: dto.tasks,
      group: dto.group,
    };
  },
};
