import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ResultsRepartitionChart } from "@/application/ui/lib/graph/ResultsRepartitionChart";

import type { ReportSummary } from "../../../../../domain/ReportSummary";
import { theme } from "../../../../theme/theme";

export interface Props {
  reportSummary: ReportSummary;
}

const THRESHOLD = 60;

export const SummaryContent = ({ reportSummary }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ResultsRepartitionChart
        results={reportSummary.grades}
        average={reportSummary.average}
        standardDeviation={reportSummary.standardDeviation}
        threshold={THRESHOLD}
      />
      <Footer>
        <MedianBadge
          className={clsx({
            isNegative: reportSummary.median.value < THRESHOLD,
          })}
        >
          {`${t("writingTaskGradeReport.median")}: ${reportSummary.median.grade.toString()}`}
        </MedianBadge>

        <CompletionFraction>
          {`${reportSummary.numberOfGradedAssignments}/${t("student", { count: reportSummary.studentCount })}`}
        </CompletionFraction>
      </Footer>
    </>
  );
};

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const MedianBadge = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: ${theme.radius.round};
  ${theme.typography.sm.medium}
  text-align: center;
  border: 2px solid ${theme.colors.success[600]};
  color: ${theme.colors.success[700]};

  &.isNegative {
    border-color: ${theme.colors.error[600]};
    color: ${theme.colors.error[700]};
  }
`;

export const CompletionFraction = styled.div`
  padding: 12px 20px;
  ${theme.typography.md.semibold}
  text-align: center;
  color: ${theme.colors.gray[600]};
`;
