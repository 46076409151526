import type { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider as AzureMsalProvider } from "@azure/msal-react";
import type { PropsWithChildren } from "react";
import { createContext } from "react";

import { InitMsalInstance } from "../../../authentication/msal/InitMsalInstance";
import { useAuthConfig } from "./AuthConfigProvider";

let msalInstance: PublicClientApplication;

type MsalContext = {
  msalInstance: PublicClientApplication | null;
};

const MsalInstanceContext = createContext<MsalContext>({
  msalInstance: null,
});

export const MsalProvider = ({ children }: PropsWithChildren) => {
  const { tenantId, applicationId } = useAuthConfig();
  msalInstance = InitMsalInstance(tenantId, applicationId);

  return (
    <MsalInstanceContext.Provider value={{ msalInstance }}>
      <AzureMsalProvider instance={msalInstance}>{children}</AzureMsalProvider>
    </MsalInstanceContext.Provider>
  );
};

export { msalInstance };
