import type { ReactNode } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import styled, { css } from "styled-components";

import { ReactComponent as Check } from "@/assets/check.svg";
import { ReactComponent as ChevronDown } from "@/assets/chevron-down-filled.svg";

import { Button } from "../../../../lib/button/Button.tsx";
import { theme } from "../../../../theme/theme.ts";

export interface NameColumnPopoverProps {
  contextualMenuOpen: boolean;
  isNameFirst: boolean;
  setContextualMenuOpen: (_: boolean) => void;
  setIsNameFirst: (_: boolean) => void;
}

export const NameColumnPopover = ({
  contextualMenuOpen,
  setContextualMenuOpen,
  isNameFirst,
  setIsNameFirst,
}: NameColumnPopoverProps) => {
  const { t } = useTranslation();
  return (
    <Popover
      positions={["bottom"]}
      isOpen={contextualMenuOpen}
      padding={16}
      onClickOutside={() => setContextualMenuOpen(false)}
      content={
        <ContextualMenu>
          <ContextMenuLabel>{t("orderBy")}</ContextMenuLabel>
          <ContextMenuButton
            isSelected={isNameFirst}
            onClick={(e) => {
              e.stopPropagation();
              setIsNameFirst(true);
            }}
            hierarchy="neutral"
            variant="text"
          >
            {t("writingTaskNameOrder.lastName")}
            {isNameFirst && <GreenCheck />}
          </ContextMenuButton>
          <ContextMenuButton
            isSelected={!isNameFirst}
            onClick={(e) => {
              e.stopPropagation();
              setIsNameFirst(false);
            }}
            hierarchy="neutral"
            variant="text"
          >
            {t("writingTaskNameOrder.firstName")}
            {!isNameFirst && <GreenCheck />}
          </ContextMenuButton>
        </ContextualMenu>
      }
    >
      <PopoverTrigger
        onClick={() => {
          setContextualMenuOpen(!contextualMenuOpen);
        }}
      >
        <StyledChevron width={15} />
      </PopoverTrigger>
    </Popover>
  );
};

interface PopoverTriggerProps {
  onClick: () => void;
  children: ReactNode;
}

const PopoverTrigger = React.forwardRef<HTMLDivElement, PopoverTriggerProps>(
  (props, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      {props.children}
    </div>
  ),
);

const GreenCheck = styled(Check)`
  > * {
    stroke: ${theme.colors.success[500]};
  }
`;

const ContextMenuLabel = styled.p`
  ${theme.typography.xs.regular}
  margin-bottom: 5px;
`;

const ContextMenuButton = styled(Button)<{ isSelected: boolean }>`
  font-weight: ${theme.fontWeight.medium};
  justify-content: space-between;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${props.theme.colors.gray[100]} !important;
    `}
`;

const ContextualMenu = styled.div`
  ${theme.typography.md.regular}
  display: flex;
  padding: 10px 5px 5px 5px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${theme.radius.medium};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.large};
  width: fit-content;
  border: ${theme.borders.primary};
`;

const StyledChevron = styled(ChevronDown)`
  margin-left: 10px;
`;
