import styled from "styled-components";

import { ReactComponent as EyeIcon } from "@/assets/eye.svg";
import { ReactComponent as EyeOffIcon } from "@/assets/eye-off.svg";

import { theme } from "../../theme/theme";

const Visible = styled(EyeIcon)`
  &:hover {
    opacity: 70% !important;
    cursor: pointer;
  }
  > * {
    stroke: ${theme.colors.gray[800]};
  }
`;

const NotVisible = styled(EyeOffIcon)`
  &:hover {
    opacity: 70%;
    cursor: pointer;
  }
  > * {
    stroke: ${theme.colors.gray[800]};
  }
`;

export const VisibleIcon = (name: string, onClick: () => void) => {
  return (
    <button
      aria-label={name}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <Visible className="visibleIcon" />
    </button>
  );
};

export const NotVisibleIcon = (name: string, onClick: () => void) => {
  return (
    <button
      aria-label={name}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <NotVisible />
    </button>
  );
};
