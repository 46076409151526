import clsx from "clsx";
import { useId, useRef, useState } from "react";
import styled from "styled-components";

import { BaseInput } from "@/application/ui/lib/inputs/inputStyles.ts";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";
import { theme } from "@/application/ui/theme/theme.ts";

const LINE_HEIGHT = 24;
const MINIMUM_ROWS = 4;

interface Props extends Omit<React.HTMLProps<HTMLTextAreaElement>, "size"> {
  size?: "sm";
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
}

export const TextArea = ({
  size = "sm",
  label,
  disabled,
  required,
  ...inputProps
}: Props) => {
  const id = useId();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);

  return (
    <BaseInput.Container>
      {label && (
        <StyledLabel className={clsx({ required, disabled })} htmlFor={id}>
          {label}
        </StyledLabel>
      )}

      <BaseInput.InputBox
        className={clsx(`size-${size}`, { focused, disabled })}
        onClick={() => inputRef.current?.focus()}
      >
        <StyledTextArea
          id={id}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...inputProps}
          rows={MINIMUM_ROWS}
          ref={inputRef}
          required={required}
        />
      </BaseInput.InputBox>
    </BaseInput.Container>
  );
};

const StyledTextArea = styled.textarea`
  ${BaseInput.InputStyle(theme)}
  ${scrollbarStyle(theme)}
    
    height: auto;
  min-height: ${MINIMUM_ROWS * LINE_HEIGHT}px;
  resize: vertical;
  overflow-x: hidden;
  box-sizing: border-box;

  line-height: ${LINE_HEIGHT}px;
`;

const StyledLabel = styled(BaseInput.Label)`
  &.disabled {
    color: ${theme.colors.gray[500]};
  }
`;
