import "reflect-metadata";
import "./reset.css";
import "./i18n/i18n.ts";

import { ErrorBoundary } from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import { MonitoringService } from "@/application/services/MonitoringService.ts";
import App from "@/application/ui/pages/App/App.tsx";

MonitoringService.init();
const app = document.getElementById("root")!;
app.setAttribute("translate", "no"); //https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/translate

ReactDOM.createRoot(app).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
