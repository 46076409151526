export const subcriteriaFr = {
  labels: {
    ex1: "Exemple 1",
    ex2: "Exemple 2",
    ex3: "Exemple 3",
  },
  notCategorise: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  syntax: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  punctuation: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  spelling: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  grammar: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  vocabulary: {
    label: "Erreur non catégorisée",
    definition: "Erreur non catégorisée",
  },
  V1: {
    label: "Terme (ou expression) imprécis, erroné ou impropre",
    definition:
      "Emilia croit que tu t'es trompé et qu'un autre mot plus adéquat pour préciser ta pensée.",
    ex1: {
      incorrect: " … j'ai <b> allé </b> à l'épicerie …",
      correct: " … j'ai <b> été </b> à l'épicerie …",
    },
    ex2: {
      incorrect: "25$ <b> égal </b> 30$",
      correct: "25$ <b> équivaut à </b> 30$",
    },
    ex3: {
      incorrect: "L'enceinte <b> jouant </b> de la musique ...",
      correct: "L'enceinte <b> diffusant </b> de la musique ...",
    },
  },
  V2: {
    label: "Anglicisme",
    definition:
      "Emilia croit que tu as fait une traduction directe en provenance de l'anglais qui n'est pas acceptée en français.",
    ex1: {
      incorrect: "Je suis <b> tombé sur </b> ...",
      correct: "J'ai <b> découvert </b> ...",
    },
    ex2: {
      incorrect: "Les <b> métrics </b>",
      correct: "Les <b> mesures </b>",
    },
    ex3: {
      incorrect: "<b> Actually </b>, ça va vraiment mal",
      correct: "<b> Actuellement </b>, ça va vraiment mal",
    },
  },
  V3: {
    label: "Registre de langue inapproprié",
    definition:
      "Emilia voit que tu utilises un terme qui n'est pas accepté à l'écrit.",
    ex1: {
      incorrect: "Le <b> gars </b> est arrivé",
      correct: "Le <b> garçon </b> est arrivé",
    },
    ex2: {
      incorrect: "<b> L'ordi </b> ne fonctionnait plus",
      correct: "<b> L'ordinateur </b> ne fonctionnait plus",
    },
    ex3: {
      incorrect: "Je suis <b> tanné </b> de le répéter",
      correct: "Je suis <b> fatigué </b> de le répéter",
    },
  },
  V4: {
    label: "Redondance ou répétition abusive de termes ou d’expressions",
    definition:
      "Emilia remarque que tu utilises certains mots de manière répétitive.",
  },
  VX: {
    label: "Autre erreur de vocabulaire",
    definition: "Tout autre type d'erreur de vocabulaire.",
  },
  S1: {
    label: "Phrase mal construite ",
    definition:
      "Dans cette phrase, on a dû ajouter ou retirer certaines classes de mots. Il se peut aussi qu'il manque un groupe essentiel (groupe du verbe, prédicat) ou qu'il y ait d'autres composantes essentielles manquantes.",
    ex1: {
      incorrect: "Il faut que je trouve un moyen...",
      correct: "Il faut que je <b> me </b> trouve un moyen...",
    },
    ex2: {
      incorrect: "Je ne pense pas <b> de </b> être capable...",
      correct: "Je ne pense pas être capable de...",
    },
    ex3: {
      incorrect: "Ils vivent...",
      correct: "Ils <b> y </b> vivent...",
    },
  },
  S1A: {
    label: "Composante essentielle absente",
    definition:
      "Si cette phrase a ce code, c'est qu'il manque un verbe, un sujet ou parfois, des compléments absolument nécessaires.",
    ex1: {
      incorrect: "Pour leur bonheur",
      correct: "<b> Ils ne vivent que </b> pour leur bonheur",
    },
    ex2: {
      incorrect: "Ils beaucoup les poissons",
      correct: "Ils <b> aiment </b> beaucoup les poissons",
    },
    ex3: {
      incorrect: "Je me penche",
      correct: "Je me penche <b> pour éviter la branche </b>",
    },
  },
  S1B: {
    label: "Segmentation de la phrase",
    definition:
      "Emilia a décidé de couper ta phrase pour en faire deux structurellement meilleures. ",
  },
  S1C: {
    label: "Segmentation de la phrase, retrait d'un point",
    definition:
      "Ici, Emilia a fusionné deux de tes phrases, souvent parce que l'on trouve des composantes complémentaires.",
  },
  S2: {
    label: "Temps ou mode verbal erroné",
    definition:
      "Ici, il y a une erreur de temps de verbe (deux temps qui ne vont pas ensemble dans le cas de ta phrase).",
    ex1: {
      incorrect: "Je pense qu'elle <b> a </b>",
      correct: "Je pense qu'elle <b> avait </b>",
    },
    ex2: {
      incorrect: "Ils <b> ont </b>",
      correct: "Ils <b> avaient </b>",
    },
    ex3: {
      incorrect: "... et que vous <b> laisserez </b> ...",
      correct: "... et que vous <b> laissiez </b> ...",
    },
  },
  S4: {
    label: "Mots liens (préposition, conjonction) mal choisis",
    definition:
      "Emilia remarque une erreur de structure causée par un mauvais choix de terme. ",
    ex1: {
      incorrect: "Mont Fuji <b> en </b> Tokyo",
      correct: "Mont Fuji <b> à </b> Tokyo",
    },
    ex2: {
      incorrect: "Ils commençaient <b> de </b> crier",
      correct: "Ils commençaient <b> à </b> crier",
    },
    ex3: {
      incorrect: "Je marche <b> sur </b> la rue",
      correct: "Je marche <b> dans </b> la rue",
    },
  },
  S5: {
    label: "Marques de négation ou de restriction absentes ou mal employées",
    definition:
      "Toute négation dans un texte doit venir en double, sauf dans les cas où tu fais parler tes personnages.",
    ex1: {
      incorrect: "J'ai jamais dit ça!",
      correct: "Je <b> n'ai </b> jamais dit ça!",
    },
    ex2: {
      incorrect: "Tu veux plus aller ...",
      correct: "Tu <b> ne </b> veux plus aller ...",
    },
    ex3: {
      incorrect: "Je n'ai <b> encore pas </b> ...",
      correct: "Je n'ai <b> pas encore </b> ...",
    },
  },
  S6: {
    label: "Déterminants ou pronoms mal choisis",
    definition:
      "Emilia croit que tu t'es trompé en choisissant le mauvais pronom ou déterminant dans le contexte de ta phrase. ",
    ex1: {
      incorrect:
        "La seule exception serait de <b> la </b> donner la chance ...",
      correct: "La seule exception serait de <b> lui </b> donner la chance ...",
    },
    ex2: {
      incorrect: "Les lutins <b> de </b> 24 décembre",
      correct: "Les lutins <b> du </b> 24 décembre",
    },
    ex3: {
      incorrect: "C'est la fin <b> du </b> deuxième période",
      correct: "C'est la fin <b> de la </b> deuxième période",
    },
  },
  S7: {
    label: "Auxiliaire de conjugaison (être ou avoir) incorrect",
    definition: "Quand tu te trompes entre le verbe avoir ou être.",
    ex1: {
      incorrect: "J'<b> ai </b> arrivé à la maison",
      correct: "Je <b> suis </b> arrivé à la maison",
    },
    ex2: {
      incorrect: "Merci beaucoup d'<b> avoir </b> venu à temps",
      correct: "Merci beaucoup d'<b> être </b> venu à temps",
    },
    ex3: {
      incorrect: "Les arbres déracinés qui <b> ont </b> tombés",
      correct: "Les arbres déracinés qui <b> sont </b> tombés",
    },
  },
  SX: {
    label: "Autre erreur de syntaxe",
    definition: "Tout autre type d'erreur de syntaxe. ",
  },
  P1: {
    label: "Virgule superflue (à supprimer)",
    definition: "Emilia a retiré une virgule.",
  },
  P2: {
    label: "Virgule manquante (à ajouter)",
    definition:
      "Emilia pense que tu dois ajouter une virgule dans cette situation.",
  },
  P3: {
    label:
      "Virgule erronée (à remplacer par un autre signe de ponctuation ou une conjonction)",
    definition:
      "Emilia remplace une virgule par un autre signe de ponctuation.",
  },
  P4: {
    label: "Type de point mal choisi en fonction du type de phrase (.!?)",
    definition:
      "Emilia croit que tu as écrit un type de phrase qui exige son type de point à la fin de la phrase.",
  },
  P5: {
    label:
      "Point erroné (à remplacer par un autre signe de ponctuation ou une conjonction)",
    definition:
      "Emilia croit que ton point devrait être en fait, sur un autre point.",
  },
  P6: {
    label:
      "Erreur dans l’emploi des guillemets ou des parenthèses (à ajouter ou à supprimer)",
    definition:
      "Emilia croit qu'il manque ou que tu dois retirer/modifier les guillemets.",
  },
  P7: {
    label:
      "Erreur dans l’emploi du deux-points ou du point-virgule (à ajouter ou à supprimer)",
    definition:
      "Emilia croit qu'il y a une erreur avec les deux points que tu as utilisés.",
  },
  P8: {
    label: "Oubli de la majuscule après le point",
    definition:
      "Ici tu as terminé une phrase (avec un signe de ponctuation (., !, ?)) et tu as omis de mettre la majuscule après.",
  },
  P9: {
    label:
      "Mauvais guillemets ou retrait des !!! ou autre répétition de ce genre.",
    definition:
      "Ici, on peut garder les doubles ou triples signes de ponctuation à l'intérieur de guillemets.",
  },
  PX: {
    label: "Autre erreur de ponctuation",
    definition: "Tout autre type d'erreur de ponctuation.",
  },
  G1: {
    label: "Erreur d’accord du verbe avec le sujet",
    definition:
      "La terminaison de ton verbe ne démontre pas que tu l'as bien accordé avec son sujet. ",
    ex1: {
      incorrect: "Tu <b> doit </b> acheter",
      correct: "Tu <b> dois </b> acheter",
    },
    ex2: {
      incorrect: "Tout le monde me <b> regardais </b>",
      correct: "Tout le monde me <b> regardait </b>",
    },
    ex3: {
      incorrect: "Ils <b> manges </b> des fruits",
      correct: "Ils <b> mangent </b> des fruits",
    },
  },
  G2: {
    label:
      "Erreur d’accord des déterminants, des adjectifs et des participes passés employés seuls",
    definition:
      "Ici, ton déterminant ou l'adjectif ne semble pas être accordé en genre et en nombre avec son donneur d'accord.",
    ex1: {
      incorrect: "Tes mains sont trop <b> chauds </b>",
      correct: "Tes mains sont trop <b> chaudes </b>",
    },
    ex2: {
      incorrect: "J'espère que <b> cet </b> lettre ...",
      correct: "J'espère que <b> cette </b> lettre ...",
    },
    ex3: {
      incorrect: "... diverses activités bien <b> situés </b> ...",
      correct: "... diverses activités bien <b> situées </b> ...",
    },
  },
  G3: {
    label: "Marque du nombre incorrecte pour le nom ou le pronom",
    definition:
      "Ici, c'est ton nom ou ton déterminant qui ne semble pas être du bon genre ou nombre. C'est lui le donneur, on ne peut donc pas dire que tu l'as mal accordé.",
    ex1: {
      incorrect: "Tous les <b> mercredi </b>",
      correct: "Tous les <b> mercredis </b>",
    },
    ex2: {
      incorrect: "Les nouveaux <b> sport </b>",
      correct: "Les nouveaux <b> sports </b>",
    },
    ex3: {
      incorrect: "Les <b> bruit </b> aigus",
      correct: "Les <b> bruits </b> aigus",
    },
  },
  G4: {
    label:
      "Erreur de forme ou d’accord des participes passés (employés avec «avoir» et «être»)",
    definition:
      "Ici, c'est un mauvais accord de ton participe passé (le verbe qui suit parfois avoir ou être).",
    ex1: {
      incorrect: "Cette fille est <b> allé </b> ...",
      correct: "Cette fille est <b> allée </b> ...",
    },
    ex2: {
      incorrect: "Elle est gravement <b> blessé </b>",
      correct: "Elle est gravement <b> blessée </b>",
    },
    ex3: {
      incorrect: "Ma soeur s'est <b> blessé </b>",
      correct: "Ma soeur s'est <b> blessée </b>",
    },
  },
  G5: {
    label: "Erreur de conjugaison",
    definition:
      "Certains verbes ou accords sont mélangés. Cette situations se produit seulement avec des verbes.",
    ex1: {
      incorrect: "Elle se <b> fesait </b> ...",
      correct: "Elle se <b> faisait </b> ...",
    },
    ex2: {
      incorrect: "Donc, je les <b> remercis </b> ...",
      correct: "Donc, je les <b> remercie </b> ...",
    },
    ex3: {
      incorrect: "Puisque cela <b> nuie </b>...",
      correct: "Puisque cela <b> nuit </b> ...",
    },
  },
  G6: {
    label: "Confusion entre les finales en «é» (-é, -er et -ez)",
    definition:
      "Emilia détecte que tu sembles mélanger des terminaisons avec le son «é» (ai, ez, er, é, ée, ées…).",
    ex1: {
      incorrect: "Ceci va <b> aidé </b>",
      correct: "Ceci va <b> aider </b>",
    },
    ex2: {
      incorrect: "J'ai <b> rêver </b> de ...",
      correct: "J'ai <b> rêvé </b> de ...",
    },
    ex3: {
      incorrect: "Je dois <b> payez </b>",
      correct: "Je dois <b> payer </b>",
    },
  },
  G7: {
    label: "Invariabilité de la classe de mot non respectée ",
    definition:
      "Ce sont des mots qui sont invariables que tu sembles tenter d'accorder. Ces mots s'écrivent toujours ainsi, dans toutes les circonstances. ",
    ex1: {
      incorrect: "Je <b> leurs </b> parle",
      correct: "Je <b> leur </b> parle",
    },
    ex2: {
      incorrect: "<b> Parmis </b> les invités",
      correct: "<b> Parmi </b> les invités",
    },
    ex3: {
      incorrect: "J'ai <b> beaucoups </b> d'amis",
      correct: "J'ai <b> beaucoup </b> d'amis",
    },
  },
  GX: {
    label: "Autre erreur d’orthographe grammaticale",
    definition: "Tout autre type d'erreur d'orthographe grammaticale. ",
  },
  O1: {
    label: "Orthographe d'usage incorrecte (autres cas que O2)",
    definition:
      "L'orthographe, c'est les lettres du mot que tu tentes d'écrire. ",
    ex1: {
      incorrect: "L'accessibilité <b> accomode </b> ...",
      correct: "L'accessibilité <b> accommode </b> ...",
    },
    ex2: {
      incorrect: "Utilise la quand tu es <b> enuyé </b>",
      correct: "Utilise la quand tu es <b> ennuyé </b>",
    },
    ex3: {
      incorrect: "J'ai <b> dicidé </b>",
      correct: "J'ai <b> décidé </b>",
    },
  },
  O2: {
    label:
      "Accents, traits d'union, apostrophes et cédilles absents ou superflus",
    definition:
      "Tu as probablement les bonnes lettres pour le mot, il reste à voir les accents, les apostrophes, les traits d'union, les cédilles…",
    ex1: {
      incorrect: "Les <b> taches </b> ne partaient pas",
      correct: "Les <b> tâches </b> ne partaient pas",
    },
    ex2: {
      incorrect: "<b> N'est ce </b> pas fantastique?",
      correct: "<b> N'est-ce </b> pas fantastique?",
    },
    ex3: {
      incorrect: "Le <b> garcon </b>",
      correct: "Le <b> garçon </b>",
    },
  },
  O3: {
    label: "Confusions homophoniques fréquentes",
    definition:
      "Un homophone est un mot qui s'écrit de manière différente pour des raisons différentes, mais qui a toujours le même son quand on le prononce. ",
    ex1: {
      incorrect: "Tu as été <b> a </b> la piscine",
      correct: "Tu as été <b> à </b> la piscine",
    },
    ex2: {
      incorrect: "Je suis <b> sur </b> que tu es capable ...",
      correct: "Je suis <b> sûr </b> que tu es capable",
    },
    ex3: {
      incorrect: "Pierre <b> est </b> Ziphey vont ...",
      correct: "Pierre <b> et </b> Ziphey vont ...",
    },
  },
  O4: {
    label: "Emploi de la majuscule (ou de la minuscule) incorrect",
    definition:
      "Emilia a détecté que tu as fait des fautes de majuscule, principalement concernant les noms propres et les règles de certaines utilisations de noms propres dans la position d'adjectif. ",
    ex1: {
      incorrect: "... <b> sofia </b> est venue ...",
      correct: "... <b> Sofia </b> est venue ...",
    },
    ex2: {
      incorrect: "Les <b> italiens </b> vont cuisiner",
      correct: "Les <b> Italiens </b> vont cuisiner",
    },
    ex3: {
      incorrect: "Les pâtés <b> Chinois </b>",
      correct: "Les pâtés <b> chinois </b>",
    },
  },
  OX: {
    label: "Autres erreurs d’orthographe d’usage",
    definition: "Tout autre type d'erreur d'orthographe d'usage. ",
  },
};
