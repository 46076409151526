import "react-tooltip/dist/react-tooltip.css";

import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as ScaleIcon } from "@/assets/scale-icon.svg";

import { Button } from "../../../../lib/button/Button";
import { theme } from "../../../../theme/theme";
import {
  DetailedGradingScaleModal,
  type DetailedGradingScaleModalRef,
} from "./DetailedGradingScaleModal";

interface Props {
  gradingScaleType: GradingScaleType;
}

export const DetailedGradingScale = ({ gradingScaleType }: Props) => {
  const gradingScaleModalRef = useRef<DetailedGradingScaleModalRef>(null);
  const { t } = useTranslation();

  const openGradeScale = (scaleType: GradingScaleType) => {
    gradingScaleModalRef.current?.display(scaleType);
  };

  return (
    <>
      <ScaleDetailsButton
        variant="text"
        hierarchy="secondary"
        size="md"
        onClick={() => openGradeScale(gradingScaleType)}
      >
        {t("gradingScaleDetails")}
        <ScaleIcon />
      </ScaleDetailsButton>
      <DetailedGradingScaleModal ref={gradingScaleModalRef} />
    </>
  );
};

const ScaleDetailsButton = styled(Button)`
  padding: 0 !important;
  width: auto;
  color: ${theme.colors.gray[600]} !important;
`;
