import type { StudentWritingTaskContentResponse } from "@api/StudentWritingTaskContentResponse.ts";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";

export const StudentWritingTaskClient = {
  fetchStudentWritingTaskContent: async (
    writingTaskId: string,
  ): Promise<StudentWritingTaskContentResponse> => {
    const { data } =
      await AuthenticatedHttpClient.get<StudentWritingTaskContentResponse>(
        `/student/writing-tasks/${writingTaskId}`,
      );

    return data;
  },
};
