import { Category } from '../../../mistakes/domain/model/Category';
import type { TextWithMistakes } from '../../../mistakes/domain/model/TextWithMistakes';

export class SyntaxGradeComputingStrategy {
  protected computeErrorCount(textWithMistakes: TextWithMistakes): number {
    const syntaxErrorCount = textWithMistakes.countPenalizedErrorsForCategory(
      Category.SYNTAX,
    );

    const punctuationErrorCount =
      textWithMistakes.countPenalizedErrorsForCategory(Category.PUNCTUATION);

    return syntaxErrorCount + punctuationErrorCount;
  }

  protected computeErrorRatio(
    textWithMistakes: TextWithMistakes,
    wordCount?: number,
  ): number {
    const errorCount = this.computeErrorCount(textWithMistakes);
    const totalWordCount = wordCount
      ? wordCount
      : textWithMistakes.countWords();
    const ratio = +((errorCount / totalWordCount) * 100).toFixed(2);
    return ratio;
  }
}
