export type DeconstructedSelection = {
  before: string;
  selection: string;
  after: string;
};

export type PointerCoordinates = {
  x: number;
  y: number;
};

const isolateSelectionInText = (
  text: string,
  selection: Selection,
  trim: boolean,
) => {
  const anchor = selection.anchorOffset;
  const focus = selection.focusOffset;
  const start = Math.min(anchor, focus);
  const end = Math.max(anchor, focus);

  const selectionString = selection.toString();
  const leadingSpaces = selectionString.match(/^(\s*)/)?.[0].length ?? 0; // Cherche les espaces au début
  const trailingSpaces = selectionString.match(/(\s*)$/)?.[0].length ?? 0; // Cherche les espaces à la fin

  return {
    before: text.substring(0, start + (trim ? leadingSpaces : 0)),
    selection: trim ? selectionString.trim() : selectionString,
    after: text.substring(end - (trim ? trailingSpaces : 0)),
  };
};

const deconstructTextWithSelection = (
  text: string,
  selection: Selection,
  trim: boolean,
): DeconstructedSelection | undefined => {
  const isSelectionEmpty = !selection.toString();

  if (isSelectionEmpty) return;

  const selectionThroughMultipleLevels =
    selection.anchorNode?.parentNode !== selection.focusNode?.parentNode;

  if (selectionThroughMultipleLevels) {
    return;
  }

  return isolateSelectionInText(text, selection, trim);
};

export const deconstructTextBasedOnAction = (
  text: string,
  mouseUpCoordinates: PointerCoordinates,
  mouseDownCoordinates: PointerCoordinates | null,
) => {
  const isClick =
    !!mouseDownCoordinates &&
    mouseUpCoordinates.x === mouseDownCoordinates.x &&
    mouseUpCoordinates.y === mouseDownCoordinates.y;

  if (isClick) {
    return deconstructTextWithSelection(text, window.getSelection()!, true);
  } else {
    return deconstructTextWithSelection(text, window.getSelection()!, false);
  }
};
