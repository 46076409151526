import type { Grade } from '../../../model/Grade';
import { ValuedGrade } from '../../../model/ValuedGrade';
import type { ValuedGradeMapper } from '../../../model/valuedGradeFactory/ValuedGradeMapper';

export class SecondaryFourOrthographyValuedGradeMapper
  implements ValuedGradeMapper
{
  create(grade: Grade): ValuedGrade {
    switch (grade.toString()) {
      case 'A+':
        return new ValuedGrade(grade, 100);
      case 'A':
        return new ValuedGrade(grade, 95);
      case 'A-':
        return new ValuedGrade(grade, 90);
      case 'B+':
        return new ValuedGrade(grade, 85);
      case 'B':
        return new ValuedGrade(grade, 80);
      case 'B-':
        return new ValuedGrade(grade, 75);
      case 'C':
        return new ValuedGrade(grade, 65);
      case 'D':
        return new ValuedGrade(grade, 50);
      case 'E':
        return new ValuedGrade(grade, 20);
      case 'E--':
        return new ValuedGrade(grade, 0);
      default:
        throw new Error(
          `Cannot find value matching ${grade.toString()} orthography for secondary four`,
        );
    }
  }
}
