import { useQuery } from "@tanstack/react-query";

import { GlobalRankingClient } from "@/application/client/GlobalRankingClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useSubCriterionRanking = (
  writingTaskId: string,
  revisionCategory: string,
  studentId?: string,
) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.subCriterionDistribution(
      writingTaskId,
      revisionCategory,
      studentId,
    ),
    queryFn: () =>
      GlobalRankingClient.getSubCriterionDistribution(
        writingTaskId,
        revisionCategory,
        studentId,
      ),
  });
  return { correctionDistribution: data };
};
