import {
  Category,
  RevisionCategoryGroups,
} from "@emilia/backend/src/common/mistakes/domain/model/Category";

export class AssignmentRevisionFilters {
  constructor(
    private groupsVisibilityState: {
      [RevisionCategoryGroups.ORTHOGRAPHY]: boolean;
      [RevisionCategoryGroups.SYNTAX]: boolean;
    },
    private categoriesVisibilityState: {
      [Category.GRAMMAR]: boolean;
      [Category.SYNTAX]: boolean;
      [Category.PUNCTUATION]: boolean;
      [Category.SPELLING]: boolean;
      [Category.UNPENALIZED]: boolean;
      [Category.UNSPECIFIED]: boolean;
      [Category.VOCABULARY]: boolean;
    },
  ) {}

  public get groups() {
    return this.groupsVisibilityState;
  }

  public get categories() {
    return this.categoriesVisibilityState;
  }

  public showGroup = (group: RevisionCategoryGroups) => {
    return this.groupsVisibilityState[group];
  };

  public showCategory = (category: Category) => {
    return this.categoriesVisibilityState[category];
  };

  protected updateGroupVisibilityState = (
    group: RevisionCategoryGroups,
    value?: boolean,
  ) => {
    this.groupsVisibilityState[group] =
      value ?? !this.groupsVisibilityState[group];
  };

  protected updateCategoryVisibilityState = (
    category: Category,
    value?: boolean,
  ) => {
    this.categoriesVisibilityState[category] =
      value ?? !this.categoriesVisibilityState[category];
  };

  public handleGroupVisibility = (group: RevisionCategoryGroups) => {
    this.updateGroupVisibilityState(group);
    const updatedState = this.groupsVisibilityState[group];
    switch (group) {
      case RevisionCategoryGroups.SYNTAX:
        this.updateCategoryVisibilityState(Category.SYNTAX, updatedState);
        this.updateCategoryVisibilityState(Category.PUNCTUATION, updatedState);
        break;
      case RevisionCategoryGroups.ORTHOGRAPHY:
        this.updateCategoryVisibilityState(Category.SPELLING, updatedState);
        this.updateCategoryVisibilityState(Category.GRAMMAR, updatedState);
        break;
      default:
        break;
    }
  };

  public handleCategoryVisibility = (category: Category) => {
    this.updateCategoryVisibilityState(category);

    switch (category) {
      case Category.GRAMMAR:
      case Category.SPELLING:
        this.updateGroupVisibilityState(
          RevisionCategoryGroups.ORTHOGRAPHY,
          this.categoriesVisibilityState[Category.SPELLING] ||
            this.categoriesVisibilityState[Category.GRAMMAR],
        );
        break;
      case Category.SYNTAX:
      case Category.PUNCTUATION:
        this.updateGroupVisibilityState(
          RevisionCategoryGroups.SYNTAX,
          this.categoriesVisibilityState[Category.PUNCTUATION] ||
            this.categoriesVisibilityState[Category.SYNTAX],
        );
        break;
      default:
        break;
    }
  };
}
