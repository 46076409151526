import type {
  CorrectionRankingDto,
  SubtypeRankingDto,
} from "@emilia/backend/src/grade-report/controller/dto/correctionRankingDto";
import type { GlobalRankingResponseDto } from "@emilia/backend/src/grade-report/controller/dto/GlobalRankingResponseDto.ts";

import type {
  CorrectionDistribution,
  GlobalRanking,
} from "../domain/GlobalRanking.ts";
import { PersonName } from "../domain/PersonName.ts";
import { AuthenticatedHttpClient } from "./HttpClient.ts";

export class GlobalRankingClient {
  static getForWritingTask = async (
    taskId: string,
    criteria?: string,
  ): Promise<GlobalRanking> => {
    const { data } =
      await AuthenticatedHttpClient.get<GlobalRankingResponseDto>(
        `/global-ranking/${taskId}${criteria ? `/${criteria}` : ""}`,
      );
    return GlobalRankingAssembler.fromDto(data);
  };

  static getSubCriterionDistribution = async (
    taskId: string,
    criterion: string,
    studentId?: string,
  ): Promise<CorrectionDistribution> => {
    const { data } = await AuthenticatedHttpClient.get<CorrectionRankingDto>(
      `/global-ranking/${taskId}/category/${criterion}${studentId ? `/${studentId}` : ""}`,
    );
    return SubcriterionDistributionAssembler.fromDto(data);
  };
}

const GlobalRankingAssembler = {
  fromDto: (dto: GlobalRankingResponseDto): GlobalRanking => ({
    brackets: dto.brackets.map((x) => ({
      range: x.range,
      students: x.students.map((student) => {
        return {
          id: student.id,
          name: new PersonName(student.name.firstName, student.name.lastName),
        };
      }),
      percentage: x.percentage,
      isMajority: x.isMajority,
    })),
  }),
};

const SubcriterionDistributionAssembler = {
  fromDto: (dto: CorrectionRankingDto): CorrectionDistribution => ({
    globalType: dto.globalType,
    subtypes: dto.subtypes.map((dtoSubtype: SubtypeRankingDto) => {
      return {
        subtypeName: dtoSubtype.subtypeName,
        userCounts: dtoSubtype.userCounts.map((userCount) => {
          return {
            id: userCount.id,
            studentName: new PersonName(
              userCount.studentName.firstName,

              userCount.studentName.lastName,
            ),

            count: userCount.count,
          };
        }),

        total: dtoSubtype.total,
      };
    }),
  }),
};
