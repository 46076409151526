import clsx from "clsx";
import type { ComponentProps } from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import type { Button } from "./Button";

const EMPTY_WIDTH = 60;
const PIXEL_PER_CHARACTER = 20;

type Props = ComponentProps<typeof Button> & {
  isToggled: boolean;
  defaultText: string;
  toggledText: string;
};

export const SlidingToggleButton = ({
  onClick,
  isToggled,
  defaultText,
  toggledText,
  ...props
}: Props) => {
  const containerWidth =
    EMPTY_WIDTH +
    PIXEL_PER_CHARACTER * Math.max(defaultText.length, toggledText.length);

  return (
    <ToggleButtonContainer {...props} onClick={onClick} $width={containerWidth}>
      <ToggleButtonBackground>
        <ToggleButtonBackgroundState>{defaultText}</ToggleButtonBackgroundState>
        <ToggleButtonBackgroundState>{toggledText}</ToggleButtonBackgroundState>
      </ToggleButtonBackground>

      <ToggleButtonState className={clsx({ isToggled })}>
        {isToggled ? toggledText : defaultText}
      </ToggleButtonState>
    </ToggleButtonContainer>
  );
};

const ToggleButtonContainer = styled.button<{ $width: number }>`
  z-index: 0;
  width: ${(props) => props.$width}px;
  height: 44px;
  padding: 4px;
  background-color: ${theme.colors.grayBlue[100]};
  border-color: ${theme.colors.gray[300]};
  border-radius: ${theme.radius.round};
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: 2px solid transparent;
  ${theme.typography.sm.medium}

  &:hover, &:focus {
    background-color: ${theme.colors.gray[300]};
  }

  &:disabled {
    background-color: inherit;
    color: ${theme.colors.gray[300]};

    &:hover {
      color: ${theme.colors.gray[300]};
    }
  }
`;

const ToggleButtonBackground = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleButtonState = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -37px;
  background-color: ${theme.colors.base.white};
  border: ${theme.borders.primary};
  border-radius: ${theme.radius.round};
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.typography.sm.semibold}
  transition: margin 0.25s;
  margin-left: 0;

  &.isToggled {
    margin-left: calc(45% - 2px);
  }
`;

const ToggleButtonBackgroundState = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.typography.sm.semibold}
  box-sizing: border-box;
`;
