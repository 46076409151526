import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { SyntaxGradeComputingStrategy } from '../SyntaxGradeComputingStrategy';
import { SecondaryOneSyntaxValuedGradeMapper } from './SecondaryOneSyntaxValuedGradeMapper';

export class SecondaryOneSyntaxGradeComputingStrategy
  extends SyntaxGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryOneSyntaxValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryOneSyntaxValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);

    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio <= 1.85:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 3.45:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 5.19:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 6.92:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 8.65:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
