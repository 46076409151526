import { useTextSelection } from "@/application/ui/pages/Revision/hooks/useTextSelection.ts";

export interface DeconstructedSelection {
  before: string;
  selection: string;
  after: string;
}

export interface NormalTextProps {
  text: string;
  onSelect: (payload: DeconstructedSelection) => void;
}

export const NormalText = ({ text, onSelect }: NormalTextProps) => {
  const ref = useTextSelection({
    text,
    onSelect,
  });

  return <span ref={ref}>{text}</span>;
};
