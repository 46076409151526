import { useMutation } from "@tanstack/react-query";

import { GradeAssignmentService } from "@/application/services/GradeAssignmentService.ts";

type UseGradeAssignmentProps = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useSaveGradedAssignment = ({
  onSuccess,
  onError,
}: UseGradeAssignmentProps) => {
  const { mutate, isLoading } = useMutation(GradeAssignmentService.grade, {
    onSuccess,
    onError,
  });

  return {
    saveGradedAssignment: mutate,
    assignmentIsSaving: isLoading,
  };
};
