import type { TFunction } from "i18next";

import type { Bracket } from "@/application/domain/GlobalRanking.ts";

export class BracketRangePresenter {
  constructor(
    public range: Bracket["range"],
    private t: TFunction,
  ) {}

  public display(mode: "letter" | "percent") {
    return mode === "letter"
      ? this.range.letter
      : `${this.range.max} ${this.t("writingTaskGradeReport.to")} ${this.range.min}%`;
  }
}
