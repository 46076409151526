import type { ValuedGrade } from "@api/grade/model/ValuedGrade";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { CriteriaResultChartByGrade } from "@/application/ui/lib/graph/CriteriaResultChartByGrade";
import { CriteriaResultChartByPercentage } from "@/application/ui/lib/graph/CriteriaResultChartByPercentage";

import { theme } from "../../../theme/theme";
import { GradesFilter } from "../GradeStates/GradeProvider";
import { useGrades } from "../GradeStates/useGrades";
import type { SortedGrades } from "../WritingTaskGradeReport";

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

export type CriteriaResultChartProps = {
  sortedGrades: SortedGrades;
  average?: ValuedGrade;
  isLetterMode: boolean;
  selectedCriteria: string;
};

export const CriteriaResultChart = ({
  sortedGrades,
  isLetterMode,
  average,
  ...rest
}: CriteriaResultChartProps) => {
  const { gradeContextDto, updateContext } = useGrades();

  const letterGrades = sortedGrades?.map((x) => ({
    grade: x.grade.grade,
    criterion: x.criterion,
  }));

  const percentageGrades = sortedGrades?.map((x) => ({
    grade: x.grade.value,
    criterion: x.criterion,
  }));

  const onSelectCriteria = (value: string) => {
    let isSubMode = true;
    if (
      value === EvaluationCriterion.ADAPTATION.toString() ||
      value === EvaluationCriterion.COHERENCE.toString()
    ) {
      isSubMode = false;
    }

    updateContext(
      new GradesFilter(
        gradeContextDto.isLetterMode,
        gradeContextDto.isPanelOpened,
        isSubMode,
        gradeContextDto.isStudentMode,
        value,
      ),
    );
  };

  return (
    <MainContainer>
      <ChartTitle>{t("resultsByCriteria")}</ChartTitle>
      {letterGrades && percentageGrades && average ? (
        isLetterMode ? (
          <CriteriaResultChartByGrade
            results={letterGrades}
            average={average.grade}
            setSelectedCriteria={onSelectCriteria}
            {...rest}
          />
        ) : (
          <CriteriaResultChartByPercentage
            results={percentageGrades}
            average={average.value}
            setSelectedCriteria={onSelectCriteria}
            {...rest}
          />
        )
      ) : (
        <ChartSkeletonContainer>
          <Skeleton height="100%" />
        </ChartSkeletonContainer>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 16px;
  min-height: 500px;
  padding: 16px;
  border-radius: ${theme.radius.large};
  border: ${theme.borders.primary};
  background-color: ${theme.colors.base.white};

  box-shadow:
    0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px 0 rgba(16, 24, 40, 0.06);
`;

const ChartTitle = styled.h2`
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.semibold}
`;

const ChartSkeletonContainer = styled.div`
  flex: 1;
`;
