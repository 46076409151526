import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Checkbox } from "@/application/ui/lib/checkbox/Checkbox.tsx";
import { Criterias } from "@/application/ui/pages/Dashboard/hooks/useCriteriaStatus.ts";
import { theme } from "@/application/ui/theme/theme.ts";

interface Props {
  getCriteria: (c: Criterias) => boolean;
  onChange: (c: Criterias) => void;
}

export const WritingTaskCreationPanelCriteriaSelection = ({
  getCriteria,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <span>
        <Criteria>{t("CriteriaChoice")}</Criteria>
      </span>
      <CriteriaContainer>
        {(Object.keys(Criterias) as Array<keyof typeof Criterias>)
          .filter((el) => {
            return isNaN(Number(el));
          })
          .map((key) => {
            const c: Criterias = Criterias[key as keyof typeof Criterias];
            const value = getCriteria(c);
            return value ? (
              <CheckBoxContainerSelected
                role={`${key}-checkbox-label-check`}
                key={key}
              >
                <Checkbox
                  onChange={() => {
                    onChange(c);
                  }}
                  checked={value}
                  selectedColor={theme.colors.primary[500]}
                  backgroundSelectedColor={theme.colors.primary[50]}
                  name={`${key}`}
                />
                <span>
                  <Criteria>{t(`EvaluationCriterias.title.${key}`)} </Criteria>
                  <CriteriaDescription>
                    {t(`EvaluationCriterias.description.${key}`)}
                  </CriteriaDescription>
                </span>
              </CheckBoxContainerSelected>
            ) : (
              <CheckBoxContainer
                role={`${key}-checkbox-label-uncheck`}
                key={key}
              >
                <Checkbox
                  onChange={() => {
                    onChange(c);
                  }}
                  checked={false}
                  name={`${key}`}
                />
                <span>
                  <Criteria>{t(`EvaluationCriterias.title.${key}`)} </Criteria>
                  <CriteriaDescription>
                    {t(`EvaluationCriterias.description.${key}`)}
                  </CriteriaDescription>
                </span>
              </CheckBoxContainer>
            );
          })}
      </CriteriaContainer>
    </>
  );
};

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckBoxContainer = styled.label`
  cursor: pointer;
  display: flex;
  border: ${theme.borders.primary};
  border-radius: ${theme.radius.large};
  padding: 14px;
  gap: 16px;
`;

const CheckBoxContainerSelected = styled.label`
  cursor: pointer;
  display: flex;
  border: 2px solid ${theme.colors.primary[500]};
  background: ${theme.colors.primary[25]};
  border-radius: ${theme.radius.large};
  padding: 14px;
  gap: 16px;
`;

const Criteria = styled.span`
  ${theme.typography.sm.medium}
`;

const CriteriaDescription = styled.span`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]};
`;
