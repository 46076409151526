import { Type } from 'class-transformer';
import {
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsString,
  Matches,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

import { LetterWithVariantGrade } from '../../../../common/grade/model/Grade';

export enum TeacherRevisionMainCategoryDto {
  SPELLING = 'spelling',
  SYNTAX = 'syntax',
  PUNCTUATION = 'punctuation',
  GRAMMAR = 'grammar',
  VOCABULARY = 'vocabulary',
}

export enum TeacherEvaluationCriterionDto {
  ORTHOGRAPHY = 'orthography',
  SYNTAX = 'syntax',
  COHERENCE = 'coherence',
  ADAPTATION = 'adaptation',
  VOCABULARY = 'vocabulary',
}

export class TeacherRevisionDto {
  id: string;
  original: string;
  revised: string;
  @IsEnum(TeacherRevisionMainCategoryDto)
  mainCategory: TeacherRevisionMainCategoryDto;
  subCategory: string | null;
  penalized?: boolean;
}
export class TeacherCommentDto {
  id: string;
  startIndex: number;
  endIndex: number;
  text: string;
}

export class TeacherGradedCriterionDto {
  grade: LetterWithVariantGrade;
  @IsEnum(TeacherEvaluationCriterionDto)
  criterion: TeacherEvaluationCriterionDto;
}

export class FrontendGradeAssignmentDto {
  assignmentId: string;
  revisedText: string;

  @ValidateIf((_object, value) => value !== null)
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => TeacherCommentDto)
  comments?: TeacherCommentDto[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => TeacherRevisionDto)
  revisions: TeacherRevisionDto[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => TeacherGradedCriterionDto)
  gradedCriteria: TeacherGradedCriterionDto[];

  // On aurait voulu utiliser la regex Grade.REGEX, mais ça fachait Swagger
  // car on importait la donnée d'un fichier qui n'est pas .dto.ts
  @Matches(/^([A-D][+-]?|E)$/, {
    message: 'grade must be a valid grade like A+, B, C-',
  })
  @IsNotEmpty()
  @IsString()
  globalGrade: LetterWithVariantGrade;
  @IsNotEmpty()
  @IsNumber()
  globalGradePercent: number;
  comment?: string;
  recordUrl: string | undefined;
}
