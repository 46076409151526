export class NumberRange {
  private readonly min: number | null;
  private readonly max: number | null;

  constructor(min: number | null, max: number | null) {
    this.min = min;
    this.max = max;
  }

  contains(value: number): boolean {
    if (this.min !== null && value < this.min) {
      return false;
    }
    return !(this.max !== null && value > this.max);
  }
}
