export const AudioPlayerService = {
  listenToDurationChange: (
    src: string | undefined,
    onDurationChange: (durationInSeconds: number) => void,
  ) => {
    if (src === null) {
      return;
    }

    const audioPlayer = new Audio(src);

    audioPlayer.addEventListener("durationchange", () => {
      if (audioPlayer.duration !== Infinity) {
        onDurationChange(audioPlayer.duration);
      }
    });
    audioPlayer.load();
    // This line is required in Chrome to make sur the duration is not Infinity.
    // Source: https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity
    audioPlayer.currentTime = 24 * 60 * 60;
  },
};
