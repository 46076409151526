import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { REVISION_PAGE_URL } from "@/application/ui/pages/Dashboard/TeacherDashboard.tsx";
import { WRITING_TASK_GRADE_REPORT_BASE_URL } from "@/application/ui/routing/constants.ts";
import { ReactComponent as BarChart } from "@/assets/bar-chart-12.svg";

interface Props {
  writingTask: TeacherWritingTask;
  homeroomId: string;
}

export const WritingTaskCardActions = ({ writingTask, homeroomId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ActionRow>
      <Button
        hierarchy="neutral"
        size="sm"
        disabled={
          ![
            "ready_to_revise",
            "revision_in_progress",
            "ready_to_publish",
            "done",
          ].includes(writingTask.status)
        }
        onClick={() => navigate(`${REVISION_PAGE_URL}/${writingTask.id}/`)}
      >
        {t("teacherWritingTaskCardAction.see_copies")}
      </Button>

      {["revision_in_progress", "ready_to_publish", "done"].includes(
        writingTask.status,
      ) && (
        <Button
          hierarchy="secondary"
          size="sm"
          onClick={() =>
            navigate(
              `${WRITING_TASK_GRADE_REPORT_BASE_URL}/${homeroomId}/${writingTask.id}`,
            )
          }
        >
          {t("accessDashboard")} <BarChart />
        </Button>
      )}
    </ActionRow>
  );
};

const ActionRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-self: flex-end;
`;
