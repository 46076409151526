import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleSlider } from "react-toggle-slider";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { IconButton } from "@/application/ui/lib/button/IconButton";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle";
import type { RevisionPopupCollapseExpandMode } from "@/application/ui/pages/Revision/components/RevisionPopup/ExpandCollapseButton.tsx";
import { ExpandCollapseButton } from "@/application/ui/pages/Revision/components/RevisionPopup/ExpandCollapseButton.tsx";
import type { Revision } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as ArrowIcon } from "@/assets/arrow-icon.svg";
import { ReactComponent as SaveIcon } from "@/assets/check.svg";
import { ReactComponent as CancelIcon } from "@/assets/x-close.svg";

import { Categories } from "./RevisionCategory";

type RevisionPopupViewModeProps = {
  readOnly: boolean;
  revision: Revision;
  onDeleteRevision: () => void;
  onSave: (revisedText: string, category: Category, penalized: boolean) => void;
  onClose: () => void;
};

export const RevisionPopupViewMode = ({
  readOnly,
  revision,
  onDeleteRevision,
  onSave,
  onClose,
}: RevisionPopupViewModeProps) => {
  const { t } = useTranslation();

  const [viewMode, setViewMode] =
    useState<RevisionPopupCollapseExpandMode>("collapsed");
  const revisedRef = useRef<HTMLParagraphElement>(null);
  const [showExpandCollapseButton, setShowExpandCollapseButton] =
    useState(false);

  const [revisedText, setRevisedText] = useState(revision.revisedText || "");
  const [category, setCategory] = useState(revision.category);
  const [penalized, setPenalized] = useState(revision.penalized);

  useEffect(() => {
    const revisedElement = revisedRef.current;
    if (
      revisedElement &&
      revisedElement.scrollWidth > revisedElement.clientWidth
    ) {
      setShowExpandCollapseButton(true);
    } else {
      setShowExpandCollapseButton(false);
    }
  }, []);

  const switchViewMode = () => {
    setViewMode((prevState) =>
      prevState === "collapsed" ? "expanded" : "collapsed",
    );
  };
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (revision.category === Category.UNSPECIFIED && inputRef.current) {
      inputRef.current.focus();
    }
  }, [revision.category]);

  const isValid =
    revisedText !== revision.toString() && category !== Category.UNSPECIFIED;
  const hasChanged =
    revisedText !== revision.revisedText ||
    category !== revision.category ||
    penalized !== revision.penalized;

  return (
    <>
      <Section>
        <Header>
          {revision.category === Category.UNSPECIFIED
            ? t("addRevision")
            : t("revisionDetails")}

          <CloseButton
            variant="text"
            hierarchy="neutral"
            onClick={onClose}
            title={t("closePopup")}
            style={{ padding: 2 }}
          >
            <CancelIcon />
          </CloseButton>
        </Header>
      </Section>

      <RevisionContainer>
        <RevisionEditionContainer>
          <OriginalRedLinethrough>
            <Original className={viewMode}>{revision.toString()}</Original>
          </OriginalRedLinethrough>
          <Arrow />
          {!readOnly ? (
            <Input
              aria-label="revised-text-input"
              value={revisedText}
              onChange={(event) => setRevisedText(event.target.value)}
              ref={inputRef}
            />
          ) : (
            <Revised ref={revisedRef} className={viewMode}>
              {revisedText}
            </Revised>
          )}
          {showExpandCollapseButton && (
            <ExpandCollapseButton
              viewMode={viewMode}
              switchViewMode={switchViewMode}
            />
          )}
        </RevisionEditionContainer>
      </RevisionContainer>

      {
        /* Masquer temporairement le code d’erreur affiché dans le pop up car l'ia n'est pas toujours juste voir csa-1146 */
        false && revision.subCategory && (
          <Section>
            <RevisionCategory>{`${revision.subCategory}: ${t(`subcriteriaClassment.${revision.subCategory}`)}`}</RevisionCategory>
          </Section>
        )
      }

      <Section>
        <Categories
          current={category}
          onChangeCategory={setCategory}
          readOnly={readOnly}
        />
      </Section>

      {!readOnly && (
        <>
          <Section>
            <PenalizedSection>
              <div
                data-testid="togglePenalizedButton"
                onClick={() => setPenalized(!penalized)}
              >
                <ToggleSlider
                  active={!penalized}
                  barBackgroundColor={theme.colors.gray[100]}
                  barBackgroundColorActive={theme.colors.primary[700]}
                  barHeight={20}
                  barWidth={36}
                  handleSize={16}
                />
              </div>
              {t("unpenalizedThisError")}
            </PenalizedSection>
          </Section>

          <ButtonContainer>
            <Button hierarchy="error" variant="text" onClick={onDeleteRevision}>
              {t("delete")}
            </Button>

            <Button
              id="saveRevision"
              hierarchy="secondary"
              data-testid="saveRevision"
              onClick={() => {
                onSave(revisedText, category, penalized);
              }}
              disabled={!isValid || !hasChanged}
            >
              {t("confirm")} <SaveIcon />
            </Button>
            {!isValid && (
              <Tooltip
                anchorSelect="#saveRevision"
                content={t("confirmRevisionTooltip")}
                place="bottom"
                style={{ ...tooltipStyle }}
              />
            )}
          </ButtonContainer>
        </>
      )}
    </>
  );
};

const Input = styled.input`
  ${theme.typography.sm.regular}
  flex: 1;
  height: 2.5rem;
  border: ${theme.borders.primary};
  border-radius: ${theme.radius.medium};
  padding: 0 12px;

  &:focus {
    outline-color: ${theme.colors.primary[300]};

    &::placeholder {
      color: transparent;
    }
  }
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.typography.xs.bold};
`;

const RevisionCategory = styled.p`
  color: ${theme.colors.gray[500]};
  ${theme.typography.xs.regular}
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.gray[500]};
  padding: 8px 16px;
`;

const RevisionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
`;

const RevisionEditionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
`;

const Revised = styled.p`
  color: ${theme.colors.base.white};
  background-color: ${theme.colors.success[500]};
  border-radius: ${theme.radius.small};
  padding: 5px 10px;

  &.collapsed {
    max-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 10.3rem;
  }

  &.expanded {
    max-width: 18.28rem;
    overflow-wrap: break-word;
  }
`;

const OriginalRedLinethrough = styled.span`
  color: ${theme.colors.error[600]};
  text-decoration: line-through;
  text-decoration-thickness: 2px;
`;

const Original = styled.p`
  color: ${theme.colors.gray[900]};

  &.collapsed {
    max-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10.28rem;
  }

  &.expanded {
    max-width: 18.9rem;
    overflow-wrap: break-word;
  }
`;

const Arrow = styled(ArrowIcon)`
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
`;

const CloseButton = styled(IconButton)`
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const PenalizedSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${theme.typography.sm.medium};
`;
