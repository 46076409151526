import styled from "styled-components";

import { theme } from "../theme/theme";

export const Title = styled.h1`
  color: ${theme.colors.gray[600]};
  ${theme.typography.displayXs.bold}
  margin-bottom: 32px;
  margin-left: 32px;
`;
