import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { WritingTaskGradeReportClient } from "../../../../client/WritingTaskGradeReportClient.ts";

export const useWritingTasksGradeReport = (homeroomId?: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.dashboardWritingTasks,
    queryFn: () => WritingTaskGradeReportClient.getWritingTasks(homeroomId),
    enabled: !!homeroomId,
  });

  return { writingTaskDashboard: data };
};
