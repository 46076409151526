import type { FrontendGradeAssignmentDto } from "@api/FrontendGradeAssignmentDto.ts";
import {
  TeacherEvaluationCriterionDto,
  TeacherRevisionMainCategoryDto,
} from "@api/FrontendGradeAssignmentDto.ts";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { keys } from "lodash";

import type { GradedAssignment } from "@/application/domain/GradedAssignment.ts";

import type { LetterWithVariantGrade } from "../../ui/pages/Dashboard/hooks/useSummarySort";

export class GradedAssignmentMapper {
  static fromGradedAssignment(
    gradedAssignment: GradedAssignment,
  ): FrontendGradeAssignmentDto {
    return {
      assignmentId: gradedAssignment.id,
      revisedText: gradedAssignment.revisedText,
      revisions: keys(gradedAssignment.revisions).map((id) => ({
        id,
        ...gradedAssignment.revisions[id],

        mainCategory: this.convertCategoryToTeacherRevisionCategoryDto(
          gradedAssignment.revisions[id].category.mainCategory,
        ),
        subCategory: gradedAssignment.revisions[id].category.subCategory,
      })),
      comments: gradedAssignment.comments,
      gradedCriteria: keys(gradedAssignment.gradedCriteria).map(
        (criterion) => ({
          grade: gradedAssignment.gradedCriteria[
            criterion as EvaluationCriterion
          ]?.toString() as LetterWithVariantGrade,
          criterion:
            this.convertEvaluationCriterionToTeacherEvaluationCriterionDto(
              criterion as EvaluationCriterion,
            ),
        }),
      ),
      globalGrade: gradedAssignment.globalGrade.grade.toString(),
      globalGradePercent: gradedAssignment.globalGrade.value,
      comment: gradedAssignment.comment,
      recordUrl: gradedAssignment.recordUrl,
    };
  }

  private static convertCategoryToTeacherRevisionCategoryDto(
    value: Category,
  ): TeacherRevisionMainCategoryDto {
    switch (value) {
      case Category.SPELLING:
        return TeacherRevisionMainCategoryDto.SPELLING;
      case Category.SYNTAX:
        return TeacherRevisionMainCategoryDto.SYNTAX;
      case Category.PUNCTUATION:
        return TeacherRevisionMainCategoryDto.PUNCTUATION;
      case Category.GRAMMAR:
        return TeacherRevisionMainCategoryDto.GRAMMAR;
      case Category.VOCABULARY:
        return TeacherRevisionMainCategoryDto.VOCABULARY;
      default:
        return TeacherRevisionMainCategoryDto.GRAMMAR;
    }
  }

  private static convertEvaluationCriterionToTeacherEvaluationCriterionDto(
    value: EvaluationCriterion,
  ): TeacherEvaluationCriterionDto {
    switch (value) {
      case EvaluationCriterion.ADAPTATION:
        return TeacherEvaluationCriterionDto.ADAPTATION;
      case EvaluationCriterion.COHERENCE:
        return TeacherEvaluationCriterionDto.COHERENCE;
      case EvaluationCriterion.VOCABULARY:
        return TeacherEvaluationCriterionDto.VOCABULARY;
      case EvaluationCriterion.SYNTAX:
        return TeacherEvaluationCriterionDto.SYNTAX;
      case EvaluationCriterion.ORTHOGRAPHY:
        return TeacherEvaluationCriterionDto.ORTHOGRAPHY;
    }
  }
}
