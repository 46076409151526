import { Grade } from "@emilia/backend/src/common/grade/model/Grade.ts";
import type { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria.ts";
import { ValuedGrade } from "@emilia/backend/src/common/grade/model/ValuedGrade.ts";
import type { GradedStudentsResponseDto } from "@emilia/backend/src/grade-report/controller/dto/gradedStudentsResponseDto.ts";
import type { GradeReportResponseDto } from "@emilia/backend/src/grade-report/controller/dto/gradeReportResponseDto.ts";

import type { GradedStudents } from "../domain/gradedStudents.ts";
import type { GradeReport } from "../domain/gradeReport.ts";
import { PersonName } from "../domain/PersonName.ts";
import { AuthenticatedHttpClient } from "./HttpClient.ts";

export class GradeReportClient {
  static getForWritingTask = async (
    taskId: string,
    studentId?: string,
  ): Promise<GradeReport> => {
    const { data } = await AuthenticatedHttpClient.get<GradeReportResponseDto>(
      `/grade-report/${taskId}${studentId ? `/${studentId}` : ""}`,
    );
    return GradeReportAssembler.fromDto(data);
  };

  static getGradedStudentsForWritingTask = async (
    taskId: string,
  ): Promise<GradedStudents> => {
    const { data } =
      await AuthenticatedHttpClient.get<GradedStudentsResponseDto>(
        `/grade-report/gradedStudents/${taskId}`,
      );
    return {
      students: data.students.map((x) => {
        return {
          id: x.id,
          name: new PersonName(x.name.firstName, x.name.lastName),
          assignmentId: x.assignmentId,
        };
      }),
    };
  };
}

const GradeReportAssembler = {
  fromDto: (dto: GradeReportResponseDto): GradeReport => ({
    grades: dto.grades.map((x) => ({
      criterion: x.criterion as EvaluationCriterion,
      grade: new ValuedGrade(Grade.of(x.grade.letter), x.grade.percentage),
    })),
    average: new ValuedGrade(
      Grade.of(dto.average.letter),
      dto.average.percentage,
    ),
  }),
};
