import { useMutation, useQueryClient } from "@tanstack/react-query";

import { WritingTaskClient } from "@/application/client/WritingTaskClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useWritingTaskUndoArchive = () => {
  const queryClient = useQueryClient();

  const { mutate: undoArchiveWritingTask, isLoading } = useMutation({
    mutationFn: (writingTaskId: string) =>
      WritingTaskClient.archiveUndoTask(writingTaskId),
    onSuccess: () => queryClient.invalidateQueries(TeacherQueryKeys.dashboard),
  });

  return { undoArchiveWritingTask, archiving: isLoading };
};
