import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { SyntaxGradeComputingStrategy } from '../SyntaxGradeComputingStrategy';
import { SecondaryFourSyntaxValuedGradeMapper } from './SecondaryFourSyntaxValuedGradeMapper';

export class SecondaryFourSyntaxGradeComputingStrategy
  extends SyntaxGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryFourSyntaxValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryFourSyntaxValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(syntaxErrorWordRatio: number): Grade {
    switch (true) {
      case syntaxErrorWordRatio <= 0.2:
        return Grade.of('A+');
      case syntaxErrorWordRatio <= 0.76:
        return Grade.of('A');
      case syntaxErrorWordRatio <= 1.32:
        return Grade.of('A-');
      case syntaxErrorWordRatio <= 1.69:
        return Grade.of('B+');
      case syntaxErrorWordRatio <= 2.06:
        return Grade.of('B');
      case syntaxErrorWordRatio <= 2.43:
        return Grade.of('B-');
      case syntaxErrorWordRatio <= 2.77:
        return Grade.of('C+');
      case syntaxErrorWordRatio <= 3.1:
        return Grade.of('C');
      case syntaxErrorWordRatio <= 3.44:
        return Grade.of('C-');
      case syntaxErrorWordRatio <= 3.79:
        return Grade.of('D+');
      case syntaxErrorWordRatio <= 4.13:
        return Grade.of('D');
      case syntaxErrorWordRatio <= 4.48:
        return Grade.of('D-');
      case syntaxErrorWordRatio <= 4.82:
        return Grade.of('E+');
      case syntaxErrorWordRatio <= 5.16:
        return Grade.of('E');
      case syntaxErrorWordRatio <= 5.5:
        return Grade.of('E-');
      default:
        return Grade.of('E--');
    }
  }
}
