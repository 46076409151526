import type { Grade, GradeLetter } from './Grade';

export enum EvaluationCriterion {
  ADAPTATION = 'adaptation',
  COHERENCE = 'coherence',
  VOCABULARY = 'vocabulary',
  SYNTAX = 'syntax',
  ORTHOGRAPHY = 'orthography',
}

export type GradedCriterion = {
  grade: GradeLetter;
  criterion: EvaluationCriterion;
};

export type GradedCriteria = {
  [EvaluationCriterion.ADAPTATION]?: Grade;
  [EvaluationCriterion.VOCABULARY]?: Grade;
  [EvaluationCriterion.COHERENCE]?: Grade;
  [EvaluationCriterion.SYNTAX]?: Grade;
  [EvaluationCriterion.ORTHOGRAPHY]?: Grade;
};
