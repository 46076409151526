import type { ScaleOptions, ScriptableContext } from "chart.js";
import { Chart as ChartJS, registerables } from "chart.js";
import type { AnnotationPluginOptions } from "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

import { theme } from "../../theme/theme";

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

const GROUP_SIZE = 10;

interface Props {
  results: number[];
  average: number;
  standardDeviation: number;
  threshold: number;
}

export const ResultsRepartitionChart = ({
  results,
  average,
  standardDeviation,
  threshold,
}: Props) => {
  const freqMap = new Map<number, number>();

  results.forEach((result) => {
    let count = freqMap.get(Math.round(result / GROUP_SIZE)) || 0;
    freqMap.set(Math.round(result / GROUP_SIZE), ++count);
  });

  let color: string;
  if (average > threshold + 10) {
    color = theme.colors.success[300];
  } else if (average > threshold) {
    color = theme.colors.yellow[300];
  } else {
    color = theme.colors.error[400];
  }

  const dataList = Array(100 / GROUP_SIZE + 1) as { x: number; y: number }[];
  for (let i = 0; i < dataList.length; i++) {
    dataList[i] = { x: i * GROUP_SIZE, y: freqMap.get(i) || 0 };
  }

  let width: number, height: number, gradient: CanvasGradient;

  const data = {
    datasets: [
      {
        data: dataList,
        tension: 0.4,
        borderWidth: 6,
        pointRadius: 0,
        fill: true,
        backgroundColor(context: ScriptableContext<"line">) {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 50, 0, 400);
          gradient.addColorStop(0, theme.colors.base.white);
          gradient.addColorStop(1, color);
          return gradient;
        },
        borderColor(context: ScriptableContext<"line">) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }

          const chartWidth = chartArea.right - chartArea.left;
          const chartHeight = chartArea.bottom - chartArea.top;
          if (!gradient || width !== chartWidth || height !== chartHeight) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top,
            );
            gradient.addColorStop(0, theme.colors.gray[300]);
            gradient.addColorStop(1, color);
          }

          return gradient;
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          lowerThresholdLine: {
            type: "line",
            xMin: Math.round(average - standardDeviation),
            xMax: Math.round(average - standardDeviation),
            borderColor: theme.colors.gray[400],
            borderWidth: 1,
            borderDash: [3, 3],
            label: {
              display: false,
              borderRadius: 100,
              padding: {
                x: 12,
                y: 8,
              },
              position: "end",
              yAdjust: -8,
              backgroundColor: theme.colors.gray[400],
              drawTime: "afterDatasetsDraw",
              content: `${Math.round(average - standardDeviation)}%`,
            },
            enter({ element }, _event) {
              element.label!.options.display = true;
              return true;
            },
            leave({ element }, _event) {
              element.label!.options.display = false;
              return true;
            },
          },
          higherThresholdLine: {
            type: "line",
            xMin: Math.round(average + standardDeviation),
            xMax: Math.round(average + standardDeviation),
            borderColor: theme.colors.gray[400],
            borderWidth: 1,
            borderDash: [3, 3],
            label: {
              display: false,
              borderRadius: 100,
              padding: {
                x: 12,
                y: 8,
              },
              position: "end",
              yAdjust: -8,
              backgroundColor: theme.colors.gray[400],
              drawTime: "afterDatasetsDraw",
              content: `${Math.round(average + standardDeviation)}%`,
            },
            enter({ element }, _event) {
              element.label!.options.display = true;
              return true;
            },
            leave({ element }, _event) {
              element.label!.options.display = false;
              return true;
            },
          },
        },
      } as AnnotationPluginOptions,
    },
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
          stepSize: 1,
        },
        min: 0,
        max: Math.max(...dataList.map((data) => data.y)) + 2,
      },
      x: {
        type: "linear",
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
          stepSize: 1,
        },
        min: 0,
        max: 100,
      } as ScaleOptions<"linear">,
    },
  };

  return (
    <ChartContainer>
      <Line data={data} options={options} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  height: 140px;
`;
