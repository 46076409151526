import type { Grade } from '../Grade';
import { ValuedGrade } from '../ValuedGrade';
import type { ValuedGradeMapper } from './ValuedGradeMapper';

export class DefaultValuedGradeMapper implements ValuedGradeMapper {
  create(grade: Grade): ValuedGrade {
    switch (grade.letter) {
      case 'A':
        return new ValuedGrade(grade, 100);
      case 'B':
        return new ValuedGrade(grade, 80);
      case 'C':
        return new ValuedGrade(grade, 60);
      case 'D':
        return new ValuedGrade(grade, 40);
      case 'E':
        return new ValuedGrade(grade, 20);
    }
  }
}
