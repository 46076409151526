import { Page } from "@/application/ui/lib/Page.tsx";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage.tsx";

export const DashboardPage = () => {
  return (
    <Page>
      <TimeoutPage />
    </Page>
  );
};
