import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";

import { useAuthConfig } from "../ui/pages/App/AuthConfigProvider";
import { msalInstance } from "../ui/pages/App/MsalProvider";

export type AuthUser = {
  username: string;
  id: string;
};
export const useAuth = () => {
  const { entraAuthScope } = useAuthConfig();
  const { instance, inProgress, accounts } = useMsal();

  const authUser: AuthUser | null = useMemo(() => {
    const activeAccount = accounts?.[0];

    return activeAccount
      ? {
          username: activeAccount.username,
          id: activeAccount.localAccountId,
        }
      : null;
  }, [accounts]);

  return {
    loading: inProgress === InteractionStatus.Startup,
    authUser,
    loginMsal: (redirectUri: string) =>
      instance.loginRedirect({
        redirectUri,
        scopes: [entraAuthScope],
      }),
    logoutMsal: (redirectUri: string) =>
      instance.logout({
        account: msalInstance.getAllAccounts()[0],
        postLogoutRedirectUri: redirectUri,
      }),
  };
};
