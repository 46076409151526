import { useState } from "react";

export type View = "original" | "revised";

type UseToggleView = {
  currentView: View;
  toggleCurrentView: () => void;
};

export const useToggleView = (): UseToggleView => {
  const [currentView, setCurrentView] = useState<View>("original");

  const toggleCurrentView = () => {
    setCurrentView(currentView === "original" ? "revised" : "original");
  };

  return {
    currentView,
    toggleCurrentView,
  };
};
