import { useState } from "react";

export type HomeroomState = {
  id: string;
  name: string;
  selected: boolean;
};

export const useSelectedGroup = (homerooms: HomeroomState[]) => {
  const [homeroomStates, setHomeroomState] =
    useState<HomeroomState[]>(homerooms);

  const toggleSelectedState = (id: string) => {
    const homeroomState = homeroomStates.find((x) => x.id === id);
    if (homeroomState) {
      setHomeroomState((prev) => {
        return prev.map((homeroom) => {
          if (homeroom.id !== id) {
            return homeroom;
          }
          return { ...homeroom, selected: !homeroom.selected };
        });
      });
    }
  };

  return { homeroomStates, toggleSelectedState };
};
