import { useParams } from "react-router-dom";

import { ReadOnlyUserProvider } from "@/application/ui/context/ReadOnlyUserContext.tsx";
import { useGetAssignmentById } from "@/application/ui/pages/Revision/components/useAssignment.ts";
import { StudentAssignmentPageContent } from "@/application/ui/pages/StudentAssignment/StudentAssignmentPageContent.tsx";

export const StudentAssignmentPage = () => {
  const { assignmentId } = useParams();

  const { assignment } = useGetAssignmentById(assignmentId!);

  return (
    <ReadOnlyUserProvider readOnlyUser={true}>
      {assignment && <StudentAssignmentPageContent assignment={assignment} />}
    </ReadOnlyUserProvider>
  );
};
