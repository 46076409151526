import type { AxiosError } from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import type { AssignmentEvent } from "@/application/domain/AssignmentEvent.ts";
import { EventSessionStorageAccessor } from "@/application/services/EventSessionStorageAccessor.ts";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { useWritingTask } from "@/application/ui/pages/Dashboard/hooks/useWritingTask.ts";
import { CloseAssignmentModal } from "@/application/ui/pages/WriteAssignment/components/CloseAssignmentModal.tsx";
import { useGetStudentWritingTaskContent } from "@/application/ui/pages/WriteAssignment/hooks/useGetStudentWritingTaskContent.ts";
import { SecureAssignmentForm } from "@/application/ui/pages/WriteAssignment/SecureAssignmentForm.tsx";
import { useSendAssignment } from "@/application/ui/pages/WriteAssignment/useSendAssignment.ts";
import { WriteAssignmentForm } from "@/application/ui/pages/WriteAssignment/WriteAssignmentForm.tsx";

import { useLoggedUser } from "../../../authentication/hooks/useLoggedUser";
import { useSaveWIPAssignment } from "./useSaveWIPAssignment";

export const WriteAssignmentPage = () => {
  const { t } = useTranslation();
  const { writingTaskId } = useParams();
  const navigate = useNavigate();
  const { writingTask, refetch } = useWritingTask(writingTaskId ?? "");
  const { data } = useGetStudentWritingTaskContent(writingTaskId);
  const { user } = useLoggedUser();
  const { saveWIPAssignment } = useSaveWIPAssignment();
  const sessionStorageKeyContent = `${writingTaskId}.${user.id}.content`;
  const sessionStorageKeyEvent = `${writingTaskId}.${user.id}.event`;
  const sessionContent = sessionStorage.getItem(sessionStorageKeyContent);

  const setStorageItem = (textContent: string) => {
    sessionStorage.setItem(sessionStorageKeyContent, textContent);
  };

  const { getEventList } = EventSessionStorageAccessor;

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        saveWIPAssignment({
          content: sessionStorage.getItem(sessionStorageKeyContent)!,
          writingTaskId: writingTaskId!,
          events: getEventList(sessionStorageKeyEvent),
        });

        void refetch();
      },
      5 * 60 * 1000,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [
    getEventList,
    refetch,
    saveWIPAssignment,
    sessionStorageKeyContent,
    sessionStorageKeyEvent,
    writingTask?.isOpen,
    writingTaskId,
  ]);

  const returnToDashboard = () => {
    sessionStorage.removeItem(sessionStorageKeyContent);
    sessionStorage.removeItem(sessionStorageKeyEvent);
    void document.exitFullscreen();
    navigate("/etudiant");
  };

  const { sendAssignment, isSendingAssignment } = useSendAssignment({
    onSuccess: () => {
      ToastService.showSuccess(t("sendAssignmentSuccess"));
      returnToDashboard();
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        ToastService.showSuccess(t("sendAssignmentAlreadySubmitted"));
        returnToDashboard();
      } else {
        ToastService.showError(t("sendAssignmentFail"));
      }
    },
  });

  const forceSendAssignment = () => {
    try {
      saveWIPAssignment({
        content: sessionStorage.getItem(sessionStorageKeyContent)!,
        writingTaskId: writingTaskId!,
        events: getEventList(sessionStorageKeyEvent),
      });
    } catch {
      console.error("Failed to send assignment");
    }
    navigate("/etudiant");
  };

  const handleConfirm = (content: string, events: AssignmentEvent[] = []) => {
    writingTaskId &&
      sendAssignment({
        content,
        writingTaskId,
        events,
      });
  };

  if (writingTask && writingTask.taskType !== "summative") {
    return (
      <>
        <WriteAssignmentForm
          onSubmit={handleConfirm}
          submitting={isSendingAssignment}
          content={sessionContent ? sessionContent : data?.content}
          writingTask={writingTask}
          setStorageItem={setStorageItem}
        />
        {!writingTask.isOpen && (
          <CloseAssignmentModal onClose={() => forceSendAssignment()} />
        )}
      </>
    );
  } else if (data && writingTask && writingTask.taskType === "summative") {
    return (
      <>
        <SecureAssignmentForm
          onSubmit={handleConfirm}
          submitting={isSendingAssignment}
          content={sessionContent ? sessionContent : (data.content ?? "")}
          persistedEvents={data.events}
          writingTask={writingTask}
          setStorageItem={setStorageItem}
          sessionStorageKeyEvent={sessionStorageKeyEvent}
          sessionStorageKeyContent={sessionStorageKeyContent}
        />
        {!writingTask.isOpen && (
          <CloseAssignmentModal onClose={() => forceSendAssignment()} />
        )}
      </>
    );
  }
  return <LoadingPage />;
};
