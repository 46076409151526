import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { SyntaxGradeComputingStrategy } from '../SyntaxGradeComputingStrategy';
import { SecondaryFiveSyntaxValuedGradeMapper } from './SecondaryFiveSyntaxValuedGradeMapper';

export class SecondaryFiveSyntaxGradeComputingStrategy
  extends SyntaxGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryFiveSyntaxValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryFiveSyntaxValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    let wordCount = textWithMistakes.countWords();
    if (wordCount > 500) {
      wordCount = 500;
    }
    const ratio = this.computeErrorRatio(textWithMistakes, wordCount);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(syntaxErrorWordRatio: number): Grade {
    switch (true) {
      case syntaxErrorWordRatio === 0:
        return Grade.of('A+');
      case syntaxErrorWordRatio <= 0.4:
        return Grade.of('A');
      case syntaxErrorWordRatio <= 0.6:
        return Grade.of('A-');
      case syntaxErrorWordRatio <= 0.8:
        return Grade.of('A--');
      case syntaxErrorWordRatio <= 1.2:
        return Grade.of('B+');
      case syntaxErrorWordRatio <= 1.6:
        return Grade.of('B');
      case syntaxErrorWordRatio <= 1.8:
        return Grade.of('B-');
      case syntaxErrorWordRatio <= 2.2:
        return Grade.of('C+');
      case syntaxErrorWordRatio <= 2.4:
        return Grade.of('C');
      case syntaxErrorWordRatio <= 2.6:
        return Grade.of('C-');
      case syntaxErrorWordRatio <= 2.8:
        return Grade.of('C--');
      case syntaxErrorWordRatio <= 3:
        return Grade.of('D+');
      case syntaxErrorWordRatio <= 3.2:
        return Grade.of('D');
      case syntaxErrorWordRatio <= 3.4:
        return Grade.of('D-');
      case syntaxErrorWordRatio <= 3.8:
        return Grade.of('E+');
      case syntaxErrorWordRatio <= 4:
        return Grade.of('E');
      case syntaxErrorWordRatio <= 4.2:
        return Grade.of('E-');
      default:
        return Grade.of('E--');
    }
  }
}
