import type { PropsWithChildren } from "react";
import { createContext } from "react";

type ReadOnlyUserProviderProps = {
  readOnlyUser: boolean;
};

export const ReadOnlyUserContext = createContext({
  readOnlyUser: true,
});

export const ReadOnlyUserProvider = ({
  readOnlyUser,
  children,
}: PropsWithChildren<ReadOnlyUserProviderProps>) => {
  return (
    <ReadOnlyUserContext.Provider
      value={{
        readOnlyUser,
      }}
    >
      {children}
    </ReadOnlyUserContext.Provider>
  );
};
