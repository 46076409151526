import { useTranslation } from "react-i18next";

import type { OnAudioCompleted } from "@/application/services/AudioRecordingService.ts";
import { formatTimerProgress } from "@/application/services/DurationFormatter.ts";
import {
  Content,
  ControlButton,
  RecordingBox,
  RecordingLabel,
  RecordingWaveform,
} from "@/application/ui/lib/recording/components/AudioStyled.ts";
import { useAudioRecorder } from "@/application/ui/lib/recording/hooks/useAudioRecord.ts";
import { ReactComponent as PlayIcon } from "@/assets/play-circle.svg";
import { ReactComponent as StopIcon } from "@/assets/stop-circle.svg";

interface Props {
  onAudioCompleted: OnAudioCompleted;
  limitInSeconds: number;
}

export const AudioRecorder = ({ onAudioCompleted, limitInSeconds }: Props) => {
  const { t } = useTranslation();
  const { timer, isRecording, startRecording, stopRecording } =
    useAudioRecorder({
      limitInSeconds,
    });

  const record = () => startRecording(onAudioCompleted);

  return (
    <RecordingBox aria-label={t("recorder")}>
      {!isRecording && (
        <>
          <ControlButton onClick={record}>
            <PlayIcon title={t("startRecordingButton")} />
          </ControlButton>

          <button style={{ cursor: "pointer" }} onClick={record}>
            <RecordingLabel>{t("startRecording")}</RecordingLabel>
          </button>
        </>
      )}

      {isRecording && (
        <>
          <ControlButton onClick={stopRecording}>
            <StopIcon title={t("stopRecording")} />
          </ControlButton>

          <Content>
            <RecordingWaveform title={t("recording")} />

            <RecordingLabel>
              {formatTimerProgress(timer, limitInSeconds)}
            </RecordingLabel>
          </Content>
        </>
      )}
    </RecordingBox>
  );
};
