export const AllGradingScales = [
  'third_year',
  'fourth_year',
  'fifth_year',
  'sixth_year',
  'secondary_one',
  'secondary_two',
  'secondary_three',
  'secondary_four',
  'secondary_five',
  'secondary_five_ministry',
] as const;

export type GradingScaleType = (typeof AllGradingScales)[number];
