import { useEffect, useState } from "react";

import type {
  AssignmentEvent,
  AssignmentEventType,
} from "@/application/domain/AssignmentEvent.ts";
import type { WritingTask } from "@/application/domain/writingTask.ts";
import { EventSessionStorageAccessor } from "@/application/services/EventSessionStorageAccessor.ts";
import { SecurityModeModal } from "@/application/ui/pages/WriteAssignment/components/SecurityModeModal.tsx";
import { SecuredModeModal } from "@/application/ui/pages/WriteAssignment/SecuredModeModal.tsx";
import { useSaveWIPAssignment } from "@/application/ui/pages/WriteAssignment/useSaveWIPAssignment.ts";
import { WriteAssignmentForm } from "@/application/ui/pages/WriteAssignment/WriteAssignmentForm.tsx";

interface Props {
  content?: string;
  persistedEvents: AssignmentEvent[];
  onSubmit: (content: string, events?: AssignmentEvent[]) => void;
  setStorageItem: (content: string) => void;
  submitting: boolean;
  writingTask: WritingTask;
  sessionStorageKeyEvent: string;
  sessionStorageKeyContent: string;
}

export const SecureAssignmentForm = ({
  submitting,
  onSubmit,
  content,
  persistedEvents,
  writingTask,
  setStorageItem,
  sessionStorageKeyEvent,
  sessionStorageKeyContent,
}: Props) => {
  const [discloseSecuredMode, setDiscloseSecuredMode] = useState(
    writingTask.taskType === "summative",
  );
  const { getEventList, setEventList, addEvent } = EventSessionStorageAccessor;
  const { saveWIPAssignment } = useSaveWIPAssignment();
  const handleCalculateInitialState = (): boolean => {
    const events = getEventList(sessionStorageKeyEvent);
    if (events.length > 0) {
      return events.map((x) => x.type).includes("OpenSession");
    }

    if (persistedEvents.length > 0) {
      setEventList(sessionStorageKeyEvent, persistedEvents);
      const event = {
        type: "TabChange" as AssignmentEventType,
        timestamp: new Date(),
      };
      addEvent(sessionStorageKeyEvent, event);
      return true;
    }

    return false;
  };

  const [locked, setLocked] = useState(() => {
    return handleCalculateInitialState();
  });

  //prevent right click because the antidote menu is there
  useEffect(() => {
    const handleContextmenu = (e: { preventDefault: () => void }) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (!locked && document.hidden) {
        setLocked(true);
        saveWIPAssignment({
          content: sessionStorage.getItem(sessionStorageKeyContent)!,
          writingTaskId: writingTask.id,
          events: getEventList(sessionStorageKeyEvent),
        });
      }

      const event = {
        type: (document.hidden
          ? "SetNotVisible"
          : "SetVisible") as AssignmentEventType,
        timestamp: new Date(),
      };
      addEvent(sessionStorageKeyEvent, event);
    });

    document.addEventListener("fullscreenchange", () => {
      if (!locked && document.fullscreenElement === null) {
        setLocked(true);
        saveWIPAssignment({
          content: sessionStorage.getItem(sessionStorageKeyContent)!,
          writingTaskId: writingTask.id,
          events: getEventList(sessionStorageKeyEvent),
        });
      }

      const event = {
        type: (document.fullscreenElement
          ? "SetFullScreen"
          : "SetNotFullScreen") as AssignmentEventType,
        timestamp: new Date(),
      };

      addEvent(sessionStorageKeyEvent, event);
    });
    return () => {
      document.removeEventListener("visibilitychange", () => {});
      document.removeEventListener("fullscreenchange", () => {});
    };
  }, [
    addEvent,
    getEventList,
    locked,
    saveWIPAssignment,
    sessionStorageKeyContent,
    sessionStorageKeyEvent,
    writingTask.id,
    writingTask.taskType,
  ]);

  const handleCloseSecuredMode = (mode: boolean) => {
    const list = getEventList(sessionStorageKeyEvent);
    const event = {
      type: "OpenSession" as AssignmentEventType,
      timestamp: new Date(),
    };
    list.push(event);
    addEvent(sessionStorageKeyEvent, event);
    setDiscloseSecuredMode(mode);
  };

  const handleClosure = (content: string) => {
    const event = {
      type: "CloseSession" as AssignmentEventType,
      timestamp: new Date(),
    };
    const events = getEventList(sessionStorageKeyEvent);
    events.push(event);
    addEvent(sessionStorageKeyEvent, event);
    onSubmit(content, events);
  };

  const reloadType = (
    performance.getEntries()[0] as PerformanceNavigationTiming
  ).type;
  if (reloadType === "navigate" || reloadType === "reload") {
    const event = {
      type: "Reload" as AssignmentEventType,
      timestamp: new Date(),
    };
    addEvent(sessionStorageKeyEvent, event);
  }

  if (discloseSecuredMode) {
    return <SecuredModeModal setDiscloseSecuredMode={handleCloseSecuredMode} />;
  }

  return (
    <>
      <WriteAssignmentForm
        onSubmit={handleClosure}
        submitting={submitting}
        content={content}
        writingTask={writingTask}
        setStorageItem={setStorageItem}
      />
      {locked && (
        <SecurityModeModal
          writingTaskId={writingTask.id}
          onClose={() => setLocked(false)}
        />
      )}
    </>
  );
};
