import type { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useLoggedUser } from "@/application/authentication/hooks/useLoggedUser.ts";

import type { HomeroomRole } from "./domain/LoggedUser.ts";

interface Props {
  role: HomeroomRole;
}

export function RequireRole({ children, role }: PropsWithChildren<Props>) {
  const { user } = useLoggedUser();

  if (!user.hasRole(role)) {
    return <Navigate to="/" replace />;
  }

  return <>{children ? children : <Outlet />}</>;
}
