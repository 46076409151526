import { useGrades } from "../GradeStates/useGrades";
import { useGlobalRanking } from "../hooks/useGlobalRanking";
import { GlobalRankingDistribution } from "./GlobalRankingDistribution";

export type GlobalRankingDistributionProps = {
  selectStudent: (studentId: string) => void;
};

export const GlobalRankingChart = ({
  selectStudent,
}: GlobalRankingDistributionProps) => {
  const { gradeContextDto, taskId } = useGrades();
  const { isLetterMode: isletterMode, selectedCriteria } = gradeContextDto;
  const { globalRanking } = useGlobalRanking(
    taskId,
    selectedCriteria !== "all" ? selectedCriteria : undefined,
  );

  return (
    <GlobalRankingDistribution
      globalRanking={globalRanking}
      isLetterMode={isletterMode}
      selectedCriteria={selectedCriteria}
      selectStudent={selectStudent}
    />
  );
};
