import { useQuery } from "@tanstack/react-query";

import { StudentDashboardClient } from "../../../../client/StudentDashboardClient";

export const useStudentDashboard = () => {
  const { data: dashboard } = useQuery({
    queryKey: ["student", "dashboard"],
    queryFn: StudentDashboardClient.fetchDashboard,
  });

  return { dashboard };
};
