import type { AssignmentEvent } from "@/application/domain/AssignmentEvent.ts";

export abstract class EventSessionStorageAccessor {
  static setEventList = (
    sessionStorageKey: string,
    events: AssignmentEvent[],
  ) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(events));
  };

  static getEventList = (sessionStorageKey: string) => {
    const eventStorageContent = sessionStorage.getItem(sessionStorageKey);
    return eventStorageContent
      ? (JSON.parse(eventStorageContent) as AssignmentEvent[])
      : [];
  };

  static addEvent = (sessionStorageKey: string, event: AssignmentEvent) => {
    const list = this.getEventList(sessionStorageKey);
    if (list.length === 0) {
      list.push(event);
      this.setEventList(sessionStorageKey, list);
      return;
    }

    const lastElement = list.at(-1);
    if (
      lastElement &&
      (lastElement.type !== event.type ||
        new Date(event.timestamp).valueOf() -
          new Date(lastElement.timestamp).valueOf() >
          3000)
    ) {
      list.push(event);
      this.setEventList(sessionStorageKey, list);
    }
  };
}
