import { Category } from '../../../mistakes/domain/model/Category';
import type { TextWithMistakes } from '../../../mistakes/domain/model/TextWithMistakes';

export class OrthographyGradeComputingStrategy {
  protected computeErrorCount(textWithMistakes: TextWithMistakes): number {
    const spellingErrorCount = textWithMistakes.countPenalizedErrorsForCategory(
      Category.SPELLING,
    );
    const grammarErrorCount = textWithMistakes.countPenalizedErrorsForCategory(
      Category.GRAMMAR,
    );

    return spellingErrorCount + grammarErrorCount;
  }

  protected computeErrorRatio(
    textWithMistakes: TextWithMistakes,
    wordCount?: number,
  ): number {
    const errorCount = this.computeErrorCount(textWithMistakes);
    const totalWordCount = wordCount
      ? wordCount
      : textWithMistakes.countWords();
    const ratio = +((errorCount / totalWordCount) * 100).toFixed(2);
    return ratio;
  }
}
