import { SubCriterionChart } from "@/application/ui/pages/GradeReport/components/SubCriterionChart.tsx";

import { useGrades } from "../GradeStates/useGrades";
import { GlobalRankingChart } from "./GlobalRankingChart";

type Props = {
  selectStudent: (id: string) => void;
};

export const BottomChart = ({ selectStudent }: Props) => {
  const { gradeContextDto, student } = useGrades();
  const { isStudentMode, isSubcriteriaMode, selectedCriteria } =
    gradeContextDto;

  if (!isStudentMode && !isSubcriteriaMode) {
    return <GlobalRankingChart selectStudent={selectStudent} />;
  }
  if (
    (isSubcriteriaMode || (isStudentMode && student?.id !== "")) &&
    selectedCriteria !== "all"
  ) {
    return (
      <SubCriterionChart
        currentStudentId={student?.id}
        selectStudent={selectStudent}
      />
    );
  }

  return;
};
