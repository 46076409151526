import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { TeacherDashboardClient } from "@/application/client/TeacherDashboardClient.ts";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export function usePublishWritingTask() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (writingTaskId: string) =>
      TeacherDashboardClient.publishWritingTask(writingTaskId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(t("writingTaskPublishSucceed"));
      },
      onError: () => {
        ToastService.showError(t("failToPublishWritingTask"));
      },
    },
  );
  return { publish: mutate, publishing: isLoading };
}
