import axios from "axios";

import { MonitoringService } from "@/application/services/MonitoringService.ts";

import { TokenFetcher } from "./TokenFetcher";

const TIMEOUT_IN_MILLIS = 300000;
const BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const instanceConfig = {
  baseURL: BASE_URL,
  timeout: TIMEOUT_IN_MILLIS,
};

export const AuthenticatedHttpClient = axios.create(instanceConfig);

export const setupAuthenticatedHttpClient = (entraAuthScope: string) => {
  AuthenticatedHttpClient.interceptors.request.use(
    async (config) => {
      const token = await TokenFetcher.getToken(entraAuthScope);
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      config.headers["Accept"] = "application/json";
      return config;
    },
    (error) => {
      MonitoringService.logMessage(
        "Request Failure. Reason: " + JSON.stringify(error),
      );
      return Promise.reject(error);
    },
  );
};

export const HttpClient = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT_IN_MILLIS,
});
