import { useQuery } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useLostAssignmentState = (ids: string[]) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.lostAssignment,
    queryFn: () => AssignmentClient.getLostAssignment(ids),
  });
  return { lostAssignment: data };
};
