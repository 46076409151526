import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { OnAudioCompleted } from "@/application/services/AudioRecordingService.ts";
import { AudioPlayer } from "@/application/ui/lib/recording/components/AudioPlayer.tsx";
import { AudioRecorder } from "@/application/ui/lib/recording/components/AudioRecorder.tsx";
import { ControlButton } from "@/application/ui/lib/recording/components/AudioStyled.ts";
import { ReactComponent as DeleteIcon } from "@/assets/delete-icon.svg";

import { theme } from "../../../../theme/theme";

interface Props {
  onAudioCompleted: OnAudioCompleted;
  onDelete: () => void;
  url?: string;
}

const DURATION_LIMIT_IN_SECONDS = 60 * 6;

export const TeacherRecorder = ({ onDelete, onAudioCompleted, url }: Props) => {
  const { t } = useTranslation();

  if (url) {
    return (
      <>
        <AudioPlayer url={url} />

        <ControlButton onClick={onDelete}>
          <TrashIcon title={t("deleteAudio")} />
        </ControlButton>
      </>
    );
  }

  return (
    <>
      <AudioRecorder
        limitInSeconds={DURATION_LIMIT_IN_SECONDS}
        onAudioCompleted={onAudioCompleted}
      />
      <div style={{ width: "20px" /*size of the delete button */ }} />
    </>
  );
};

const TrashIcon = styled(DeleteIcon)`
  color: ${theme.colors.grayBlue[500]};
`;
