import { useState } from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { useFlaggedState } from "@/application/ui/pages/RevisionRequest/useFlaggedState.ts";
import { useLostAssignmentState } from "@/application/ui/pages/RevisionRequest/useLostAssignmentState.ts";
import { useRevisionsState } from "@/application/ui/pages/RevisionRequest/useRevisionsState.ts";

import { theme } from "../../theme/theme";

type QueueElement = {
  assignmentId: string;
  [k: string]: unknown;
};

interface QueueStateProps {
  title: string;
  elements: QueueElement[];
  canResend: boolean;
  isResendDisabled: boolean;
  isForced?: boolean;
}

type LostQueueProps = {
  Ids: string[];
  enabled: boolean;
};

const onPressResend = (assignmentId: string) => {
  void AssignmentClient.resendAssignment(assignmentId)
    .then(() => {
      ToastService.showSuccess("Réenvoyé avec success");
    })
    .catch((error: Error) => {
      ToastService.showError(`Une erreur est survenu: ${error.message}`);
    });
  window.location.reload();
};

const onPressForceSend = (assignmentId: string) => {
  void AssignmentClient.forceSendAssignment(assignmentId)
    .then(() => {
      ToastService.showSuccess("Réenvoyé avec success");
    })
    .catch((error: Error) => {
      ToastService.showError(`Une erreur est survenu: ${error.message}`);
    });
  window.location.reload();
};

const LostQueue = ({ Ids, enabled }: LostQueueProps) => {
  const { lostAssignment } = useLostAssignmentState(Ids);
  const filteredAssignment =
    lostAssignment?.filter((a) => !Ids.includes(a.assignmentId)) ?? [];
  return (
    <QueueState
      title="Copie perdu"
      elements={filteredAssignment}
      canResend={true}
      isResendDisabled={enabled}
    />
  );
};

const QueueState = ({
  elements,
  title,
  isResendDisabled,
  canResend,
  isForced = false,
}: QueueStateProps) => {
  return (
    <Group>
      <GroupTitle>{title}</GroupTitle>
      <ul>
        {elements.map((element) => (
          <Entry key={element.assignmentId}>
            <Content>
              <EntryName>Id: {element.assignmentId}</EntryName>
              <ReactJson collapsed enableClipboard src={element} />
            </Content>
            {canResend && (
              <Button
                onClick={() => {
                  if (isForced) {
                    onPressForceSend(element.assignmentId);
                  } else {
                    onPressResend(element.assignmentId);
                  }
                }}
                size="sm"
                disabled={isResendDisabled}
              >
                Réenvoyer la copie
              </Button>
            )}
          </Entry>
        ))}
      </ul>
    </Group>
  );
};

export const RevisionsStatePage = () => {
  const { data } = useRevisionsState();
  const { flagged } = useFlaggedState();
  const [isLockedMode, setIsLockedMode] = useState<boolean>(true);
  return (
    <Group>
      <Button onClick={() => setIsLockedMode(!isLockedMode)}>
        {" "}
        {isLockedMode ? "Debloquer réenvoie" : "Bloquer réenvoie"}
      </Button>
      {flagged && data && (
        <LostQueue
          Ids={[...ConcatIds(data), ...flagged.map((m) => m.assignmentId)]}
          enabled={isLockedMode}
        />
      )}
      <QueueState
        elements={flagged ?? []}
        title="Copie signaler par un enseignant"
        canResend={true}
        isResendDisabled={isLockedMode}
        isForced={true}
      />
      <QueueState
        elements={data?.pendingRequests ?? []}
        title="Requêtes en attente"
        canResend={false}
        isResendDisabled={isLockedMode}
      />
      <QueueState
        elements={data?.failedPendingRequests ?? []}
        title="Requêtes en attente (en traitement)"
        canResend={true}
        isResendDisabled={isLockedMode}
      />
      <QueueState
        elements={data?.completedRevisions ?? []}
        title="Corrections complétées en attente"
        canResend={false}
        isResendDisabled={isLockedMode}
      />
      <QueueState
        elements={data?.failedCompletedRevisions ?? []}
        title="Corrections complétées en attente (en erreur)"
        canResend={false}
        isResendDisabled={isLockedMode}
      />
    </Group>
  );
};

const ConcatIds = (data: {
  pendingRequests: QueueElement[];
  failedPendingRequests: QueueElement[];
  completedRevisions: QueueElement[];
  failedCompletedRevisions: QueueElement[];
}): string[] => {
  return [
    ...data.pendingRequests.map((e) => e.assignmentId),
    ...data.failedPendingRequests.map((e) => e.assignmentId),
    ...data.completedRevisions.map((e) => e.assignmentId),
    ...data.failedCompletedRevisions.map((e) => e.assignmentId),
  ];
};

const Group = styled.section`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 10px 40px;
  gap: 24px;
`;

const GroupTitle = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${theme.typography.lg.medium}
  color: ${theme.colors.gray[900]};
  gap: 8px;
`;

const Entry = styled.li`
  padding: 10px;
`;

const EntryName = styled.h3`
  margin-bottom: 15px;
`;

const Content = styled.div`
  margin-bottom: 15px;
`;
