import { useQuery } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import type { AuthConfig } from "../../../authentication/msal/EnvConfigClient";
import { EnvConfigClient } from "../../../authentication/msal/EnvConfigClient";
import { setupAuthenticatedHttpClient } from "../../../client/HttpClient";
import { LoadingSpinner } from "../../lib/spinner/LoadingSpinner";

type AuthConfigContextProps = {
  authConfig: AuthConfig | null;
};

export const AuthConfigContext = createContext<AuthConfigContextProps>({
  authConfig: null,
});

export const useAuthConfig = () => {
  const context = useContext(AuthConfigContext);

  if (!context || !context.authConfig) {
    throw new Error("useAuthConfig cannot be used outside AuthConfigProvider");
  }

  return {
    tenantId: context.authConfig.tenantId,
    applicationId: context.authConfig.applicationId,
    entraAuthScope: context.authConfig.entraAuthScope,
  };
};

export const AuthConfigProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading } = useQuery({
    queryKey: ["authConfig"],
    queryFn: () => EnvConfigClient.authConfig(),
  });

  if (isLoading || !data) {
    return <LoadingSpinner />;
  }

  setupAuthenticatedHttpClient(data.entraAuthScope);

  return (
    <AuthConfigContext.Provider value={{ authConfig: data }}>
      {children}
    </AuthConfigContext.Provider>
  );
};
