import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Page } from "@/application/ui/lib/Page.tsx";
import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { ReactComponent as HomeroomIcon } from "@/assets/homeroom-icon.svg";

import { theme } from "../../theme/theme";
import { StudentWritingTaskCard } from "./components/StudentWritingTaskCard";
import { useStudentDashboard } from "./hooks/useStudentDashboard";

export const StudentDashboard = () => {
  const { t } = useTranslation();

  const { dashboard } = useStudentDashboard();

  return (
    <Page>
      <Title>{t("dashboard")}</Title>

      {dashboard?.homerooms?.map((homeroom, index) => (
        <Fragment key={homeroom.id + homeroom.name}>
          <Group
            className={
              index !== dashboard.homerooms.length - 1 ? "separator" : ""
            }
          >
            <GroupTitle>
              <HomeroomIcon />
              {homeroom.name}
            </GroupTitle>

            <WritingTasks>
              {homeroom.writingTasks.map((task) => (
                <StudentWritingTaskCard key={task.id} writingTask={task} />
              ))}
            </WritingTasks>
          </Group>
        </Fragment>
      ))}
    </Page>
  );
};

const GroupTitle = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${theme.typography.lg.medium}
  color: ${theme.colors.gray[900]};
  gap: 8px;
  margin-bottom: 23px;
`;

const Group = styled.section`
  padding: 0 40px;
  gap: 24px;

  &.separator {
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`;

const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
`;
