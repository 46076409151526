import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { WritingTaskStatusClient } from "../../../../client/WritingTaskStatusClient";
import { ToastService } from "../../../lib/toast/ToastService";
import { TeacherQueryKeys } from "../utils/TeacherQueryKeys";

export const useOpenWritingTask = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (taskId: string) => WritingTaskStatusClient.openWritingTask(taskId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(
          t("openWritingTask.success.title"),
          t("openWritingTask.success.message"),
        );
      },
      onError: () => {
        ToastService.showError(t("openWritingTask.fail"));
      },
    },
  );

  return {
    isLoading,
    openWritingTask: mutate,
  };
};
