import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as EmptyFolder } from "@/assets/emptystates-empty-folder.svg";

import { theme } from "../../../theme/theme";

export const NoAssignmentsFoundRevisionPage = () => {
  const { t } = useTranslation();

  return (
    <NoAssignmentContainer>
      <EmptyFolder />
      <NoAssignmentWarningMessage>
        {t("noAssignmentsYet")}
      </NoAssignmentWarningMessage>
    </NoAssignmentContainer>
  );
};

const NoAssignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const NoAssignmentWarningMessage = styled.p`
  text-align: center;
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.medium}
  border-radius: ${theme.radius.large};
  padding-top: 3%;
  width: 30%;
`;
