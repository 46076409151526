import { useState } from "react";
import { Popover } from "react-tiny-popover";

import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { ReactComponent as PasswordLockIcon } from "@/assets/passcode-lock.svg";

import { UnlockCode } from "../../../../domain/UnlockCode";
import type { Button } from "../../../lib/button/Button";
import {
  ContextualMenu,
  ContextualMenuAction,
} from "./TeacherWritingTaskCard/Header/ContextMenu/WritingTaskContextMenu.tsx";

export interface WritingTaskContextMenuCodeProps {
  writingTaskId: string;
}

export const WritingTaskContextMenuCode = ({
  writingTaskId,
}: WritingTaskContextMenuCodeProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openContextMenu: React.ComponentProps<typeof Button>["onClick"] = (
    e,
  ) => {
    e.preventDefault();
    setMenuOpen(!isMenuOpen);
  };

  const closeContextMenu = () => setMenuOpen(false);

  const code = UnlockCode.fromId(writingTaskId);

  return (
    <Popover
      isOpen={isMenuOpen}
      positions={["right", "left"]}
      align="start"
      onClickOutside={() => {
        closeContextMenu();
      }}
      content={
        <ContextualMenu>
          <ContextualMenuAction variant="text" hierarchy="neutral">
            {code.value}
          </ContextualMenuAction>
        </ContextualMenu>
      }
    >
      <IconButton
        onClick={openContextMenu}
        aria-label="unlockCodeButton"
        hierarchy="neutral"
        size="xs"
        variant="text"
      >
        <PasswordLockIcon />
      </IconButton>
    </Popover>
  );
};
