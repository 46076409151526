import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { GradeReportClient } from "../../../../client/GradeReportClient.ts";

export const useGradeReport = (writingTaskId: string, studentId?: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.gradeReport(writingTaskId, studentId),
    queryFn: () =>
      GradeReportClient.getForWritingTask(writingTaskId, studentId),
    enabled: !!writingTaskId,
  });

  return data;
};
