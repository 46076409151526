import { CardActionsFactory } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/CallToAction/CardActionsFactory.tsx";
import type { ContextMenuProps } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/ContextMenu/ContextMenuFactory.tsx";
import { HeaderFactory } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/HeaderFactory.tsx";
import { TaskCard } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/TaskCard.tsx";

import { WritingTaskInformation } from "../WritingTaskInformation.tsx";
import { CardContentFactory } from "./Content/CardContentFactory.tsx";
import { TaskInformations } from "./Content/TaskInformations.tsx";

type Props = ContextMenuProps & {
  homeroomId: string;
};

export const DefaultTaskCard = ({
  writingTask,
  homeroomId,
  ...props
}: Props) => {
  return (
    <TaskCard color="white">
      <TaskCard.Header>
        <HeaderFactory writingTask={writingTask} {...props} />
        <WritingTaskInformation.Name>
          {writingTask.name}
        </WritingTaskInformation.Name>
      </TaskCard.Header>
      <TaskCard.Body>
        <TaskInformations writingTask={writingTask} />
        <CardContentFactory writingTask={writingTask} />
      </TaskCard.Body>
      <TaskCard.Footer>
        <CardActionsFactory writingTask={writingTask} homeroomId={homeroomId} />
      </TaskCard.Footer>
    </TaskCard>
  );
};
