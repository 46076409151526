import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { ReactComponent as DotsVertical } from "@/assets/dots-vertical.svg";

import { Button } from "../../../../lib/button/Button";
import { theme } from "../../../../theme/theme";

interface Action {
  label: string;
  callback: () => void;
  disabled?: boolean;
}

export interface Props {
  actions: Action[];
}

export const StudentSummaryContextMenu = ({ actions }: Props) => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openContextMenu: React.ComponentProps<typeof Button>["onClick"] = (
    e,
  ) => {
    e.preventDefault();
    setMenuOpen(true);
  };
  const closeContextMenu = () => setMenuOpen(false);

  const shouldShowActions = !!actions.filter(({ disabled }) => !disabled)
    .length;
  if (!shouldShowActions) {
    return null;
  }

  return (
    <Popover
      isOpen={isMenuOpen}
      positions="left"
      align="start"
      onClickOutside={closeContextMenu}
      content={
        <ContextualMenu>
          {actions.map(({ label, disabled, callback }) => (
            <ContextualMenuAction
              key={label}
              variant="text"
              hierarchy="neutral"
              onClick={(e) => {
                e.preventDefault();
                callback();
                closeContextMenu();
              }}
              disabled={disabled ?? false}
            >
              {label}
            </ContextualMenuAction>
          ))}
        </ContextualMenu>
      }
    >
      <IconButton
        onClick={openContextMenu}
        hierarchy="neutral"
        variant="text"
        size="xs"
        aria-label={t("moreOptions")}
        style={{ cursor: "pointer" }}
      >
        <DotsVertical />
      </IconButton>
    </Popover>
  );
};

const ContextualMenu = styled.section`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${theme.radius.small};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.large};
  width: fit-content;
  border: ${theme.borders.primary};
`;

const ContextualMenuAction = styled(Button)`
  justify-content: flex-start;
`;
