import styled from "styled-components";

import type { TeacherWritingTask } from "../../../../../../../domain/Teacher/TeacherDashboard.ts";
import { Button } from "../../../../../../lib/button/Button.tsx";
import { theme } from "../../../../../../theme/theme.ts";
import type { WritingTaskContextMenuAction } from "../../../WritingTaskContextMenuActions.tsx";
import { WritingTaskContextMenuActions } from "../../../WritingTaskContextMenuActions.tsx";
import { WritingTaskContextMenuCode } from "../../../WritingTaskContextMenuCode.tsx";

export type Props = {
  writingTask: TeacherWritingTask;
  actions: WritingTaskContextMenuAction[];
};

export const WritingTaskContextMenu = ({ writingTask, actions }: Props) => {
  const displayCode = writingTask.taskType === "summative";
  return (
    <Container>
      {displayCode && (
        <WritingTaskContextMenuCode writingTaskId={writingTask.id} />
      )}
      <WritingTaskContextMenuActions actions={actions} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

export const ContextualMenu = styled.section`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${theme.radius.small};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.large};
  width: fit-content;
  border: ${theme.borders.primary};
`;

export const ContextualMenuAction = styled(Button)`
  justify-content: flex-start;
`;
