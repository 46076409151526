import { values } from 'lodash';

export type EvaluationCriteria = {
  C1: boolean;
  C2: boolean;
  C3: boolean;
  C4: boolean;
  C5: boolean;
};

export function getCriteriaByIndex(
  evaluationCriteria: EvaluationCriteria,
  index: number,
): boolean {
  if (index < 0 || index >= 5) {
    throw new Error('Index should be between 0 and 4, inclusive');
  }

  return values(evaluationCriteria)[index];
}

export function getDefaultEvaluationCriteria(): EvaluationCriteria {
  return { C1: true, C2: true, C3: true, C4: true, C5: true };
}

export function isEvaluationCriteriaValid(ec: EvaluationCriteria) {
  return ec.C1 || ec.C2 || ec.C3 || ec.C4 || ec.C5;
}
