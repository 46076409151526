export class UnlockCode {
  public value: number;
  constructor(value: number) {
    this.value = value;
  }

  static create(input: string, length: number = 4) {
    const decoded = atob(input);

    const numericString = decoded
      .split("")
      .map((char) => char.charCodeAt(0))
      .join("");

    const code = parseInt(numericString.slice(0, length), 10);
    return new this(code);
  }

  static fromId(id: string) {
    return this.create(id.split("-", 1)[0]);
  }
}
