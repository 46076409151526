import { useQuery } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useFlaggedState = () => {
  const { data, refetch } = useQuery({
    queryKey: TeacherQueryKeys.flaggedState,
    queryFn: () => AssignmentClient.getFlagged(),
  });
  return { flagged: data, refetch };
};
