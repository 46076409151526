const family = {
  inter: "Inter",
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

interface Props {
  fontSize: number;
  lineHeight: number;
  letterSpacing?: string;
  textTransform?: string;
}

interface Font {
  regular: string;
  medium: string;
  semibold: string;
  bold: string;
}

export const createFont = (config: Props): Font => {
  const { fontSize, lineHeight, letterSpacing, textTransform } = config;
  const base = `
    font-family: ${family.inter};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ""}
    ${textTransform ? `text-transform: ${textTransform};` : ""}
  `;

  return {
    regular: `
      ${base}
      font-weight: ${fontWeight.regular};
    `,
    medium: `
      ${base}
      font-weight: ${fontWeight.medium};
    `,
    semibold: `
      ${base}
      font-weight: ${fontWeight.semibold};
    `,
    bold: `
      ${base}
      font-weight: ${fontWeight.bold};
    `,
  };
};
