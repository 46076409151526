import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";

interface Props {
  color: "warning" | "success" | "error";
  size: number;
}

export const FeaturedIcon = ({
  color,
  children,
  size,
}: PropsWithChildren<Props>) => {
  return (
    <Icon color={color} size={size}>
      {children}
    </Icon>
  );
};

const Icon = styled.span<Props>`
  ${({ color, size }) => `
    background-color: ${theme.colors[color][100]};
    border: 6px solid ${theme.colors[color][50]};
    width: ${size}px;
    height: ${size}px;
    padding: 10px;
    > * {
      width: ${size}px;
      height: ${size}px;
      color: ${theme.colors[color][700]};
    }
  `};
  border-radius: 28px;
`;
