import { type PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as InfoIcon } from "@/assets/info-circle.svg";
import { ReactComponent as PasscodeIcon } from "@/assets/passcode-lock.svg";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { useLoggedUser } from "../../../../../authentication/hooks/useLoggedUser";
import { tooltipStyle } from "../../../../lib/tooltip/tooltipStyle";
import { theme } from "../../../../theme/theme";

interface Props {
  color: "warning" | "success" | "error";
  closable?: boolean;
  withTooltip?: boolean;
}

interface ColorProps {
  color: "warning" | "success" | "error";
}

export const AdvertiseSecuredMode = ({
  color,
  closable = true,
  withTooltip = false,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { user } = useLoggedUser();
  const storageKey = `${user.id}.hideSecuredMode`;

  const [shouldAdvertiseSecuredMode, setShouldAdvertiseSecuredMode] = useState(
    !localStorage.getItem(storageKey),
  );

  const handleHideSecuredMode = () => {
    localStorage.setItem(storageKey, "hide");
    setShouldAdvertiseSecuredMode(false);
  };

  if (closable && !shouldAdvertiseSecuredMode) {
    return;
  }

  return (
    <SecuredModeAdvertisement color={color}>
      <Lock color={color} />
      <SecuredModeDescription color={color}>
        {t("securedSummativeMode.disclaimer")}
      </SecuredModeDescription>
      {closable && <CloseIcon color={color} onClick={handleHideSecuredMode} />}
      {withTooltip && (
        <>
          <Info id="anchor" color={color} />
          <Tooltip
            anchorSelect="#anchor"
            place="bottom-end"
            offset={-5}
            style={tooltipStyle}
          >
            <TooltipContent>{t("securedSummativeMode.tooltip")}</TooltipContent>
          </Tooltip>
        </>
      )}
    </SecuredModeAdvertisement>
  );
};

const TooltipContent = styled.div`
  width: 200px;
`;

const Info = styled(InfoIcon)<ColorProps>`
  cursor: pointer;
  * {
    ${({ color }) => `
      stroke: ${theme.colors[color][700]};
    `};
  }
`;

const CloseIcon = styled(XCloseIcon)<ColorProps>`
  ${({ color }) => `
    width: 36px;
    height: 36px;
    > * {
        stroke: ${theme.colors[color][700]};
    }
  `};
`;

const Lock = styled(PasscodeIcon)<ColorProps>`
  width: 24px;
  height: 24px;
  ${({ color }) => `
    > * {
      stroke: ${theme.colors[color][700]};
    }
  `};
`;

const SecuredModeDescription = styled.mark<ColorProps>`
  ${theme.typography.xs.semibold}

  ${({ color }) => `
    color: ${theme.colors[color][700]};
  `};
`;

const SecuredModeAdvertisement = styled.label<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${theme.colors.success[300]};
  background: ${theme.colors.success[25]};
  border-radius: ${theme.radius.large};
  padding: 16px;
  gap: 12px;

  ${({ color }) => `
    border: 2px solid ${theme.colors[color][300]};
    background: ${theme.colors[color][25]};
  `};
`;
