import { theme } from "@/application/ui/theme/theme.ts";

export const tooltipStyle = {
  boxShadow: theme.shadowAndBlur.large,
  color: "#363F72",
  textAlign: "center" as const, // IDE screams because of typing without const
  backgroundColor: "white",
  fontSize: "0.75rem",
  fontWeight: "600",
  lineHeight: "1.5",
  zIndex: 999999,
};
