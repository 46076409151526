import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { GradeReportClient } from "../../../../client/GradeReportClient.ts";

export const useGradedStudents = (writingTaskId: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.gradedStudents(writingTaskId),
    queryFn: () =>
      GradeReportClient.getGradedStudentsForWritingTask(writingTaskId),
    enabled: !!writingTaskId,
  });

  return { gradedStudents: data };
};
