import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { Card } from "@/application/ui/lib/Card.tsx";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { theme } from "../../theme/theme";

export interface Props {
  icon: JSX.Element;
  title: string;
  description: string;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export interface ConfirmationDialogRef {
  display: () => void;
}

export const ConfirmationDialog = React.forwardRef(
  (
    {
      onConfirm,
      onCancel,
      title,
      description,
      confirmLabel,
      cancelLabel,
      icon,
    }: Props,
    ref: Ref<ConfirmationDialogRef>,
  ) => {
    const [open, isOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      display: () => {
        isOpen(true);
      },
    }));

    const handleConfirm = () => {
      onConfirm();
      isOpen(false);
    };

    const handleCancel = () => {
      onCancel?.();
      isOpen(false);
    };

    return (
      <Backdrop open={open}>
        <Modal as="dialog">
          <FloatingIconButton
            onClick={handleCancel}
            type="button"
            size="sm"
            hierarchy="neutral"
            variant="text"
          >
            <XCloseIcon />
          </FloatingIconButton>
          <Header>
            {icon}
            <Title>{title}</Title>
          </Header>
          <Content>
            <SupportingText>{description}</SupportingText>
          </Content>
          <Actions>
            <ActionsContent>
              <Button onClick={handleConfirm} hierarchy="secondary">
                {confirmLabel}
              </Button>
              <Button onClick={handleCancel} hierarchy="neutral" variant="text">
                {cancelLabel}
              </Button>
            </ActionsContent>
          </Actions>
        </Modal>
      </Backdrop>
    );
  },
);

const Backdrop = styled.div<{ open: boolean }>`
  ${({ theme, open }) => `
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: ${open ? "flex" : "none"};
      justify-content: center;
      align-items: center;
      background-color: ${theme.colors.gray[700]}B3;
      backdrop-filter: blur(5px);
    `}
`;

const Modal = styled(Card)`
  flex-direction: column;
  border: 0;
  z-index: 2;
  padding: 0;
  width: 652px;
  color: ${theme.colors.gray[900]};
  box-shadow: 0 20px 24px -4px ${theme.colors.gray[900]}08;
`;

const Header = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  gap: 16px;
`;

const Title = styled.span`
  ${theme.typography.lg.semibold}
`;

const SupportingText = styled.span`
  ${theme.typography.sm.regular};
  color: ${theme.colors.gray[600]};
`;

const Actions = styled.div`
  padding-top: 32px;
  gap: 8px;
`;

const ActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
  gap: 12px;
`;

const FloatingIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
`;
