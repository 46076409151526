import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CategoryIcon } from "@/application/ui/pages/Revision/CategoryIcon.tsx";

import { theme } from "../../../../theme/theme";

interface CategoriesProps {
  current: Category;
  onChangeCategory: (category: Category) => void;
  readOnly: boolean;
}

export const Categories = ({
  current,
  onChangeCategory,
  readOnly,
}: CategoriesProps) => {
  const { t } = useTranslation();

  const isSelected = (category: Category) => current === category;
  const displayedCategories = [
    Category.VOCABULARY,
    Category.SYNTAX,
    Category.PUNCTUATION,
    Category.GRAMMAR,
    Category.SPELLING,
  ];
  return readOnly ? (
    <CategoryElement className={clsx({ isSelected, readOnly })}>
      <CategoryIcon category={current} />
      {t(current)}
    </CategoryElement>
  ) : (
    <Flex aria-label="category-options">
      {displayedCategories.map((category) => (
        <CategoryOption
          key={category}
          category={category}
          isSelected={isSelected(category)}
          onChange={() => onChangeCategory(category)}
        />
      ))}
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

interface CategoryOptionsProps {
  category: Category;
  isSelected: boolean;
  onChange: () => void;
}

const CategoryOption = ({
  category,
  isSelected,
  onChange,
}: CategoryOptionsProps) => {
  const { t } = useTranslation();
  return (
    <CategoryElement
      data-testid={`categoryButton-${category}`}
      onClick={onChange}
      className={clsx({ isSelected })}
    >
      <Radio type="radio" checked={isSelected} hidden={true} readOnly />
      <CategoryIcon category={category} />
      <p>{t(category)}</p>
    </CategoryElement>
  );
};

const Radio = styled.input`
  &:checked {
    accent-color: ${theme.colors.grayBlue[500]};
  }
`;

const CategoryElement = styled.div`
  &:not(.readOnly) {
    cursor: pointer;

    &:hover {
      background: ${theme.colors.gray[50]};
    }
  }

  ${theme.typography.sm.medium}
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: ${theme.radius.small};

  box-shadow: 0 0 0 1px ${theme.colors.gray[200]};
  background-color: ${theme.colors.base.white};

  &.isSelected {
    box-shadow: 0 0 0 2px ${theme.colors.primary[400]};
    background-color: ${theme.colors.primary[25]};
  }
`;
