import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import { Tooltip } from "react-tooltip";

import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle.ts";
import { ReactComponent as DotsVertical } from "@/assets/dots-vertical.svg";

import type { Button } from "../../../lib/button/Button";
import {
  ContextualMenu,
  ContextualMenuAction,
} from "./TeacherWritingTaskCard/Header/ContextMenu/WritingTaskContextMenu.tsx";

export type WritingTaskContextMenuAction = {
  label: string;
  children: string | JSX.Element;
  callback: () => void;
  disabled?: boolean;
  className?: string;
  tooltipMessage?: string;
  keepOpenOnClick?: boolean;
};

export interface WritingTaskContextMenuActionsProps {
  actions: WritingTaskContextMenuAction[];
}

export const WritingTaskContextMenuActions = ({
  actions,
}: WritingTaskContextMenuActionsProps) => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openContextMenu: React.ComponentProps<typeof Button>["onClick"] = (
    e,
  ) => {
    e.preventDefault();
    setMenuOpen(true);
  };
  const closeContextMenu = () => setMenuOpen(false);
  return (
    <Popover
      isOpen={isMenuOpen}
      positions={["right", "left"]}
      align="start"
      onClickOutside={closeContextMenu}
      content={
        <ContextualMenu>
          {actions.map(
            ({
              label,
              disabled,
              callback,
              children,
              className,
              tooltipMessage,
            }) => (
              <ContextualMenuAction
                key={label}
                className={className}
                variant="text"
                hierarchy="neutral"
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  callback();
                  closeContextMenu();
                }}
              >
                {className && tooltipMessage && disabled && (
                  <Tooltip
                    anchorSelect={"." + className}
                    place="bottom"
                    style={tooltipStyle}
                  >
                    {tooltipMessage}
                  </Tooltip>
                )}
                {children}
              </ContextualMenuAction>
            ),
          )}
        </ContextualMenu>
      }
    >
      <IconButton
        onClick={openContextMenu}
        aria-label={t("moreOptions")}
        hierarchy="neutral"
        size="xs"
        variant="text"
      >
        <DotsVertical />
      </IconButton>
    </Popover>
  );
};
