import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { SyntaxGradeComputingStrategy } from '../SyntaxGradeComputingStrategy';
import { SecondaryTwoSyntaxValuedGradeMapper } from './SecondaryTwoSyntaxValuedGradeMapper';

export class SecondaryTwoSyntaxGradeComputingStrategy
  extends SyntaxGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryTwoSyntaxValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryTwoSyntaxValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);

    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio <= 0.6:
        return Grade.of('A+');
      case orthographyErrorWordRatio <= 1.49:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 2.99:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 4.49:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 5.99:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 7.49:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
