import type { GradeComputingStrategy } from '../../model/GradeComputingStrategy';
import type { GradingScaleType } from '../../model/GradingScaleType';
import { SecondaryFiveSyntaxGradeComputingStrategy } from './secondaryFive/SecondaryFiveSyntaxGradeComputingStrategy';
import { SecondaryFiveMinistrySyntaxGradeComputingStrategy } from './secondaryFiveMinistry/SecondaryFiveMinistrySyntaxGradeComputingStrategy';
import { SecondaryFourSyntaxGradeComputingStrategy } from './secondaryFour/SecondaryFourSyntaxGradeComputingStrategy';
import { SecondaryOneSyntaxGradeComputingStrategy } from './secondaryOne/SecondaryOneSyntaxGradeComputingStrategy';
import { SecondaryThreeSyntaxGradeComputingStrategy } from './secondaryThree/SecondaryThreeSyntaxGradeComputingStrategy';
import { SecondaryTwoSyntaxGradeComputingStrategy } from './secondaryTwo/SecondaryTwoSyntaxGradeComputingStrategy';

export class SyntaxGradeComputingStrategyFactory {
  static create(type: GradingScaleType): GradeComputingStrategy {
    switch (type) {
      case 'secondary_one':
        return new SecondaryOneSyntaxGradeComputingStrategy();
      case 'secondary_two':
        return new SecondaryTwoSyntaxGradeComputingStrategy();
      case 'secondary_three':
        return new SecondaryThreeSyntaxGradeComputingStrategy();
      case 'secondary_four':
        return new SecondaryFourSyntaxGradeComputingStrategy();
      case 'secondary_five':
        return new SecondaryFiveSyntaxGradeComputingStrategy();
      case 'secondary_five_ministry':
        return new SecondaryFiveMinistrySyntaxGradeComputingStrategy();
      default:
        throw new Error('Not implemented yet');
    }
  }
}
