import styled, { keyframes } from "styled-components";

import { ReactComponent as LoadingIcon } from "../../../../assets/loading-icon.svg";

export const LoadingSpinner = () => {
  return (
    <SpinnerAnimator>
      <LoadingIcon aria-live="polite" role="status" aria-label="loading" />
    </SpinnerAnimator>
  );
};

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerAnimator = styled.div`
  width: 100%;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spinAnimation} 1s steps(8, end) infinite;
`;
