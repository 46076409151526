import type { WritingTaskAssignmentsResponseDto } from "@api/WritingTaskAssignmentsResponse.dto.ts";
import type { WritingTaskResponseDto } from "@api/WritingTaskResponse.dto.ts";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";

import { AssignmentMapper } from "@/application/client/mapper/AssignmentMapper.ts";
import type { TaskType } from "@/application/domain/TaskType.ts";
import { WritingTask } from "@/application/domain/writingTask.ts";
import type { RedactionType } from "@/application/domain/WritingTask/RedactionType.ts";
import { WritingTaskAssignments } from "@/application/domain/WritingTaskAssignments.ts";

export const WritingTaskMapper = {
  assembleWithAssignmentsToDomain: (
    response: WritingTaskAssignmentsResponseDto,
  ): WritingTaskAssignments => {
    return new WritingTaskAssignments({
      ...response,
      writingTask: {
        id: response.writingTask.id,
        name: response.writingTask.name,
        gradingScaleType: response.writingTask
          .gradingScaleType as GradingScaleType,
        evaluatedCriteria: response.writingTask.evaluationCriteria,
      },
      assignments: response.assignments
        .filter((x) => x !== null)
        .map((dto) => AssignmentMapper.fromDto(dto))
        .reverse(),
    });
  },
  assembleToDomain: (response: WritingTaskResponseDto): WritingTask => {
    return new WritingTask({
      id: response.writingTask.id,
      name: response.writingTask.name,
      instructions: response.writingTask.instructions,
      taskType: response.writingTask.taskType as TaskType,
      redactionType: response.writingTask.redactionType as RedactionType,
      gradingScaleType: response.writingTask
        .gradingScaleType as GradingScaleType,
      evaluatedCriteria: response.writingTask.evaluationCriteria,
      isOpen: response.writingTask.isOpen,
      numberOfWords: response.writingTask.numberOfWords,
    });
  },
};
