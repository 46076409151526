import type { PropsWithChildren, ReactNode } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { IconButton } from "../button/IconButton";

type Props = PropsWithChildren & {
  icon: ReactNode;
  open: boolean;
  title: string;
  description: ReactNode;
  onClose?: () => void;
};

export const BaseModal = ({
  children,
  open,
  icon,
  title,
  onClose,
  description,
}: Props) => {
  return (
    <Modal
      style={modalStyles}
      isOpen={open}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <Container>
        <TitleLabel>
          {icon}
          <Title>{title}</Title>
          {onClose && (
            <FloatingIconButton
              onClick={onClose}
              type="button"
              size="sm"
              hierarchy="neutral"
              variant="text"
            >
              <XCloseIcon />
            </FloatingIconButton>
          )}
        </TitleLabel>
        <Body>{description}</Body>
        <Divider />
        <ButtonContainer>{children}</ButtonContainer>
      </Container>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    bottom: "auto",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "850px",
    borderRadius: "12px",
    border: "1px",
    padding: "0",
    backgroundColor: theme.colors.base.white,
    color: theme.colors.base.black,
    boxShadow: "0 20px 24px -4px " + theme.colors.gray[900] + "08",
  },
  overlay: {
    background: theme.colors.gray[700] + "B3",
    backdropFilter: "blur(4px)",
  },
};

const FloatingIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

const TitleLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Title = styled.span`
  ${theme.typography.lg.semibold}
  color: ${theme.colors.gray[900]};
`;

const Body = styled.span`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Divider = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  padding: 0 10px 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: self-end;
  width: fit-content;
  gap: 8px;
`;
