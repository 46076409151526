import { useQuery } from "@tanstack/react-query";

import { ReportSummaryClient } from "@/application/client/ReportSummaryClient";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useReportSummary = (writingTaskId: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.reportSummary(writingTaskId),
    queryFn: () => ReportSummaryClient.getForWritingTask(writingTaskId),
    enabled: !!writingTaskId,
  });

  return { reportSummary: data };
};
