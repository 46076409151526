import type { HomeroomsResponse } from "@api/HomeroomsResponse.ts";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import type { TeacherHomeroomsList } from "@/application/domain/Teacher/TeacherDashboard.ts";

export const TeacherHomeroomsClient = {
  fetchHomerooms: async (): Promise<TeacherHomeroomsList> => {
    const { data } = await AuthenticatedHttpClient.get<HomeroomsResponse>(
      `/homeroom`,
      { timeout: 25000 },
    );
    return data.homerooms;
  },
};
