import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { GlobalRankingClient } from "../../../../client/GlobalRankingClient.ts";

export const useGlobalRanking = (writingTaskId: string, criteria?: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.globalRanking(writingTaskId, criteria),
    queryFn: () =>
      GlobalRankingClient.getForWritingTask(writingTaskId, criteria),
  });

  return { globalRanking: data };
};
