import { useQueryClient } from "@tanstack/react-query";
import type { ReactNode, Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styled, { css } from "styled-components";

import { AssignmentStatus } from "@/application/domain/Assignment.ts";
import type { WritingTaskListing } from "@/application/domain/HomeroomListing.ts";
import { RightPanel } from "@/application/ui/lib/RightPanel.tsx";
import { usePublishAnAssignment } from "@/application/ui/pages/Dashboard/hooks/usePublishAnAssignment.ts";
import { useUnlockAssignment } from "@/application/ui/pages/Dashboard/hooks/useUnlockAssignment.ts";
import { REVISION_PAGE_URL } from "@/application/ui/pages/Dashboard/TeacherDashboard.tsx";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";
import { ReactComponent as ArrowDown } from "@/assets/arrow-down.svg";
import { ReactComponent as ArrowUp } from "@/assets/arrow-up.svg";
import { ReactComponent as Check } from "@/assets/check.svg";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { IconButton } from "../../../../lib/button/IconButton.tsx";
import { tooltipStyle } from "../../../../lib/tooltip/tooltipStyle.ts";
import { theme } from "../../../../theme/theme.ts";
import { useGroupWritingTask } from "../../hooks/useGroupWritingTask.ts";
import {
  SortDirection,
  SortType,
  useSummarySort,
} from "../../hooks/useSummarySort.ts";
import { NameColumnPopover } from "./NameColumnPopover.tsx";
import { StudentSummaryContextMenu } from "./StudentSummaryContextMenu.tsx";
import { WritingTaskStatus } from "./WritingTaskStatus.tsx";

export interface WritingTaskSummaryPanelRef {
  openForWritingTask: (writingTask: WritingTaskListing) => void;
  forHomeroom: (name: string) => void;
}

export const WritingTaskSummaryPanel = React.forwardRef(
  (_, ref: Ref<WritingTaskSummaryPanelRef>) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [writingTask, setWritingTask] = useState<
      WritingTaskListing | undefined
    >();
    const [homeroomName, setHomeroomName] = useState("");
    const groupSummary = useGroupWritingTask(writingTask?.id);
    const { unlock } = useUnlockAssignment({
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        await queryClient.invalidateQueries(
          TeacherQueryKeys.writingTaskSummary(writingTask!.id),
        );
      },
    });
    const { publish } = usePublishAnAssignment();
    const { activeSort, sortDirection, handleSort, sortedSummaries } =
      useSummarySort(groupSummary ?? []);
    const [contextualMenuOpen, setContextualMenuOpen] = useState(false);
    const [isNameFirst, setIsNameFirst] = useState(true);

    useImperativeHandle(ref, () => ({
      openForWritingTask: setWritingTask,
      forHomeroom: setHomeroomName,
    }));
    const navigate = useNavigate();
    return (
      <RightPanel opened={!!writingTask} width={850}>
        <Header style={{ flexDirection: "row" }}>
          <TitleContainer>
            <Title>{t("writingTaskGradeSummary")}</Title>
            <GroupInformations>
              {`${t("student", { count: groupSummary?.length })} - ${homeroomName}`}
            </GroupInformations>
          </TitleContainer>
          <IconButton
            variant="text"
            hierarchy="neutral"
            onClick={() => {
              setWritingTask(undefined);
            }}
          >
            <XCloseIcon />
          </IconButton>
        </Header>

        <RightPanel.Content style={{ padding: 0 }}>
          <AssignmentsTable>
            <thead>
              <AssignmentsHeader>
                <AssignmentsHeaderElement width={findColumnWidth()}>
                  {t(`hashtag`)}
                </AssignmentsHeaderElement>
                <SortableHeader
                  id="studentNameContainer"
                  activeSort={activeSort}
                  sortType={SortType.name}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  label={isNameFirst ? t(`nomPrenom`) : t(`prenomNom`)}
                >
                  <NameColumnPopover
                    contextualMenuOpen={contextualMenuOpen}
                    isNameFirst={isNameFirst}
                    setContextualMenuOpen={setContextualMenuOpen}
                    setIsNameFirst={setIsNameFirst}
                  />
                </SortableHeader>
                <SortableHeader
                  activeSort={activeSort}
                  sortType={SortType.status}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  label={t(`status`)}
                />
                <SortableHeader
                  activeSort={activeSort}
                  sortType={SortType.published}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  label={t(`published`)}
                />
                <SortableHeader
                  activeSort={activeSort}
                  sortType={SortType.grades}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  label={t(`grades`)}
                />
                <AssignmentsHeaderElement width={findColumnWidth()} />
              </AssignmentsHeader>
            </thead>
            <tbody>
              {sortedSummaries.map((summary, index) => (
                <Assignment
                  key={
                    summary.assignment.id.length > 0
                      ? summary.assignment.id
                      : summary.id
                  }
                >
                  <AssignmentRowElement width={findColumnWidth()}>
                    {summary.number}
                  </AssignmentRowElement>
                  <AssignmentRowElement
                    className={`inactiveStudent${index}`}
                    width={findColumnWidth(SortType.name)}
                  >
                    <StudentNameElement $pxwidth={getDynamicWidth()}>
                      {isNameFirst
                        ? summary.name.fullNameReversed()
                        : summary.name.fullName()}
                    </StudentNameElement>
                  </AssignmentRowElement>
                  {!summary.active && (
                    <Tooltip
                      anchorSelect={`.inactiveStudent${index}`}
                      content={t("inactiveStudent")}
                      place="top"
                      offset={0}
                      style={{
                        ...tooltipStyle,
                      }}
                    />
                  )}

                  <AssignmentRowElement
                    width={findColumnWidth(SortType.status)}
                  >
                    <WritingTaskStatus
                      status={
                        summary.active
                          ? summary.assignment?.status
                          : AssignmentStatus.NULL
                      }
                    />
                  </AssignmentRowElement>
                  <AssignmentRowElement
                    width={findColumnWidth(SortType.published)}
                  >
                    {summary.assignment?.published ? (
                      <GreenCheck width={20} height={20} />
                    ) : (
                      "-"
                    )}
                  </AssignmentRowElement>
                  <AssignmentRowElement
                    width={findColumnWidth(SortType.grades)}
                  >
                    {summary.assignment?.grade || "-"}
                  </AssignmentRowElement>
                  <AssignmentRowElement width={findColumnWidth()}>
                    <StudentSummaryContextMenu
                      actions={
                        summary.assignment?.id
                          ? [
                              {
                                label: t(
                                  "studentSummaryContextMenu.unlockAssignment",
                                ),
                                callback: () => unlock(summary.assignment.id),
                                disabled:
                                  summary.assignment?.status ===
                                  AssignmentStatus.WRITING_IN_PROGRESS,
                              },
                              {
                                label: t(
                                  "studentSummaryContextMenu.publishAssignment",
                                ),
                                callback: () =>
                                  publish({
                                    writingTaskId: writingTask!.id,
                                    assignmentId: summary.assignment.id,
                                  }),
                                disabled:
                                  summary.assignment.status !==
                                    AssignmentStatus.GRADED ||
                                  summary.assignment.published,
                              },
                              {
                                label: t("studentSummaryContextMenu.see_copie"),
                                callback: () =>
                                  navigate(
                                    `${REVISION_PAGE_URL}/${writingTask!.id}/${summary.id}`,
                                  ),
                                disabled:
                                  summary.assignment.status !==
                                    AssignmentStatus.GRADED &&
                                  summary.assignment.status !==
                                    AssignmentStatus.CORRECTED &&
                                  summary.assignment.status !==
                                    AssignmentStatus.CORRECTION_REJECTED,
                              },
                            ]
                          : []
                      }
                    />
                  </AssignmentRowElement>
                </Assignment>
              ))}
            </tbody>
          </AssignmentsTable>
        </RightPanel.Content>
      </RightPanel>
    );
  },
);

interface SortableHeaderProps extends HeaderProps {
  activeSort: SortType;
  children?: ReactNode;
  id?: string;
  sortDirection: SortDirection;
  handleSort: (sortType: SortType) => void;
}

interface HeaderProps {
  sortType: SortType;
  label: string;
}

interface ColumnSettings {
  key: SortType;
  value: number;
}

const sortableColumnsWidth: ColumnSettings[] = [
  { key: SortType.name, value: 2 },
  { key: SortType.grades, value: 1 },
  { key: SortType.status, value: 3 },
  { key: SortType.published, value: 1 },
];

const findColumnWidth = (column?: SortType): number => {
  if (column === undefined) {
    return 0;
  }
  return sortableColumnsWidth.find((x) => x.key === column)!.value;
};

const getDynamicWidth = (): number => {
  const container = document.querySelector("#studentNameContainer");
  return container?.getBoundingClientRect().width ?? 0;
};

const SortableHeader = ({
  activeSort,
  sortType,
  sortDirection,
  handleSort,
  label,
  id,
  children,
}: SortableHeaderProps) => {
  const isActiveSort = activeSort === sortType;
  const className = isActiveSort ? "active" : "inactive";

  return (
    <AssignmentsHeaderElement
      id={id}
      width={findColumnWidth(sortType)}
      $sortable
      onClick={() => handleSort(sortType)}
    >
      {isActiveSort ? (
        sortDirection === SortDirection.asc ? (
          <StyledUpArrow className={className} />
        ) : (
          <StyledDownArrow className={className} />
        )
      ) : (
        <StyledDownArrowHover className={className} />
      )}
      <ElementContent>
        {label}
        {children}
      </ElementContent>
    </AssignmentsHeaderElement>
  );
};

const GreenCheck = styled(Check)`
  > * {
    stroke: ${theme.colors.success[500]};
  }
`;

const Header = styled(RightPanel.Header)`
  display: flex;
  justify-content: space-between;
`;

const AssignmentsTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AssignmentsHeader = styled.tr`
  display: flex;
  background-color: ${theme.colors.gray[200]};
  border-top: 1px solid #f9fafb;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const GroupInformations = styled.div`
  padding: 4px 8px;
  border-radius: ${theme.radius.round};
  align-self: center;
  background-color: ${theme.colors.gray[100]};
  ${theme.typography.xs.regular}
`;

const StyledArrow = css`
  margin-right: 8px;
  &.active {
    color: ${theme.colors.gray[600]};
  }

  &.inactive {
    color: ${theme.colors.gray[400]};
  }
`;

const StyledUpArrow = styled(ArrowUp)`
  ${StyledArrow}
`;

const StyledDownArrow = styled(ArrowDown)`
  ${StyledArrow}
`;

const StyledDownArrowHover = styled(StyledDownArrow)`
  visibility: hidden;
`;

const AssignmentsHeaderElement = styled.td<{
  width: number;
  $sortable?: boolean;
}>`
  flex: ${(props) => props.width};
  display: flex;
  margin: 13px 20px;
  min-width: 24px;
  color: ${theme.colors.gray[600]};
  ${theme.typography.xs.regular}
  align-items: flex-start;
  cursor: ${(props) => (props.$sortable ? "pointer" : "default")};

  &:hover ${StyledDownArrowHover} {
    visibility: visible;
  }
`;

const Assignment = styled.tr`
  display: flex;
  border-bottom: 1px solid #eaecf0;
`;

const AssignmentRowElement = styled.td<{ width: number }>`
  display: flex;
  flex: ${(props) => props.width};
  margin: 24px 20px;
  min-width: 24px;
  width: 100%;
  ${theme.typography.sm.medium}
  color: ${theme.colors.gray[900]};
  align-items: center;
  line-height: 20px;
`;

const StudentNameElement = styled.div<{ $pxwidth: number }>`
  width: ${(props) => props.$pxwidth}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ElementContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

const Title = styled.h2`
  flex: 1;
  color: ${theme.colors.gray[900]};
  ${theme.typography.lg.bold}
`;
