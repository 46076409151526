import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { FeaturedIcon } from "@/application/ui/lib/icon/FeaturedIcon.tsx";
import { ReactComponent as Lock } from "@/assets/passcode-lock.svg";

import { BaseModal } from "../../../lib/form/BaseModal";

type Props = {
  onClose: () => void;
};

export const CloseAssignmentModal = ({ onClose }: Props) => {
  const [open, isOpen] = useState<boolean>(true);
  const { t } = useTranslation();

  return (
    <BaseModal
      open={open}
      icon={
        <FeaturedIcon color="success" size={20}>
          <Lock />
        </FeaturedIcon>
      }
      title={t("writingAssignmentPage.closeAssignmentModalTitle")}
      description={t("writingAssignmentPage.closeAssignmentModalContent")}
    >
      <Button
        onClick={() => {
          isOpen(false);
          onClose();
        }}
      >
        {t("writingAssignmentPage.closeAssignmentModalAction")}
      </Button>
    </BaseModal>
  );
};
