const formatSeconds = (seconds: number): string =>
  `${seconds < 10 ? "0" : ""}${seconds}`;

export const formatDuration = (timeInSecond: number): string => {
  const seconds = Math.floor(timeInSecond % 60);
  const minutes = Math.floor(timeInSecond / 60);

  return `${minutes}:${formatSeconds(seconds)}`;
};

export const formatTimerProgress = (current: number, limit: number) =>
  `${formatDuration(current)} / ${formatDuration(limit)}`;
