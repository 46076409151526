import { useEffect, useRef, useState } from "react";

import { AudioPlayerService } from "@/application/services/AudioPlayerService.ts";
import { useTimer } from "@/application/ui/lib/recording/hooks/useTimer.ts";

export const useAudioPlayer = (src?: string) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const play = () => {
    if (audioRef.current) {
      void audioRef.current.play();
      audioRef.current.onended = () => setIsPlaying(false);

      setIsPlaying(true);
    }
  };

  const pause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const reset = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    return AudioPlayerService.listenToDurationChange(src, setDuration);
  }, [src]);

  const timer = useTimer({
    active: isPlaying,
  });

  return {
    timer,
    play,
    pause,
    reset,
    durationInSeconds: duration,
    isPlaying,
    audioProps: {
      ref: audioRef,
      src,
      preload: "auto",
    },
  };
};
