import type { ForwardedRef } from "react";
import {
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
  forwardRef,
  type PropsWithChildren,
} from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: "contained" | "text" | "outlined" | "borderless";
  hierarchy?: "primary" | "secondary" | "error" | "success" | "neutral";
  size?: "default" | "xs" | "sm" | "md" | "lg" | "xl";
};

export const Button = forwardRef(
  (
    {
      variant = "contained",
      hierarchy = "primary",
      size = "default",
      type = "button",
      children,
      className,
      ...rest
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <StyledButton
        ref={ref}
        {...rest}
        className={`${variant} ${hierarchy} size-${size} ${className}`}
        type={type}
      >
        {children}
      </StyledButton>
    );
  },
);

const StyledButton = styled.button`
  border-radius: ${theme.radius.round};
  box-shadow: ${theme.shadowAndBlur.xsmall};
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: 2px solid transparent;

  &.text {
    box-shadow: none;
  }

  &.size-default {
    padding: 10px 16px;
    ${theme.typography.sm.medium}
  }

  &.size-xs {
    padding: 4px 8px;
    ${theme.typography.xs.medium}
  }

  &.size-sm {
    padding: 8px 14px;
    ${theme.typography.sm.medium}
  }

  &.size-md {
    padding: 10px 16px;
    ${theme.typography.sm.medium}
  }

  &.size-lg {
    padding: 10px 18px;
    ${theme.typography.md.medium}
  }

  &.size-xl {
    padding: 12px 20px;
    ${theme.typography.md.medium}
  }

  &:focus {
    box-shadow:
      0 1px 2px 0 rgba(16, 24, 40, 0.05),
      0 0 0 4px #f8f9fc;
  }

  &.contained,
  &.outlined {
    border-width: 1px;
    border-style: solid;
  }

  &.borderless {
    border: none;
  }

  &.success {
    border-color: ${theme.colors.success[500]};
    color: ${theme.colors.base.white};
    background: ${theme.colors.success[600]};

    &:hover {
      background: ${theme.colors.success[700]};
    }
  }

  &.error {
    border-color: ${theme.colors.error[300]};
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.error[600]};

    &.text {
      color: ${theme.colors.error[700]};
      background-color: transparent;
    }

    &:hover {
      background: ${theme.colors.error[50]};
    }
  }

  &.neutral {
    background-color: ${theme.colors.base.white};
    border-color: ${theme.colors.gray[200]};
    color: ${theme.colors.base.black};

    &:hover {
      background-color: ${theme.colors.gray[50]};
    }

    &:disabled {
      background-color: inherit;
      color: ${theme.colors.gray[300]};
      cursor: auto;

      &:hover {
        color: ${theme.colors.gray[300]};
      }
    }

    &.text {
      border: none;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        color: ${theme.colors.gray[500]};
      }

      &:disabled {
        cursor: auto;
        background-color: inherit;
        color: ${theme.colors.gray[300]};

        &:hover {
          color: ${theme.colors.gray[300]};
        }
      }
    }
  }

  &.primary {
    background: ${theme.colors.primary[700]};
    color: ${theme.colors.base.white};

    &:hover {
      background: ${theme.colors.primary[900]};
    }

    &:disabled {
      background: ${theme.colors.primary[50]};
      color: ${theme.colors.gray[400]};
      cursor: auto;
    }
  }

  &.secondary {
    background-color: ${theme.colors.primary[100]};
    color: ${theme.colors.primary[700]};

    &:hover {
      background-color: ${theme.colors.primary[50]};
    }

    &:disabled {
      background-color: ${theme.colors.primary[25]};
      color: ${theme.colors.grayBlue[200]};
      cursor: auto;
    }

    &.text {
      border: none;
      color: ${theme.colors.primary[700]};
      background-color: transparent;
      box-shadow: none;
      font-weight: ${theme.fontWeight.semibold};

      &:hover {
        color: ${theme.colors.primary[900]};
      }
    }
  }
`;
