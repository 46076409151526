export class PersonName {
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
  ) {}

  fullNameReversed() {
    return [this.lastName, this.firstName].filter(Boolean).join(", ");
  }

  fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(" ");
  }
}
