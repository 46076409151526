import words from "lodash/words";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { WritingTask } from "@/application/domain/writingTask.ts";
import type { ConfirmationDialogRef } from "@/application/ui/lib/form/ConfirmationDialog.tsx";
import { ConfirmationDialog } from "@/application/ui/lib/form/ConfirmationDialog.tsx";
import { FeaturedIcon } from "@/application/ui/lib/icon/FeaturedIcon.tsx";
import { LoadingSpinner } from "@/application/ui/lib/spinner/LoadingSpinner.tsx";
import { InformationPanel } from "@/application/ui/pages/WriteAssignment/components/InformationPanel.tsx";
import { WriteAssignmentFormValue } from "@/application/ui/pages/WriteAssignment/WriteAssignmentFormValue.ts";
import { ReactComponent as SendIcon } from "@/assets/send.svg";

import { Button } from "../../lib/button/Button";
import { theme } from "../../theme/theme";

interface Props {
  content?: string;
  onSubmit: (content: string) => void;
  setStorageItem: (content: string) => void;
  submitting: boolean;
  writingTask: WritingTask;
}

export const WriteAssignmentForm = ({
  submitting,
  onSubmit,
  content,
  writingTask,
  setStorageItem,
}: Props) => {
  const { t } = useTranslation();
  const confirmationDialogRef = useRef<ConfirmationDialogRef>(null);
  const [nbWords, setNbWords] = useState(words(content ?? "").length);
  const [writeAssignmentFormValue, setWriteAssignmentFormValue] = useState(
    WriteAssignmentFormValue.init(content),
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleSubmit = () => {
    confirmationDialogRef.current?.display();
  };

  const textarea = document.getElementById("content");
  textarea?.addEventListener("keydown", function (e) {
    if ([" ", ".", ",", "!", "?", "Backspace", "Delete"].includes(e.key)) {
      const calculatedWord = words(this.textContent ?? "").length;
      setNbWords(() => {
        return calculatedWord;
      });
      setStorageItem(this.textContent ?? "");
    }
  });

  const handleCharacterClick = (character: string) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      textarea.setRangeText(character, start, end, "end");
      textarea.focus();
    }
  };

  const buttonContent = submitting ? <LoadingSpinner /> : <>{t("submit")}</>;

  return (
    <>
      <LayoutContainer>
        <Top>
          <SaveDisclaimer>
            {t("writingAssignmentPage.saveDisclaimer")}
          </SaveDisclaimer>
        </Top>

        <AssignmentTextArea
          ref={textareaRef}
          role="textbox"
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
          id="content"
          spellCheck={false}
          placeholder={t("writingAssignmentPage.textPlaceholder")}
          value={writeAssignmentFormValue.content}
          onChange={(event) =>
            setWriteAssignmentFormValue(
              writeAssignmentFormValue.setContent(event.target.value),
            )
          }
        />
        <CallToActionButton
          size="md"
          onClick={handleSubmit}
          disabled={!writeAssignmentFormValue.isSubmittable() || submitting}
        >
          {buttonContent}
        </CallToActionButton>
      </LayoutContainer>
      <InformationPanel
        numberOfWords={nbWords}
        writingTask={writingTask}
        onCharacterClick={handleCharacterClick}
      />
      <ConfirmationDialog
        icon={
          <FeaturedIcon color="warning" size={20}>
            <SendIcon />
          </FeaturedIcon>
        }
        title={t("writingAssignmentConfirmationDialog.title")}
        description={t("writingAssignmentConfirmationDialog.description")}
        confirmLabel={t("yes")}
        cancelLabel={t("cancel")}
        ref={confirmationDialogRef}
        onConfirm={() => {
          onSubmit(writeAssignmentFormValue.content);
        }}
      />
    </>
  );
};

const SaveDisclaimer = styled.p`
  ${theme.typography.xs.regular};
  color: ${theme.colors.gray[500]};
`;

const Top = styled.div`
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.gray[50]};
`;

const LayoutContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${theme.colors.base.white};
`;

const AssignmentTextArea = styled.textarea`
  width: 80%;
  margin-top: 40px;
  height: 100%;
  padding: 12px 14px;
  border-radius: ${theme.radius.medium};
  border: ${theme.borders.primary};
  background: ${theme.colors.base.white};
  ${theme.typography.md.regular}
  box-shadow: ${theme.shadowAndBlur.xsmall};
  &:focus {
    outline-color: ${theme.colors.primary[300]};
  }
`;

const CallToActionButton = styled(Button)`
  width: 30%;
  margin-bottom: 5rem;
`;
