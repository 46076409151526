import type { StudentWritingTaskContentResponse } from "@api/StudentWritingTaskContentResponse.ts";
import { useQuery } from "@tanstack/react-query";

import { StudentWritingTaskClient } from "@/application/client/StudentWritingTaskClient.ts";

export const useGetStudentWritingTaskContent = (
  writingTaskId: string | undefined,
) => {
  const { data, isFetching, error } = useQuery<
    StudentWritingTaskContentResponse,
    Error
  >(
    ["student", "writing-tasks", writingTaskId],
    () =>
      StudentWritingTaskClient.fetchStudentWritingTaskContent(writingTaskId!),
    {
      enabled: !!writingTaskId,
    },
  );

  return { data, isFetching, error };
};
