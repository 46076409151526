import { Category } from "@api/mistakes/domain/model/Category.ts";
import clsx from "clsx";

import { NormalText } from "@/application/ui/pages/Revision/components/TextCorrector/segments/NormalText.tsx";
import { Revision } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import {
  Container,
  InvisibleRevised,
  Mark,
  RevisionBlock,
  RevisionContainer,
} from "./StyledText";

interface RevisedTextProps {
  level?: number;
  revision: Revision;
  onChange: (revision: Revision) => void;
  readOnly: boolean;
  isUnpenalizesMode?: boolean;
  displayRevision?: boolean;
  isCommentMode: boolean;
}

export const UnpenalizedText = ({
  level = 0,
  revision,
  onChange,
  readOnly,
  isUnpenalizesMode,
  displayRevision,
  isCommentMode,
}: RevisedTextProps) => {
  const style = {
    textUnderlineOffset: `${6 - 5 * level}px`,
  };

  return (
    <RevisionContainer>
      <RevisionBlock>
        {displayRevision && (
          <InvisibleRevised
            className={`${revision.penalized ? revision.category : Category.UNPENALIZED} ${revision.category} ${
              revision.penalized ? "penalized" : "not-penalized"
            }`}
          >
            {revision.revisedText}
          </InvisibleRevised>
        )}
        <Container className={clsx({ isClickable: isUnpenalizesMode })}>
          <span
            onClick={() => {
              onChange(revision.togglePenalisation());
            }}
          >
            <Mark
              style={style}
              className={`${revision.category} ${
                revision.penalized ? "penalized" : "not-penalized"
              } ${isCommentMode ? "isCommentMode" : ""}`}
              role="comment"
              aria-label={revision.toString()}
              id={`revision-${revision.id}`}
            >
              {revision.segments.map((segment, index) => {
                if (segment instanceof Revision) {
                  return (
                    <UnpenalizedText
                      level={level + 1}
                      key={segment.id}
                      revision={segment}
                      onChange={(_revision) =>
                        onChange(revision.replaceAt(index, _revision))
                      }
                      readOnly={readOnly}
                      isCommentMode={isCommentMode}
                    />
                  );
                }

                return (
                  <NormalText
                    key={`${revision.id}-segment-${index}`}
                    text={segment}
                    onSelect={() => {}}
                  />
                );
              })}
            </Mark>
          </span>
        </Container>
      </RevisionBlock>
    </RevisionContainer>
  );
};
