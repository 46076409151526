import type { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { NavBar } from "@/application/ui/layout/NavBar.tsx";

export function MainLayout({ children }: PropsWithChildren) {
  return (
    <div style={{ display: "flex" }}>
      <NavBar />

      <Main>{children ? children : <Outlet />}</Main>
    </div>
  );
}

const Main = styled.main`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex: 1;
`;
