import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../../theme/theme";

interface Props {
  status: string;
}

export const StudentWritingTaskStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={clsx(status)}>
      <Typography>{t(`studentWritingTaskStatus.${status}`)}</Typography>
    </Container>
  );
};

const Container = styled.span`
  border-radius: ${theme.radius.round};
  padding: 4px 12px 4px 12px;

  &.wait_for_revision {
    background-color: ${theme.colors.warning[50]};
    color: ${theme.colors.warning[700]};
  }
  &.reviewed {
    background-color: ${theme.colors.success[50]};
    color: ${theme.colors.success[700]};
  }
`;

const Typography = styled.span`
  ${theme.typography.sm.medium}
`;
