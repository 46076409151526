import { useQuery } from "@tanstack/react-query";

import { ReportDashboardClient } from "@/application/client/reportDashboardClient";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useReportDashboard = () => {
  const {
    data: reportDashboard,
    isError,
    isFetching,
  } = useQuery({
    queryKey: TeacherQueryKeys.reportDashboard,
    queryFn: () => ReportDashboardClient.fetchGroups(),
    retry: false,
  });
  return { reportDashboard, isError, isFetching };
};
