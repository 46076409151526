import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { WritingTaskGroupSummaryClient } from "../../../../client/WritingTaskGroupSummaryClient.ts";

export const useGroupWritingTask = (writingTaskId?: string) => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.writingTaskSummary(writingTaskId),
    queryFn: () =>
      WritingTaskGroupSummaryClient.getForWritingTask(writingTaskId || ""),
    enabled: !!writingTaskId,
  });

  return data;
};
