import { useEffect, useRef } from "react";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";

import { theme } from "../../../../theme/theme";
import { CommentPopupViewMode } from "./CommentPopupViewMode";

type Props = {
  readOnly: boolean;
  comment: TeacherComment;
  onDelete: () => void;
  onChange: (comment: TeacherComment) => void;
  onClose: () => void;
};

export const CommentPopup = ({
  readOnly,
  comment,
  onDelete,
  onChange,
  onClose,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const saveComment = (commentText: string) => {
    comment.text = commentText;
    onChange(comment);
    onClose();
  };

  const deleteComment = () => {
    onDelete();
    onClose();
  };

  return (
    <Container ref={ref} role="dialog">
      <CommentPopupViewMode
        readOnly={readOnly}
        comment={comment}
        onDeleteComment={deleteComment}
        onSave={saveComment}
        onClose={onClose}
      />
    </Container>
  );
};

const Container = styled.div`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.xlarge};
  width: 500px;

  &.view {
    min-width: 8.8rem;
    max-width: 44.81rem;
  }

  &.edit {
    min-width: 5px;
    max-width: 44.81rem;
  }
`;
