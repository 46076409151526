import { useMutation } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import type { AssignmentEvent } from "@/application/domain/AssignmentEvent.ts";

type SaveAssignmentProps = {
  content: string;
  writingTaskId: string;
  events: AssignmentEvent[];
};

export const useSaveWIPAssignment = () => {
  const { mutate } = useMutation((props: SaveAssignmentProps) =>
    AssignmentClient.saveWIPAssignment(
      props.content,
      props.writingTaskId,
      props.events,
    ),
  );

  return {
    saveWIPAssignment: mutate,
  };
};
