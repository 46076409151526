import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import { useState } from "react";

export enum Criterias {
  C1,
  C2,
  C3,
  C4,
  C5,
}

export const useCriteriaStatus = (selectedCriteria?: EvaluationCriteria) => {
  const [C1, setC1] = useState<boolean>(selectedCriteria?.C1 ?? true);
  const [C2, setC2] = useState<boolean>(selectedCriteria?.C2 ?? true);
  const [C3, setC3] = useState<boolean>(selectedCriteria?.C3 ?? true);
  const [C4, setC4] = useState<boolean>(selectedCriteria?.C4 ?? true);
  const [C5, setC5] = useState<boolean>(selectedCriteria?.C5 ?? true);

  const getCriteria = (c: Criterias) => {
    if (c === Criterias.C1) {
      return C1;
    } else if (c === Criterias.C2) {
      return C2;
    } else if (c === Criterias.C3) {
      return C3;
    } else if (c === Criterias.C4) {
      return C4;
    } else if (c === Criterias.C5) {
      return C5;
    }
    throw new Error("Wrong parameters");
  };

  const toggleCriteria = (c: Criterias) => {
    if (c === Criterias.C1) {
      setC1(!C1);
    } else if (c === Criterias.C2) {
      setC2(!C2);
    } else if (c === Criterias.C3) {
      setC3(!C3);
    } else if (c === Criterias.C4) {
      setC4(!C4);
    } else if (c === Criterias.C5) {
      setC5(!C5);
    }
  };

  const setToDefaultState = () => {
    setC1(true);
    setC2(true);
    setC3(true);
    setC4(true);
    setC5(true);
  };

  return {
    getCriteria,
    toggleCriteria,
    setToDefaultState,
  };
};
