import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';
import { SecondaryTwoOrthographyValuedGradeMapper } from './SecondaryTwoOrthographyValuedGradeMapper';

export class SecondaryTwoOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryTwoOrthographyValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryTwoOrthographyValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);

    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio <= 0.6:
        return Grade.of('A+');
      case orthographyErrorWordRatio <= 2.49:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 4.49:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 6.49:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 9.49:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 12.49:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
