import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { TeacherDashboardClient } from "@/application/client/TeacherDashboardClient.ts";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

interface PublishAssignment {
  writingTaskId: string;
  assignmentId: string;
}

export function usePublishAnAssignment() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (publishAssignment: PublishAssignment) =>
      TeacherDashboardClient.publishAnAssignment(
        publishAssignment.writingTaskId,
        publishAssignment.assignmentId,
      ),

    {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        await queryClient.invalidateQueries(
          TeacherQueryKeys.writingTaskSummary(variables.writingTaskId),
        );
        ToastService.showSuccess(t("writingTaskPublishSucceed"));
      },
      onError: () => {
        ToastService.showError(t("failToPublishWritingTask"));
      },
    },
  );
  return { publish: mutate, publishing: isLoading };
}
