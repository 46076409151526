import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";

import { theme } from "../../../../../theme/theme";

const MINIMUM_ROWS = 4;
const LINE_HEIGHT = 20;

type CommentTextAreaProps = {
  comment: string;
  onClearComment?: () => void;
  onChange?: (comment: string) => void;
  readonly?: boolean;
};

export const CommentTextArea = ({
  comment,
  onClearComment,
  onChange,
  readonly = false,
}: CommentTextAreaProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SidebarBox title={t("comment")}>
        {!readonly && (
          <TextArea
            aria-label="comment-textbox"
            placeholder={t("commentTextAreaPlaceholder")}
            value={comment}
            rows={MINIMUM_ROWS}
            onChange={(event) => onChange?.(event.target.value)}
          />
        )}

        {readonly && comment.trim() !== "" && (
          <TextArea
            disabled={readonly}
            aria-label="comment-textbox"
            placeholder={t("commentTextAreaPlaceholder")}
            value={comment}
            rows={MINIMUM_ROWS}
            onChange={(event) => onChange?.(event.target.value)}
          />
        )}

        {readonly && comment.trim() === "" && (
          <EmptyCommentState>{t("noComment")}</EmptyCommentState>
        )}
      </SidebarBox>
      {!readonly && (
        <Button
          onClick={() => onClearComment?.()}
          hierarchy="secondary"
          variant="text"
        >
          {t("clear")}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 100%;
`;

const TextArea = styled.textarea`
  flex: 1;
  border: none;
  color: ${theme.colors.text.primary};
  ${theme.typography.sm.regular}
  line-height: ${LINE_HEIGHT}px;
  text-align: left;
  max-height: 11.25rem;
  resize: vertical;
  min-height: ${MINIMUM_ROWS * LINE_HEIGHT}px;
  background: transparent;

  &:focus {
    outline: none;

    &::placeholder {
      color: ${theme.colors.gray[500]};
    }
  }
`;

const EmptyCommentState = styled.div`
  border: none;
  color: ${theme.colors.gray[500]};
  ${theme.typography.sm.regular}
  text-align: center;
`;
