import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { Card } from "@/application/ui/lib/Card.tsx";
import { theme } from "@/application/ui/theme/theme.ts";

interface Props {
  color: "white" | "gray";
}

export const TaskCard = ({ children, color }: PropsWithChildren<Props>) => {
  return (
    <Container
      color={
        color === "white" ? theme.colors.base.white : theme.colors.gray[50]
      }
    >
      {children}
    </Container>
  );
};

const Container = styled(Card)`
  ${({ color }) => `
     background-color: ${color};
  `};
  flex-direction: column;
  width: 356px;
  height: 350px;
  padding: 16px;
  justify-content: space-between;
  gap: 18px;
`;

TaskCard.Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
`;

TaskCard.Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
TaskCard.Footer = styled.div`
  display: flex;
  flex-direction: column;
`;
