import type { Ref } from "react";
import { forwardRef } from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import type { ButtonProps } from "./Button";

export const ToggleButton = forwardRef(
  ({ children, ...rest }: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      <StyledButton ref={ref as any} {...rest}>
        {children}
      </StyledButton>
    );
  },
);

const StyledButton = styled.button`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  ${theme.typography.sm.medium}
  gap: 0.5rem;
  cursor: pointer;
  border-radius: ${theme.radius.small};
  background: ${theme.colors.base.white};
  box-shadow: 0 0 0 1px ${theme.colors.gray[200]};

  &:hover {
    background: ${theme.colors.gray[50]};
  }

  &[disabled] {
    background: ${theme.colors.primary[25]};
    box-shadow: 0 0 0 2px ${theme.colors.primary[400]};
    pointer-events: none;
  }
`;
