import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ToggleButton } from "@/application/ui/lib/button/ToggleButton";
import type { View } from "@/application/ui/pages/Revision/components/ToggleView/useToggleView.tsx";

type ToggleViewProps = {
  toggleMode: View;
  onToggle: () => void;
};

const ToggleView = ({ toggleMode, onToggle }: ToggleViewProps) => {
  const { t } = useTranslation();

  return (
    <ToggleSwitchContainer aria-label="Toggle Switch">
      <ToggleButton disabled={toggleMode === "original"} onClick={onToggle}>
        {t("original")}
      </ToggleButton>
      <ToggleButton disabled={toggleMode === "revised"} onClick={onToggle}>
        {t("revised")}
      </ToggleButton>
    </ToggleSwitchContainer>
  );
};

const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default ToggleView;
