import { NumberRange } from '../../../../global/domain/model/NumberRange';
import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import { TotalNumberCountBasedGradeMatrix } from '../../../model/TotalNumberCountBasedGradeMatrix';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';
import { SecondaryFiveOrthographyValuedGradeMapper } from '../secondaryFive/SecondaryFiveOrthographyValuedGradeMapper';

export class SecondaryFiveMinistryOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryFiveOrthographyValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryFiveOrthographyValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const errorCount = this.computeErrorCount(textWithMistakes);
    const totalWordCount = textWithMistakes.countWords();

    return this.valuedGradeMapper.create(
      this.findMatchingGrade(totalWordCount, errorCount),
    );
  }

  private findMatchingGrade(
    totalWordCount: number,
    orthographyErrorCount: number,
  ): Grade {
    return secondaryFiveMinistryOrthographyGradeMatrix.determineGrade(
      totalWordCount,
      orthographyErrorCount,
    );
  }
}

const secondaryFiveMinistryOrthographyGradeMatrix =
  new TotalNumberCountBasedGradeMatrix(
    new Map([
      [
        new NumberRange(0, null),
        new Map([
          [new NumberRange(0, 0), Grade.of('A+')],
          [new NumberRange(1, 2), Grade.of('A')],
          [new NumberRange(3, 4), Grade.of('A-')],
          [new NumberRange(5, 6), Grade.of('B+')],
          [new NumberRange(7, 8), Grade.of('B')],
          [new NumberRange(9, 9), Grade.of('B-')],
          [new NumberRange(10, 11), Grade.of('C+')],
          [new NumberRange(12, 13), Grade.of('C')],
          [new NumberRange(14, 14), Grade.of('C-')],
          [new NumberRange(15, 15), Grade.of('D+')],
          [new NumberRange(16, 16), Grade.of('D')],
          [new NumberRange(17, 17), Grade.of('D-')],
          [new NumberRange(18, 18), Grade.of('D--')],
          [new NumberRange(19, 19), Grade.of('E+')],
          [new NumberRange(20, 20), Grade.of('E')],
          [new NumberRange(21, 21), Grade.of('E-')],
          [new NumberRange(22, null), Grade.of('E--')],
        ]),
      ],
    ]),
  );
