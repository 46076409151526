import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ChevronRight } from "@/assets/chevron-right.svg";

import { theme } from "../theme/theme";

interface Props {
  entries: { label: string; link?: string }[];
}
export const Breadcrumbs = ({ entries }: Props) => {
  return (
    <Bread>
      {entries.map((entry, index) => (
        <React.Fragment key={entry.label}>
          {index !== 0 && <ColoredChevronRight />}

          <Crumb
            className={clsx({ active: index === entries.length - 1 })}
            as={index === entries.length - 1 || !entry.link ? "span" : Link}
            to={entry.link}
          >
            {entry.label}
          </Crumb>
        </React.Fragment>
      ))}
    </Bread>
  );
};

const Bread = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ColoredChevronRight = styled(ChevronRight)`
  width: 16px;
  height: 16px;
  color: ${theme.colors.gray[300]};
`;

const Crumb = styled.span`
  ${theme.typography.sm.regular}
  padding: 4px 8px;

  &.active {
    font-weight: ${theme.fontWeight.semibold};
  }
`;
