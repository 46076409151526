import { useState } from "react";
import styled from "styled-components";

import { theme } from "../../../theme/theme";
import type { Character } from "./Characters";
import { casingCharacter, characters } from "./Characters";

interface Props {
  insertCharacter: (character: string) => void;
}

export const CharacterKeyboard = ({ insertCharacter }: Props) => {
  const [isUppercase, setIsUppercase] = useState(false);

  const handleClick = (character: Character) => {
    if (isUppercase) {
      insertCharacter(character.uppercase);
    } else {
      insertCharacter(character.lowercase);
    }
  };

  const getCharacterCasing = (character: Character) => {
    return isUppercase ? character.uppercase : character.lowercase;
  };

  return (
    <Container>
      {characters.map((character, index) => (
        <div key={`heyboardCharacter${index}-${isUppercase}`}>
          <Button onClick={() => handleClick(character)}>
            {getCharacterCasing(character)}
          </Button>
        </div>
      ))}
      <Button
        onClick={() => setIsUppercase(!isUppercase)}
        style={{ flexGrow: 1 }}
      >
        {getCharacterCasing(casingCharacter)}
      </Button>
    </Container>
  );
};

const Button = styled.button`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: ${theme.radius.medium};
  background-color: ${theme.colors.gray[50]}
  color: ${theme.colors.gray[600]}
  flex: 0 0 calc(100% / 9);

  &:hover {
    border: 1px solid ${theme.colors.gray[400]};
    background-color: ${theme.colors.gray[100]};
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0 6px;
`;
