import "react-tooltip/dist/react-tooltip.css";

import type { GradeLetter } from "@emilia/backend/src/common/grade/model/Grade";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { ConsultationAssignment } from "@/application/domain/Assignment.ts";
import { TOOLTIP_ELEMENT_ID } from "@/application/ui/lib/tooltip/StyledTooltip.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";

import { theme } from "../../../../theme/theme";
import { DetailedGradingScale } from "./DetailedGradingScale";

const possibleGrades: GradeLetter[] = ["A", "B", "C", "D", "E"];

const possibleCriteria: Set<EvaluationCriterion> = new Set<EvaluationCriterion>(
  [
    EvaluationCriterion.ADAPTATION,
    EvaluationCriterion.COHERENCE,
    EvaluationCriterion.VOCABULARY,
    EvaluationCriterion.SYNTAX,
    EvaluationCriterion.ORTHOGRAPHY,
  ],
);

type GradingScaleProps = {
  assignment?: ConsultationAssignment;
};

export const ReadOnlyGradingScale = ({ assignment }: GradingScaleProps) => {
  const { t } = useTranslation();
  const action = assignment?.gradingScaleType && (
    <DetailedGradingScale gradingScaleType={assignment?.gradingScaleType} />
  );

  return (
    <SidebarBox title={t("gradingScale")} action={action}>
      <Grid>
        <GridRow className="heading">
          <GridCell></GridCell>
          <GridCell>A</GridCell>
          <GridCell>B</GridCell>
          <GridCell>C</GridCell>
          <GridCell>D</GridCell>
          <GridCell>E</GridCell>
        </GridRow>
        {[...possibleCriteria].map((criterion) => (
          <GridRow
            key={criterion}
            aria-disabled
            data-tooltip-id={TOOLTIP_ELEMENT_ID}
            data-tooltip-hidden
            data-tooltip-content={t("calculatedGradeTooltipMessage")}
            data-tooltip-place="bottom"
            className={
              !assignment?.gradedCriteria[criterion] ? "not-selected" : ""
            }
          >
            <CriterionGridCell role="gridcell">
              {t(criterion)}
            </CriterionGridCell>

            {possibleGrades.map((possibleGrade) => (
              <GradingCell
                key={criterion + possibleGrade}
                role="gridcell"
                aria-label={`${criterion}-${possibleGrade}`}
                aria-disabled
                aria-selected={
                  assignment?.gradedCriteria[criterion]?.letter ===
                  possibleGrade
                }
              />
            ))}
          </GridRow>
        ))}
      </Grid>
    </SidebarBox>
  );
};

const Grid = styled.div`
  flex: 1;
  color: ${theme.colors.gray[500]};
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr);
  height: 32px;
  background-color: ${theme.colors.base.white};
  transition: background-color 0.5s ease-out;

  &.heading ~ div {
    border-top: 1px solid ${theme.colors.gray[300]};
  }
  padding: 2px;

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  &[aria-disabled="false"] > [aria-selected="false"]:hover {
    cursor: pointer;
    background-color: ${theme.colors.gray[100]};
  }

  &.not-selected {
    background: ${theme.colors.primary[50]};
  }
`;

const CriterionGridCell = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
`;

const GridCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GradingCell = styled.div`
  border-radius: ${theme.radius.medium};
  &[aria-selected="true"] {
    background-color: ${theme.colors.primary[300]};
  }
`;
