import type { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import {
  assembleChartData,
  assembleChartOptions,
} from "./CriteriaResultChartCommon";

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

type GradeResult = {
  grade: number;
  criterion: EvaluationCriterion;
};

interface Props {
  results: GradeResult[];
  average: number;
  selectedCriteria: string;
  setSelectedCriteria: (criteria: string) => void;
}

export const CriteriaResultChartByPercentage = ({
  results,
  average,
  selectedCriteria,
  setSelectedCriteria,
}: Props) => {
  const { t } = useTranslation();

  const criteriaColors = results.map((result) => {
    if (
      selectedCriteria === "all" ||
      selectedCriteria === result.criterion.toString()
    ) {
      if (result.grade >= average) {
        return theme.colors.indigo[400];
      } else {
        return theme.colors.error[400];
      }
    } else {
      return theme.colors.gray[300];
    }
  });

  const labels = results.map((x) => t(x.criterion));
  const values = results.map((result) => result.grade);

  const data = assembleChartData(labels, values, criteriaColors);

  const tooltips = results.map((x) => x.grade.toString() + "%");
  const criterion = results.map((x) => x.criterion);
  const yScale = {
    border: {
      display: false,
    },
    title: {
      display: true,
      text: t("relativePercentage"),
    },
    max: 100,
    suggestedMin: 0,
    beginAtZero: false,
    ticks: {
      stepSize: 5,
    },
    grid: {
      color: theme.colors.gray[100],
    },
  };
  const options = assembleChartOptions(
    tooltips,
    `${average}%`,
    average,
    yScale,
    criterion,
    setSelectedCriteria,
  );

  return (
    <ChartContainer>
      <Bar data={data} options={options} style={{ maxWidth: "100%" }} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  flex: 1;
  height: 100%;
`;
