import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import styled from "styled-components";

import { ReactComponent as ErrorSummaryPunctuationIcon } from "@/assets/error-summary-punctuation-icon.svg";
import { ReactComponent as ErrorSummarySyntaxIcon } from "@/assets/error-summary-syntax-icon.svg";

import { theme } from "../../theme/theme";
import { UnpenalizedIcon } from "./components/Sidebar/ErrorSummary/UnpenalizedRevision";

type CategoryIconProps = {
  category: Category;
};

export const CategoryIcon = ({ category }: CategoryIconProps) => {
  return (
    <StyledCategoryIcon className={category}>
      {category === Category.PUNCTUATION && <ErrorSummaryPunctuationIcon />}
      {category === Category.SYNTAX && <ErrorSummarySyntaxIcon />}
      {category === Category.UNPENALIZED && <UnpenalizedIcon />}
    </StyledCategoryIcon>
  );
};

const StyledCategoryIcon = styled.div`
  &.spelling {
    background-color: ${theme.colors.category.spelling};
    width: 8px;
    height: 8px;
  }

  &.grammar {
    background-color: ${theme.colors.category.grammar};
    width: 8px;
    height: 8px;
  }

  &.vocabulary {
    background-color: ${theme.colors.category.vocabulary};
    width: 8px;
    height: 8px;
  }
`;
