export class AudioRecord {
  public readonly EXTENSION = "wav";
  public static readonly AUDIO_TYPE = "audio/wav";

  public url: string;

  constructor(public blob: Blob) {
    this.url = URL.createObjectURL(blob);
  }
}
