import type { Grade } from "@emilia/backend/src/common/grade/model/Grade";
import type { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import {
  assembleChartData,
  assembleChartOptions,
} from "./CriteriaResultChartCommon";

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

type GradeResult = {
  grade: Grade;
  criterion: EvaluationCriterion;
};

interface Props {
  results: GradeResult[];
  average: Grade;
  selectedCriteria: string;
  setSelectedCriteria: (criteria: string) => void;
}

export const CriteriaResultChartByGrade = ({
  results,
  average,
  selectedCriteria,
  setSelectedCriteria,
}: Props) => {
  const { t } = useTranslation();

  const possibleGrades = ["E", "D", "C", "B", "A"];

  const criteriaColors = results.map((result) => {
    if (
      selectedCriteria === "all" ||
      selectedCriteria === result.criterion.toString()
    ) {
      if (result.grade.compare(average)) {
        return theme.colors.indigo[400];
      } else {
        return theme.colors.error[400];
      }
    } else {
      return theme.colors.gray[300];
    }
  });

  const labels = results.map((x) => t(x.criterion));
  const values = results.map((result) => result.grade.getGradeValue());
  const data = assembleChartData(labels, values, criteriaColors);

  const tooltips = results.map((x) => x.grade.toString());
  const criterion = results.map((x) => x.criterion);
  const yScale = {
    border: {
      display: false,
    },
    title: {
      display: true,
      text: t("grade"),
    },
    suggestedMax: 13,
    min: 0,
    beginAtZero: false,
    ticks: {
      stepSize: 1,
      callback: (val: string | number) => {
        val = Number(val);
        if (val === 1 || (val % 3 === 0 && val !== 0)) {
          return " " + possibleGrades[Math.floor(val / 3)];
        }
        return "";
      },
    },
    grid: {
      color: theme.colors.gray[100],
    },
  };
  const options = assembleChartOptions(
    tooltips,
    average.toString(),
    average.getGradeValue(),
    yScale,
    criterion,
    setSelectedCriteria,
  );

  return (
    <ChartContainer>
      <Bar data={data} options={options} style={{ maxWidth: "100%" }} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  flex: 1;
  height: 100%;
`;
