import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import type { ImportUsersFieldValues } from "@/application/ui/pages/ImportUsers/ImportUsersPage.tsx";

export const ImportUserClient = {
  importUser: async (data: ImportUsersFieldValues) => {
    const formData = new FormData();
    data.groups?.[0] && formData.append("groups", data.groups[0]);
    data.teachers?.[0] && formData.append("teachers", data.teachers[0]);
    data.students?.[0] && formData.append("students", data.students[0]);
    await AuthenticatedHttpClient.post(`/user-import`, formData);
  },
};
