import type { PropsWithChildren } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ReactComponent as AlertCircleIcon } from "@/assets/alert-circle.svg";

import { theme } from "../../theme/theme";

const Container = (props: PropsWithChildren) => <div>{props.children}</div>;

export class ToastService {
  static showSuccess(title: string, message?: string) {
    toast.success(
      <Container>
        <StyledToastContent>
          <StyledAlertCircleIcon className="success" />
          <ToastTextContent>
            <ToastTitle>{title}</ToastTitle>
            <ToastMessage>{message}</ToastMessage>
          </ToastTextContent>
        </StyledToastContent>
      </Container>,
      {
        hideProgressBar: true,
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT,
        className: "success",
      },
    );
  }

  static showError(title: string, message?: string) {
    toast.error(
      <Container>
        <StyledToastContent>
          <StyledAlertCircleIcon className="error" />
          <ToastTitle>{title}</ToastTitle>
          <ToastMessage>{message}</ToastMessage>
        </StyledToastContent>
      </Container>,
      {
        autoClose: false,
        position: toast.POSITION.BOTTOM_LEFT,
        className: "error",
        hideProgressBar: true,
      },
    );
  }
}

export const StyledToastContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ToastTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const ToastTitle = styled.p`
  ${theme.typography.sm.medium};
`;

export const ToastMessage = styled.p`
  ${theme.typography.sm.regular};
`;

export const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  padding-right: 8px;
  width: 20px;
  height: 20px;

  &.success {
    stroke: ${theme.colors.success[700]};
  }

  &.error {
    stroke: ${theme.colors.error[700]};
  }
`;
