import { useQuery } from "@tanstack/react-query";

import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";
import { RevisionsStateClient } from "@/application/ui/pages/RevisionRequest/RevisionsStateClient.ts";

export const useRevisionsState = () => {
  const { data } = useQuery({
    queryKey: TeacherQueryKeys.revisionsState,
    queryFn: () => RevisionsStateClient.getAll(),
  });
  return { data };
};
