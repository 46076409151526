import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import type { Evaluation } from "@emilia/backend/src/common/grade/globalGrade/GlobalGradeComputingService";
import { GlobalGradeComputingService } from "@emilia/backend/src/common/grade/globalGrade/GlobalGradeComputingService";
import type { GradeLetter } from "@emilia/backend/src/common/grade/model/Grade";
import { Grade } from "@emilia/backend/src/common/grade/model/Grade";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import type { ValuedGrade } from "@emilia/backend/src/common/grade/model/ValuedGrade";
import { ValuedGradeMapperFactory } from "@emilia/backend/src/common/grade/model/valuedGradeFactory/ValuedGradeMapperFactory";
import { OrthographyGradeComputingStrategyFactory } from "@emilia/backend/src/common/grade/orthographyGrade/strategies/OrthographyGradeComputingStrategyFactory";
import { SyntaxGradeComputingStrategyFactory } from "@emilia/backend/src/common/grade/syntaxGrade/strategies/SyntaxGradeComputingStrategyFactory";

import { RevisedAssignmentContentState } from "../../ui/pages/Revision/service/RevisedAssignmentContentState";
import type { Assignment } from "../Assignment";

export type ComputedEvaluation = {
  [EvaluationCriterion.SYNTAX]?: ValuedGrade;
  [EvaluationCriterion.ORTHOGRAPHY]?: ValuedGrade;
};

export class GradingScaleModel {
  constructor(
    public readonly type: GradingScaleType,
    private evaluation: Evaluation,
    private computedEvaluation: ComputedEvaluation,
  ) {}

  static init(
    assignment: Assignment | undefined,
    gradingScaleType: GradingScaleType,
  ) {
    const revisedAssignment = RevisedAssignmentContentState.init(assignment);

    return new GradingScaleModel(
      gradingScaleType,
      {
        [EvaluationCriterion.ADAPTATION]: this.mapGradeToValuedGrade(
          assignment,
          gradingScaleType,
          EvaluationCriterion.ADAPTATION,
        ),
        [EvaluationCriterion.VOCABULARY]: this.mapGradeToValuedGrade(
          assignment,
          gradingScaleType,
          EvaluationCriterion.VOCABULARY,
        ),
        [EvaluationCriterion.COHERENCE]: this.mapGradeToValuedGrade(
          assignment,
          gradingScaleType,
          EvaluationCriterion.COHERENCE,
        ),
        [EvaluationCriterion.SYNTAX]: this.mapGradeToValuedGrade(
          assignment,
          gradingScaleType,
          EvaluationCriterion.SYNTAX,
        ),
        [EvaluationCriterion.ORTHOGRAPHY]: this.mapGradeToValuedGrade(
          assignment,
          gradingScaleType,
          EvaluationCriterion.ORTHOGRAPHY,
        ),
      },
      GradingScaleModel.computeComputedEvaluation(
        gradingScaleType,
        revisedAssignment,
      ),
    );
  }

  static mapGradeToValuedGrade(
    assignment: Assignment | undefined,
    gradingScaleType: GradingScaleType,
    criterion: EvaluationCriterion,
  ) {
    const gradedCriterion: Grade | undefined =
      assignment?.gradedCriteria?.[criterion];
    return gradedCriterion
      ? ValuedGradeMapperFactory.withContext(
          gradingScaleType,
          criterion,
        ).create(gradedCriterion)
      : undefined;
  }

  updateCriterionManually(
    criterion: EvaluationCriterion,
    letterOnlyGrade: GradeLetter,
  ): GradingScaleModel {
    const valuedGrade = ValuedGradeMapperFactory.withContext(
      this.type,
      criterion,
    ).create(Grade.of(letterOnlyGrade));

    return new GradingScaleModel(
      this.type,
      { ...this.evaluation, [criterion]: valuedGrade },
      this.computedEvaluation,
    );
  }

  isGradeLetterForCriterion(
    criterion: EvaluationCriterion,
    gradeLetter: GradeLetter,
    evaluationCriteria: EvaluationCriteria,
  ): boolean {
    return (
      gradeLetter ===
      this.getFullEvaluation(evaluationCriteria)[criterion]?.grade?.letter
    );
  }

  private static computeOrthographyGrade(
    type: GradingScaleType,
    revisedAssignmentContent: RevisedAssignmentContentState,
  ): ValuedGrade {
    return OrthographyGradeComputingStrategyFactory.create(type).compute(
      revisedAssignmentContent,
    );
  }

  private static computeComputedEvaluation(
    type: GradingScaleType,
    revisedAssignmentContent: RevisedAssignmentContentState,
  ): ComputedEvaluation {
    return {
      [EvaluationCriterion.ORTHOGRAPHY]:
        GradingScaleModel.computeOrthographyGrade(
          type,
          revisedAssignmentContent,
        ),
      [EvaluationCriterion.SYNTAX]: GradingScaleModel.computeSyntaxGrade(
        type,
        revisedAssignmentContent,
      ),
    };
  }

  private static computeSyntaxGrade(
    type: GradingScaleType,
    revisedAssignmentContent: RevisedAssignmentContentState,
  ): ValuedGrade | undefined {
    const invalidGradingScales = [
      "third_year",
      "fourth_year",
      "fifth_year",
      "sixth_year",
    ];
    if (!invalidGradingScales.includes(type)) {
      return SyntaxGradeComputingStrategyFactory.create(type).compute(
        revisedAssignmentContent,
      );
    }

    return undefined;
  }

  updateComputedGrades(
    revisedAssignmentContent: RevisedAssignmentContentState,
  ): GradingScaleModel {
    return new GradingScaleModel(
      this.type,
      this.evaluation,
      GradingScaleModel.computeComputedEvaluation(
        this.type,
        revisedAssignmentContent,
      ),
    );
  }

  getGlobalGrade(evaluationCriteria: EvaluationCriteria): ValuedGrade | null {
    if (
      this.isCompletedEvaluation(
        this.getFullEvaluation(evaluationCriteria),
        evaluationCriteria,
      )
    ) {
      return new GlobalGradeComputingService(
        this.type,
        evaluationCriteria,
      ).compute(this.getFullEvaluation(evaluationCriteria));
    }

    return null;
  }
  getValuedGradeFor(
    criterion: EvaluationCriterion,
    evaluationCriteria: EvaluationCriteria,
  ): ValuedGrade | undefined {
    return this.getFullEvaluation(evaluationCriteria)[criterion];
  }

  private isCompletedEvaluation(
    evaluation: Evaluation,
    evaluationCriteria: EvaluationCriteria,
  ): boolean {
    return (
      ((evaluationCriteria.C1 &&
        !!evaluation[EvaluationCriterion.ADAPTATION]) ||
        !evaluationCriteria.C1) &&
      ((evaluationCriteria.C2 && !!evaluation[EvaluationCriterion.COHERENCE]) ||
        !evaluationCriteria.C2) &&
      ((evaluationCriteria.C3 &&
        !!evaluation[EvaluationCriterion.VOCABULARY]) ||
        !evaluationCriteria.C3) &&
      ((evaluationCriteria.C4 && !!evaluation[EvaluationCriterion.SYNTAX]) ||
        !evaluationCriteria.C4) &&
      ((evaluationCriteria.C5 &&
        !!evaluation[EvaluationCriterion.ORTHOGRAPHY]) ||
        !evaluationCriteria.C5)
    );
  }

  evaluationIsComplete(evaluationCriteria: EvaluationCriteria): boolean {
    return this.isCompletedEvaluation(
      this.getFullEvaluation(evaluationCriteria),
      evaluationCriteria,
    );
  }

  isGraded(
    criterion: EvaluationCriterion,
    evaluationCriteria: EvaluationCriteria,
  ): boolean {
    return !!this.getFullEvaluation(evaluationCriteria)[criterion];
  }

  getFullEvaluation(evaluationCriteria: EvaluationCriteria): Evaluation {
    const orthography = evaluationCriteria.C5
      ? {
          [EvaluationCriterion.ORTHOGRAPHY]:
            this.evaluation[EvaluationCriterion.ORTHOGRAPHY] ??
            this.computedEvaluation[EvaluationCriterion.ORTHOGRAPHY],
        }
      : {};

    const syntax = evaluationCriteria.C4
      ? {
          [EvaluationCriterion.SYNTAX]:
            this.evaluation[EvaluationCriterion.SYNTAX] ??
            this.computedEvaluation[EvaluationCriterion.SYNTAX],
        }
      : {};
    return {
      ...this.evaluation,
      ...orthography,
      ...syntax,
    };
  }
}
