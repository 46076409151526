import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { WritingTaskClient } from "@/application/client/WritingTaskClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { ToastService } from "../../../lib/toast/ToastService";

export const useDeleteDraftWritingTask = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deleteDraft } = useMutation({
    mutationFn: (writingTaskId: string) =>
      WritingTaskClient.deleteDraftTask(writingTaskId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
      ToastService.showSuccess(t("draftWritingTask.deletionModal.success"));
    },
    onError: () => {
      ToastService.showError(t("draftWritingTask.deletionModal.fail"));
    },
  });

  return { deleteDraft };
};
