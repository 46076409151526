import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';
import { SecondaryThreeOrthographyValuedGradeMapper } from './SecondaryThreeOrthographyValuedGradeMapper';

export class SecondaryThreeOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryThreeOrthographyValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryThreeOrthographyValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio <= 0.4:
        return Grade.of('A+');
      case orthographyErrorWordRatio <= 1.79:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 3.53:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 5.19:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 7.39:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 9.66:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
