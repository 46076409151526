import { cloneDeep } from "lodash";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";
import type { CorrectionMode } from "@/application/ui/pages/Revision/RevisionStates/AssignmentsProvider.tsx";

import { HighlightedComment } from "./segments/HighlightedComment";

interface Props {
  readOnly: boolean;
  comments: TeacherComment[];
  onChange: (comments: TeacherComment[]) => void;
  getParent: () => HTMLDivElement;
  mode: CorrectionMode;
}

export const CommentsOverlay = ({
  readOnly,
  comments,
  getParent,
  onChange,
  mode,
}: Props) => {
  const findCoordinates = (container: HTMLDivElement) => {
    const containerRange = document.createRange();
    containerRange.selectNode(container);
    const coordinates = containerRange.getBoundingClientRect();
    containerRange.detach();
    return coordinates;
  };

  const [parentCoordinates, setParentCoordinates] = useState(
    findCoordinates(getParent()),
  );

  useLayoutEffect(() => {
    function updateCoordinates() {
      setParentCoordinates(findCoordinates(getParent()));
    }
    window.addEventListener("resize", updateCoordinates);
    updateCoordinates();
    return () => window.removeEventListener("resize", updateCoordinates);
  }, [getParent]);

  const deleteComment = (index: number) => {
    comments.splice(index, 1);
    onChange(cloneDeep(comments));
  };

  const modifyComment = (index: number, comment: TeacherComment) => {
    comments[index] = comment;
    onChange(cloneDeep(comments));
  };

  return (
    <Container>
      {comments.map((comment, index) => (
        <HighlightedComment
          key={comment.id}
          readOnly={readOnly}
          comment={comment}
          parent={getParent()}
          parentCoordinates={parentCoordinates}
          onDelete={() => deleteComment(index)}
          onChange={(comment: TeacherComment) => modifyComment(index, comment)}
          mode={mode}
        />
      ))}
    </Container>
  );
};

const Container = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;
