import { useQuery } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";

import type { ConsultationAssignment } from "../../../../domain/Assignment";

type UseAssignment = {
  assignment: ConsultationAssignment | undefined;
  isLoading: boolean;
  error: unknown;
};

export const useGetAssignmentById = (id: string): UseAssignment => {
  const { data, isLoading, error } = useQuery(["assignment-by-id", id], () =>
    AssignmentClient.getById(id),
  );
  return {
    assignment: data,
    isLoading,
    error,
  };
};
