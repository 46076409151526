import { t } from "i18next";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button";
import { ReactComponent as ShowIcon } from "@/assets/eye.svg";
import { ReactComponent as HideIcon } from "@/assets/eye-off.svg";

import type { GlobalRanking } from "../../../../domain/GlobalRanking";
import { theme } from "../../../theme/theme";
import { GlobalRankingBracket } from "./GlobalRankingBracket";

export type GlobalRankingDistributionProps = {
  selectStudent: (studentId: string) => void;
  isLetterMode: boolean;
  selectedCriteria: string;
  globalRanking?: GlobalRanking;
};

export const GlobalRankingDistribution = ({
  selectStudent,
  isLetterMode,
  selectedCriteria,
  globalRanking,
}: GlobalRankingDistributionProps) => {
  const [isShowingStudents, setIsShowingStudents] = useState(true);

  return (
    <MainContainer>
      <Header>
        <LeftHeader>
          <DistributionTitle>
            {t("writingTaskGradeReport.globalGradeDistribution")}
          </DistributionTitle>
          {selectedCriteria !== "all" && (
            <CriteriaBadge>{t(selectedCriteria)}</CriteriaBadge>
          )}
        </LeftHeader>

        <Button
          data-testid="hideShowStudentsButton"
          size="sm"
          variant="outlined"
          hierarchy="neutral"
          style={{ width: "unset" }}
          onClick={() => setIsShowingStudents(!isShowingStudents)}
        >
          {isShowingStudents ? <HideIcon /> : <ShowIcon />}
          {isShowingStudents
            ? t("writingTaskGradeReport.hideStudents")
            : t("writingTaskGradeReport.showStudents")}
        </Button>
      </Header>

      <BracketList>
        {globalRanking
          ? globalRanking.brackets.map((bracket) => (
              <GlobalRankingBracket
                key={`bracket${bracket.range.letter}`}
                bracket={bracket}
                isLetterMode={isLetterMode}
                isShowingStudents={isShowingStudents}
                selectStudent={selectStudent}
              />
            ))
          : [...(Array(5) as Array<number>)].map((_e, i) => (
              <BracketSkeletonContainer key={`bracketSkeleton${i}`}>
                <Skeleton height="100%" borderRadius={12} />
              </BracketSkeletonContainer>
            ))}
      </BracketList>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: ${theme.radius.large};
  border: ${theme.borders.primary};
  background-color: ${theme.colors.base.white};

  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DistributionTitle = styled.h2`
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.semibold}
`;

const CriteriaBadge = styled.div`
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: ${theme.radius.small};
  border: 2px solid ${theme.colors.grayBlue[600]};

  color: ${theme.colors.grayBlue[700]};
  text-align: center;
  ${theme.typography.sm.medium}
`;

const BracketList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const BracketSkeletonContainer = styled.div`
  flex: 1;
  height: 120px;
`;
