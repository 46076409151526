import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TeacherWritingTask } from "../../../../../../domain/Teacher/TeacherDashboard";
import { theme } from "../../../../../theme/theme";

type Props = {
  writingTask: TeacherWritingTask;
};

export const TaskInformations = ({ writingTask }: Props) => {
  const { t } = useTranslation();

  return (
    <InfosContainer>
      <InfoContainer>
        <InfoLabel>{t("teacherWritingTaskCardInformations.type")}</InfoLabel>
        <InfoValue>{t(`redactionType.${writingTask.redactionType}`)}</InfoValue>
      </InfoContainer>
      <InfoContainer>
        <InfoLabel>
          {t("teacherWritingTaskCardInformations.gradingScale")}
        </InfoLabel>
        <InfoValue>
          {t(`gradingScaleOption.${writingTask.gradingScale}`)}
        </InfoValue>
      </InfoContainer>
      <InfoContainer>
        <InfoLabel>
          {t("teacherWritingTaskCardInformations.wordsCount")}
        </InfoLabel>
        <InfoValue>{writingTask.numberOfWords ?? "-"}</InfoValue>
      </InfoContainer>
    </InfosContainer>
  );
};

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoLabel = styled.span`
  color: ${theme.colors.gray[500]};
  ${theme.typography.xs.bold};
`;

const InfoValue = styled.span`
  color: ${theme.colors.gray[500]};
  ${theme.typography.xs.medium};
`;
