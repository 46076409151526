import { useMemo } from "react";

import { RightPanel } from "@/application/ui/lib/RightPanel.tsx";

import { theme } from "../../theme/theme";
import { useGetAssignmentById } from "../Revision/components/useAssignment";
import { ConsultationAssignmentEditor } from "../Revision/ConsultationAssignmentEditor";
import { AssignmentsProvider } from "../Revision/RevisionStates/AssignmentsProvider";
import { RevisedAssignmentContentState } from "../Revision/service/RevisedAssignmentContentState";

interface Panelprops {
  assignmentId: string;
  opened: boolean;
}

export const AssignmentConsultationPanel = ({
  opened,
  assignmentId,
}: Panelprops) => {
  const { assignment } = useGetAssignmentById(assignmentId);
  const revisedAssignmentContentState = useMemo(
    () => RevisedAssignmentContentState.init(assignment),
    [assignment],
  );

  return (
    <AssignmentsProvider>
      <RightPanel opened={opened} width={850}>
        <RightPanel.Content
          style={{ backgroundColor: theme.colors.base.white }}
        >
          <ConsultationAssignmentEditor
            revisedAssignmentContentState={revisedAssignmentContentState}
            comments={assignment?.comments ?? []}
          />
        </RightPanel.Content>
      </RightPanel>
    </AssignmentsProvider>
  );
};
