import { values } from 'lodash';

import { Grade } from '../model/Grade';
import { EvaluationCriterion } from '../model/GradedCriteria';
import type { GradingScaleType } from '../model/GradingScaleType';
import { ValuedGrade } from '../model/ValuedGrade';
import type { EvaluationCriteria } from './EvaluationCriteria';
import { getCriteriaByIndex } from './EvaluationCriteria';
import { weightMap } from './weight';

export type GlobalGrade =
  | 'A+'
  | 'A'
  | 'A-'
  | 'B+'
  | 'B'
  | 'B-'
  | 'C+'
  | 'C'
  | 'C-'
  | 'D+'
  | 'D'
  | 'D-'
  | 'E';

export type Evaluation = {
  [EvaluationCriterion.ADAPTATION]?: ValuedGrade;
  [EvaluationCriterion.COHERENCE]?: ValuedGrade;
  [EvaluationCriterion.VOCABULARY]?: ValuedGrade;
  [EvaluationCriterion.SYNTAX]?: ValuedGrade;
  [EvaluationCriterion.ORTHOGRAPHY]?: ValuedGrade;
};

export type CriteriaWeighting = {
  [EvaluationCriterion.ADAPTATION]: number;
  [EvaluationCriterion.COHERENCE]: number;
  [EvaluationCriterion.VOCABULARY]: number;
  [EvaluationCriterion.SYNTAX]: number;
  [EvaluationCriterion.ORTHOGRAPHY]: number;
};

export class GlobalGradeComputingService {
  private criteriaWeighting: CriteriaWeighting;
  private evaluationCriteria: EvaluationCriteria;

  constructor(
    gradingScaleType: GradingScaleType,
    evaluationCriteria: EvaluationCriteria,
  ) {
    this.criteriaWeighting = weightMap[gradingScaleType];
    this.evaluationCriteria = evaluationCriteria;
  }

  compute(evaluation: Evaluation): ValuedGrade {
    const value = this.computeMark(evaluation);
    return new ValuedGrade(
      Grade.of(this.toLetterWithVariantGrade(value)),
      value,
    );
  }

  computeMark(evaluation: Evaluation): number {
    const enabledCriteria = values(EvaluationCriterion)
      .filter((_, index) => getCriteriaByIndex(this.evaluationCriteria, index))
      .filter((criterion) => evaluation[criterion])
      .map((criterion) => ({
        weight: this.criteriaWeighting[criterion],
        mark: this.computeWeightedMarkForCriterion(criterion, evaluation),
      }));

    const globalWeight = enabledCriteria.reduce(
      (acc, { weight }) => acc + weight,
      0,
    );
    let globalMark = enabledCriteria.reduce((acc, { mark }) => acc + mark, 0);

    if (globalWeight < 1) {
      globalMark = Math.ceil(globalMark / globalWeight);
    }
    return globalMark;
  }

  private computeWeightedMarkForCriterion(
    criterion: EvaluationCriterion,
    evaluation: Evaluation,
  ) {
    return this.criteriaWeighting[criterion] * evaluation[criterion]!.value;
  }

  toLetterWithVariantGrade(globalMark: number) {
    if (globalMark >= 95) {
      return 'A+';
    } else if (globalMark >= 90) {
      return 'A';
    } else if (globalMark >= 85) {
      return 'A-';
    } else if (globalMark >= 80) {
      return 'B+';
    } else if (globalMark >= 75) {
      return 'B';
    } else if (globalMark >= 70) {
      return 'B-';
    } else if (globalMark >= 65) {
      return 'C+';
    } else if (globalMark >= 60) {
      return 'C';
    } else if (globalMark >= 55) {
      return 'C-';
    } else if (globalMark >= 50) {
      return 'D+';
    } else if (globalMark >= 40) {
      return 'D';
    } else if (globalMark >= 35) {
      return 'D-';
    } else {
      return 'E';
    }
  }
}
