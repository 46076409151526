import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import type { AssignmentEvent } from "@/application/domain/AssignmentEvent.ts";

type SendAssignmentProps = {
  content: string;
  writingTaskId: string;
  events: AssignmentEvent[];
};

type UseSendAssignmentProps = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const useSendAssignment = ({
  onSuccess,
  onError,
}: UseSendAssignmentProps) => {
  const { mutate, isLoading } = useMutation(
    (props: SendAssignmentProps) =>
      AssignmentClient.sendAssignment(
        props.content,
        props.writingTaskId,
        props.events,
      ),
    {
      onSuccess,
      onError,
    },
  );

  return {
    isSendingAssignment: isLoading,
    sendAssignment: mutate,
    assignmentIsLoading: isLoading,
  };
};
