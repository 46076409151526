import { useState } from "react";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { WritingTaskContextMenu } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/ContextMenu/WritingTaskContextMenu.tsx";

import { useFlags } from "../../../../../App/FlagsProvider";
import type { WritingTaskContextMenuAction } from "../../../WritingTaskContextMenuActions";
import { Delete, DeleteConfirmationModal } from "./Actions/Delete";
import { Hide } from "./Actions/Hide.tsx";
import { SeeStudentsList } from "./Actions/SeeStudentsList";
import { SendResults } from "./Actions/SendResults";
import { Unhide } from "./Actions/Unhide.tsx";
import { UpdateDraftWritingTask } from "./Actions/UpdateDraftWritingTask";
import { UpdateWritingTask } from "./Actions/UpdateWritingTask";

export interface ContextMenuProps {
  writingTask: TeacherWritingTask;
  seeStudentsList: (task: TeacherWritingTask) => void;
  onUpdateWritingTask: () => void;
  onUpdateDraftWritingTask: () => void;
}

export const ContextMenuFactory = ({
  writingTask,
  seeStudentsList,
  onUpdateWritingTask,
  onUpdateDraftWritingTask,
}: ContextMenuProps) => {
  let actions: WritingTaskContextMenuAction[] = [];
  const [open, isOpen] = useState<boolean>(false);
  const { draftWritingTask } = useFlags();

  switch (writingTask.typeStatus) {
    case "open":
    case "closed":
      actions = [
        SendResults(writingTask),
        SeeStudentsList(writingTask, seeStudentsList),
        Hide(writingTask),
      ];
      if (!draftWritingTask) {
        actions.push(UpdateWritingTask(writingTask, onUpdateWritingTask));
      }
      return (
        <WritingTaskContextMenu actions={actions} writingTask={writingTask} />
      );
    case "masked":
      actions = [
        SendResults(writingTask),
        SeeStudentsList(writingTask, seeStudentsList),
        Unhide(writingTask),
      ];
      if (!draftWritingTask) {
        actions.push(UpdateWritingTask(writingTask, onUpdateWritingTask));
      }
      return (
        <WritingTaskContextMenu actions={actions} writingTask={writingTask} />
      );
    case "draft":
      actions = [
        UpdateDraftWritingTask(writingTask, onUpdateDraftWritingTask),
        Delete(() => isOpen(true)),
      ];
      return (
        <>
          <WritingTaskContextMenu actions={actions} writingTask={writingTask} />
          <DeleteConfirmationModal
            isOpen={isOpen}
            open={open}
            writingTask={writingTask}
          />
        </>
      );
    default:
      break;
  }
};
