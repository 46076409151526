import type { StudentDashboardResponse } from "@api/StudentDashboardResponse";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";

import type { StudentDashboard } from "../domain/StudentDashboard";

export const StudentDashboardClient = {
  fetchDashboard: async (): Promise<StudentDashboard> => {
    const { data } =
      await AuthenticatedHttpClient.get<StudentDashboardResponse>(
        "/student/dashboard",
      );

    return data;
  },
};
