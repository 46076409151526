import { useEffect, useState } from "react";

type WithoutLimit = {
  active: boolean;
  limitInSeconds?: never;
  onStop?: never;
};

type WithLimit = {
  active: boolean;
  limitInSeconds: number;
  onStop: () => void;
};

type UseTimer = WithoutLimit | WithLimit;

export const useTimer = ({ active, limitInSeconds, onStop }: UseTimer) => {
  const [timer, setTime] = useState(0);

  useEffect(() => {
    if (!active) {
      return;
    }

    const hasReachedLimit = (currentTimer: number) =>
      limitInSeconds === undefined ? false : currentTimer + 1 >= limitInSeconds;

    const interval = setInterval(() => {
      setTime((currentTimer) => {
        if (hasReachedLimit(currentTimer)) {
          clearInterval(interval);
          onStop?.();
          return 0;
        }

        return currentTimer + 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      setTime(0);
    };
  }, [active, onStop, limitInSeconds]);

  return timer;
};
