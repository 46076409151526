import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type {
  HomeroomListing,
  WritingTaskListing,
} from "@/application/domain/HomeroomListing";
import { Page } from "@/application/ui/lib/Page";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage";
import { theme } from "@/application/ui/theme/theme";

import { useTeacherHomeroomDashboard } from "../hooks/useTeacherDashboard";
import { NoWritingTasks } from "./NoWritingTasks";
import { WritingTaskCard } from "./TeacherWritingTaskCard/WritingTaskCard";

interface Props {
  homeroomId: string;
  openCreationFor: (homeroomId: string) => void;
  openUpdateFor: (homeroom: HomeroomListing, writingTaskId: string) => void;
  openUpdateForDraft: (
    homeroom: HomeroomListing,
    writingTaskId: string,
  ) => void;
  onOpenGradePanel: (task: WritingTaskListing, homeroomName: string) => void;
  includeMasked: boolean;
}

export const TeacherDashboardWritingTaskList = ({
  homeroomId,
  openCreationFor,
  openUpdateFor,
  openUpdateForDraft,
  onOpenGradePanel,
  includeMasked,
}: Props) => {
  const { t } = useTranslation();

  const { dashboard, isError: isErrorDashboard } = useTeacherHomeroomDashboard(
    homeroomId,
    includeMasked,
  );

  if (isErrorDashboard) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!dashboard) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  const hasTasks = dashboard.writingTasks.length > 0;
  return (
    <Group aria-labelledby={dashboard.id}>
      {hasTasks ? (
        <WritingTasks aria-label={t("writingTaskListing")}>
          {dashboard.writingTasks.map((task) => (
            <WritingTaskCard
              key={task.id}
              writingTask={task}
              onUpdateWritingTask={() => {
                openUpdateFor(dashboard, task.id);
              }}
              onUpdateDraftWritingTask={() => {
                openUpdateForDraft(dashboard, task.id);
              }}
              seeStudentsList={() => onOpenGradePanel(task, dashboard.name)}
              homeroomId={dashboard.id}
            />
          ))}
        </WritingTasks>
      ) : (
        <NoWritingTasks onCreateClick={() => openCreationFor(dashboard.id)} />
      )}
    </Group>
  );
};

const Group = styled.section`
  padding: 32px 40px;
  gap: 24px;

  &.separator {
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`;

const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
`;
