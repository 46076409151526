import { Grade } from "@api/grade/model/Grade.ts";
import { ValuedGrade } from "@api/grade/model/ValuedGrade.ts";
import type { ReportSummaryResponseDto } from "@emilia/backend/src/grade-report/controller/dto/ReportSummaryResponseDto.ts";

import type { ReportSummary } from "../domain/ReportSummary.ts";
import { AuthenticatedHttpClient } from "./HttpClient.ts";
import { WritingTaskMapper } from "./mapper/WritingTaskMapper.ts";

export class ReportSummaryClient {
  static getForWritingTask = async (taskId: string): Promise<ReportSummary> => {
    const { data } =
      await AuthenticatedHttpClient.get<ReportSummaryResponseDto>(
        `/grade-report/summary/${taskId}`,
      );
    return ReportSummaryAssembler.fromDto(data);
  };
}

const ReportSummaryAssembler = {
  fromDto: (dto: ReportSummaryResponseDto): ReportSummary => ({
    writingTask: WritingTaskMapper.assembleToDomain(dto.writingTask),
    grades: dto.grades,
    median: new ValuedGrade(Grade.of(dto.median.letter), dto.median.percentage),
    average: dto.average,
    standardDeviation: dto.standardDeviation,
    numberOfGradedAssignments: dto.numberOfGradedAssignments,
    studentCount: dto.studentCount,
  }),
};
