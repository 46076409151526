import type { PropsWithChildren } from "react";

import { useLoggedUser } from "../../application/authentication/hooks/useLoggedUser";

export const AdminOnly = ({ children }: PropsWithChildren) => {
  const { user } = useLoggedUser();

  if (user.isAdmin()) {
    return children;
  }

  return null;
};
