import type { UserResponseDto } from "@users/UserResponseDto.ts";

import { LoggedStudent } from "@/application/authentication/domain/LoggedStudent.tsx";
import { LoggedTeacher } from "@/application/authentication/domain/LoggedTeacher.ts";
import type { LoggedUser } from "@/application/authentication/domain/LoggedUser.ts";
import { HomeroomRole } from "@/application/authentication/domain/LoggedUser.ts";

import { LoggedAdmin } from "../../domain/LoggedAdmin";

export class LoggedInUserMapper {
  static fromDto(userResponse: UserResponseDto): LoggedUser {
    let role;
    switch (userResponse.role) {
      case "teacher":
        role = HomeroomRole.Teacher;
        break;
      case "student":
        role = HomeroomRole.Student;
        break;
      case "admin":
        role = HomeroomRole.Admin;
        break;
      default:
        role = HomeroomRole.Teacher;
        break;
    }
    if (isUserATeacher(role)) {
      return new LoggedTeacher({ ...userResponse, role });
    } else if (isUserAnAdmin(role)) {
      return new LoggedAdmin({ ...userResponse, role });
    }

    return new LoggedStudent({ ...userResponse, role });
  }
}

const isUserATeacher = (role: HomeroomRole) => role === HomeroomRole.Teacher;
const isUserAnAdmin = (role: HomeroomRole) => role === HomeroomRole.Admin;
