import type { PropsWithChildren } from "react";

import { useDevelopment } from "@/development/hooks/useDevelopment.ts";

export const DeveloperOnly = ({ children }: PropsWithChildren) => {
  const { isDeveloper } = useDevelopment();

  if (isDeveloper) {
    return children;
  }

  return null;
};
