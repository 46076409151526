import { useMutation } from "@tanstack/react-query";

import { WritingTaskStatusClient } from "../../../../client/WritingTaskStatusClient";

type Props = {
  onSuccess?: () => Promise<void>;
  onError?: () => void;
};

export const useCloseAndSubmitWritingTask = ({ onSuccess, onError }: Props) => {
  const { mutate, isLoading } = useMutation(
    (taskId: string) =>
      WritingTaskStatusClient.closeAndSubmitWritingTask(taskId),
    {
      onSuccess,
      onError,
    },
  );

  return {
    isLoading,
    closeAndSubmitWritingTask: mutate,
  };
};
