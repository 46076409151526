export enum TextStyle {
  NARRATIVE = "narrative",
  INFORMATIONAL = "informational",
  POETRY = "poetry",
  DESCRIPTIVE = "descriptive",
  EXPLICATION = "explication",
  ARGUMENTATIVE = "argumentative",
}

export const textStyles = Object.values(TextStyle);
