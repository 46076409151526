import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { OrthographyGradeComputingStrategy } from '../OrthographyGradeComputingStrategy';
import { SecondaryOneOrthographyValuedGradeMapper } from './SecondaryOneOrthographyValuedGradeMapper';

export class SecondaryOneOrthographyGradeComputingStrategy
  extends OrthographyGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryOneOrthographyValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryOneOrthographyValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(orthographyErrorWordRatio: number): Grade {
    switch (true) {
      case orthographyErrorWordRatio <= 3.25:
        return Grade.of('A');
      case orthographyErrorWordRatio <= 5.75:
        return Grade.of('B');
      case orthographyErrorWordRatio <= 8.25:
        return Grade.of('C');
      case orthographyErrorWordRatio <= 11.75:
        return Grade.of('D');
      case orthographyErrorWordRatio <= 13.25:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
