const gradeLetters = ['A', 'B', 'C', 'D', 'E'] as const;
const gradeValues: { [key in GradeLetter]: number } = {
  A: 12,
  B: 9,
  C: 6,
  D: 3,
  E: 1,
} as const;
export type GradeLetter = (typeof gradeLetters)[number];

function isValidGradeLetter(letter: string): letter is GradeLetter {
  return (gradeLetters as readonly string[]).includes(letter);
}

const gradeVariants = ['++', '+', '-', '--'] as const;
const variantValues: { [key in GradeVariant]: number } = {
  '++': 1.5,
  '+': 1,
  '-': -1,
  '--': -1.5,
} as const;
export type GradeVariant = (typeof gradeVariants)[number];

function isValidGradeVariant(variant: string): variant is GradeVariant {
  return (gradeVariants as readonly string[]).includes(variant);
}

export class Grade {
  constructor(
    public readonly letter: GradeLetter,
    public readonly variant: GradeVariant | null = null,
  ) {
    if (!isValidGradeLetter(letter)) {
      throw new Error(`invalid Grade ${this.letter}`);
    }

    if (variant && !isValidGradeVariant(variant)) {
      throw new Error(`invalid Variant ${this.variant}`);
    }
  }

  static of(grade: string) {
    const letter = grade.substring(0, 1);
    const variant = grade.substring(1) || null;

    if (!isValidGradeLetter(letter)) {
      throw new Error(`invalid Grade ${grade}`);
    }

    if (!variant) {
      return new Grade(letter, null);
    }

    if (!isValidGradeVariant(variant)) {
      throw new Error(`invalid Grade ${grade}`);
    }

    return new Grade(letter, variant);
  }

  toString(): LetterWithVariantGrade {
    return `${this.letter}${this.variant ?? ''}`;
  }

  equals(other: Grade | undefined) {
    return this.toString() === other?.toString();
  }

  compare(other: Grade): boolean {
    if (this.getGradeValue() >= other.getGradeValue()) {
      return true;
    }
    return false;
  }

  getGradeValue(): number {
    return (
      gradeValues[this.letter] +
      (this.variant ? variantValues[this.variant] : 0)
    );
  }
}

export type LetterWithVariantGrade =
  | 'A+'
  | 'A++'
  | 'A'
  | 'A-'
  | 'A--'
  | 'B++'
  | 'B+'
  | 'B'
  | 'B-'
  | 'B--'
  | 'C++'
  | 'C+'
  | 'C'
  | 'C-'
  | 'C--'
  | 'D++'
  | 'D+'
  | 'D'
  | 'D-'
  | 'D--'
  | 'E++'
  | 'E+'
  | 'E'
  | 'E-'
  | 'E--';
