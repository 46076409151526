import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { createContext } from "react";

import { UserClient } from "@/application/authentication/api/UserClient.ts";
import type { LoggedUser } from "@/application/authentication/domain/LoggedUser.ts";
import { useAuth } from "@/application/authentication/useAuth.ts";

type UserProviderContext = {
  user: LoggedUser | null;
  isLoading: boolean;
  logout: () => Promise<void>;
  login: () => Promise<void>;
};

export const UserContext = createContext<UserProviderContext>({
  user: null,
  isLoading: false,
  logout: async () => {},
  login: async () => {},
});

interface ProviderProps extends PropsWithChildren {
  overrides?: Partial<UserProviderContext>;
}

export const UserProvider = ({ children, overrides }: ProviderProps) => {
  const { loading, logoutMsal, loginMsal } = useAuth();
  const queryClient = useQueryClient();

  const login = async () => {
    await loginMsal("/");
  };

  const logout = async () => {
    await logoutMsal("/connexion");

    await queryClient.invalidateQueries();
  };

  const { isLoading, data: user = null } = useQuery<LoggedUser>(
    ["me"],
    UserClient.me,
    {
      enabled: !loading,
    },
  );

  const props = {
    user,
    logout,
    login,
    isLoading,
    ...overrides,
  };

  return (
    <UserContext.Provider value={{ ...props }}>{children}</UserContext.Provider>
  );
};
