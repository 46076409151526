import { useTranslation } from "react-i18next";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { ReactComponent as EditIcon } from "@/assets/edit.svg";

import type { WritingTaskContextMenuAction } from "../../../../WritingTaskContextMenuActions";

export const UpdateWritingTask = (
  writingTask: TeacherWritingTask,
  onUpdateWritingTask: () => void,
): WritingTaskContextMenuAction => {
  const { t } = useTranslation();

  return {
    label: t("teacherWritingTaskCardAction.update_writing_task"),
    children: (
      <>
        <EditIcon />
        {t("teacherWritingTaskCardAction.update_writing_task")}
      </>
    ),
    disabled: writingTask.hasPublishedGrades,
    callback: onUpdateWritingTask,
    className: "updateWritingTask",
    tooltipMessage: writingTask.hasPublishedGrades
      ? t("teacherWritingTaskCardAction.update_writing_task_tooltip")
      : "",
  };
};
