import { Navigate } from "react-router-dom";

import { HomeroomRole } from "@/application/authentication/domain/LoggedUser.ts";
import { useLoggedUser } from "@/application/authentication/hooks/useLoggedUser.ts";

export const RedirectOnLogin = () => {
  const { user } = useLoggedUser();

  if (user.hasRole(HomeroomRole.Teacher)) {
    return <Navigate to="/professeur" />;
  }

  if (user.hasRole(HomeroomRole.Admin)) {
    return <Navigate to="/administrateur" />;
  }

  if (user.hasRole(HomeroomRole.Student)) {
    return <Navigate to="/etudiant" />;
  }
};
