import type { LoggedUserProps } from "@/application/authentication/domain/LoggedUser.ts";
import {
  HomeroomRole,
  LoggedUser,
} from "@/application/authentication/domain/LoggedUser.ts";

type Props = LoggedUserProps;

export class LoggedStudent extends LoggedUser {
  role = HomeroomRole.Student;

  constructor(props: Props) {
    super(props);
  }
}
