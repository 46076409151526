import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { AssignmentStatus } from "@/application/domain/Assignment.ts";

import { theme } from "../../../theme/theme";

interface AssignmentStatusProps {
  status: AssignmentStatus;
}

export const Status = ({ status }: AssignmentStatusProps) => {
  const { t } = useTranslation();

  return (
    <Container role="status" aria-label={t(status)} className={status}>
      {t(status)}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
  border-radius: ${theme.radius.round};
  ${theme.typography.sm.regular}

  &.CORRECTED {
    background: ${theme.colors.success[50]};
    color: ${theme.colors.success[700]};
  }

  &.CORRECTION_REJECTED {
    background: ${theme.colors.error[50]};
    color: ${theme.colors.error[700]};
  }

  &.WAIT_FOR_CORRECTION {
    background: ${theme.colors.warning[50]};
    color: ${theme.colors.warning[700]};
  }

  &.GRADED {
    background: ${theme.colors.primary[50]};
    color: ${theme.colors.primary[700]};
  }
`;
