import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { CorrectionDistribution } from "@/application/domain/GlobalRanking.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { IconButton } from "@/application/ui/lib/button/IconButton";
import { SubCriterionCategory } from "@/application/ui/pages/GradeReport/components/SubCriterionCategory.tsx";
import { ReactComponent as ChevronLeft } from "@/assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@/assets/chevron-right.svg";
import { ReactComponent as ShowIcon } from "@/assets/eye.svg";
import { ReactComponent as HideIcon } from "@/assets/eye-off.svg";

import { LoadingSpinner } from "../../../lib/spinner/LoadingSpinner";
import { theme } from "../../../theme/theme";

export type SubCriterionDistributionProps = {
  correctionDistribution?: CorrectionDistribution;
  isStudentMode: boolean;
  selectStudent: (studentId: string) => void;
};

export const SubCriterionDistribution = ({
  correctionDistribution,
  isStudentMode,
  selectStudent,
}: SubCriterionDistributionProps) => {
  const [isShowingStudents, setIsShowingStudents] = useState(true);
  const { t } = useTranslation();
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );

  if (!correctionDistribution) {
    return (
      <MainContainer data-testid="subCriterionChart">
        <LoadingSpinner />;
      </MainContainer>
    );
  }

  const mistakeTotalCount = correctionDistribution.subtypes.reduce(
    (count, value) => {
      return count + value.total;
    },
    0,
  );

  return (
    <MainContainer data-testid="subCriterionChart">
      <Header>
        <HeaderSection>
          <DistributionTitle>
            {t("subcriteriaClassment.title")}
          </DistributionTitle>
          <CriteriaBadge>{t(correctionDistribution.globalType)}</CriteriaBadge>
        </HeaderSection>

        <HeaderSection>
          {!isStudentMode && (
            <Button
              data-testid="hideShowStudentsButton"
              size="sm"
              variant="outlined"
              hierarchy="neutral"
              style={{ width: "unset" }}
              onClick={() => setIsShowingStudents(!isShowingStudents)}
            >
              {isShowingStudents ? <HideIcon /> : <ShowIcon />}
              {isShowingStudents
                ? t("writingTaskGradeReport.hideStudents")
                : t("writingTaskGradeReport.showStudents")}
            </Button>
          )}

          <IconButton
            size="sm"
            variant="text"
            hierarchy="neutral"
            onClick={scrollPrev}
          >
            <ChevronLeft />
          </IconButton>

          <IconButton
            size="sm"
            variant="text"
            hierarchy="neutral"
            onClick={scrollNext}
          >
            <ChevronRight />
          </IconButton>
        </HeaderSection>
      </Header>

      <HorizontalScroll className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          {correctionDistribution.subtypes.length ? (
            <BracketList className="embla__container">
              {correctionDistribution.subtypes.map((subtype) => (
                <SubCriterionCategory
                  key={subtype.subtypeName}
                  subtype={subtype}
                  total={mistakeTotalCount}
                  isShowingStudents={isShowingStudents}
                  isStudentMode={isStudentMode}
                  selectStudent={selectStudent}
                />
              ))}
            </BracketList>
          ) : (
            <BlankSlate data-testid="blankSlate" className="embla__slide">
              {t("writingTaskGradeReport.noErrorsBlankslate")}
            </BlankSlate>
          )}
        </div>
      </HorizontalScroll>
    </MainContainer>
  );
};

const HorizontalScroll = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: ${theme.radius.large};
  border: ${theme.borders.primary};
  background-color: ${theme.colors.base.white};

  overflow: clip;

  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DistributionTitle = styled.h2`
  color: ${theme.colors.gray[600]};
  ${theme.typography.lg.semibold}
`;

const CriteriaBadge = styled.div`
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: ${theme.radius.small};
  border: 2px solid ${theme.colors.grayBlue[600]};

  color: ${theme.colors.grayBlue[700]};
  ${theme.typography.sm.medium}
  text-align: center;
`;

const BracketList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const BlankSlate = styled.div`
  display: flex;
  padding: 16px 0px 40px 40px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
