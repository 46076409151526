import type { TextWithMistakes } from '../../../../mistakes/domain/model/TextWithMistakes';
import { Grade } from '../../../model/Grade';
import type { GradeComputingStrategy } from '../../../model/GradeComputingStrategy';
import type { ValuedGrade } from '../../../model/ValuedGrade';
import { SyntaxGradeComputingStrategy } from '../SyntaxGradeComputingStrategy';
import { SecondaryThreeSyntaxValuedGradeMapper } from './SecondaryThreeSyntaxValuedGradeMapper';

export class SecondaryThreeSyntaxGradeComputingStrategy
  extends SyntaxGradeComputingStrategy
  implements GradeComputingStrategy
{
  private valuedGradeMapper: SecondaryThreeSyntaxValuedGradeMapper;

  constructor() {
    super();
    this.valuedGradeMapper = new SecondaryThreeSyntaxValuedGradeMapper();
  }

  compute(textWithMistakes: TextWithMistakes): ValuedGrade {
    const ratio = this.computeErrorRatio(textWithMistakes);
    return this.valuedGradeMapper.create(this.findMatchingGrade(ratio));
  }

  private findMatchingGrade(syntaxErrorWordRatio: number): Grade {
    switch (true) {
      case syntaxErrorWordRatio <= 0.4:
        return Grade.of('A+');
      case syntaxErrorWordRatio <= 1.13:
        return Grade.of('A');
      case syntaxErrorWordRatio <= 2.53:
        return Grade.of('B');
      case syntaxErrorWordRatio <= 3.79:
        return Grade.of('C');
      case syntaxErrorWordRatio <= 5.06:
        return Grade.of('D');
      case syntaxErrorWordRatio <= 6.33:
        return Grade.of('E');
      default:
        return Grade.of('E--');
    }
  }
}
