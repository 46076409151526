import clsx from "clsx";
import { useSelect } from "downshift";
import type { ReactNode } from "react";
import styled from "styled-components";

import { BaseInput } from "@/application/ui/lib/inputs/inputStyles.ts";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";
import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as ChevronDown } from "@/assets/chevron-down.svg";

export type Option = {
  label: string;
  value: string;
};

export interface DropdownProps {
  size?: "sm" | "md";
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  options: Option[];
  defaultValue?: string;
  onChange?: (newValue: string) => void;
  value?: string;
  className?: string;
  selectedItemLabelPrefix?: string;
  icon?: ReactNode;
  blurOnDisable?: boolean;
  edges?: "round" | "straight";
}

export const Dropdown = ({
  size = "sm",
  label,
  disabled,
  options,
  placeholder,
  value,
  defaultValue,
  name,
  onChange,
  icon,
  blurOnDisable,
  className,
  selectedItemLabelPrefix,
  edges,
}: DropdownProps) => {
  const findOption = (value?: string) =>
    options.find((option) => option.value === value);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getLabelProps,
    getItemProps,
  } = useSelect({
    items: options,
    selectedItem: findOption(value),
    defaultSelectedItem: findOption(defaultValue),
    onSelectedItemChange: ({ selectedItem }) => {
      onChange?.(selectedItem?.value ?? "");
    },
  });

  return (
    <BaseInput.Container className={className}>
      {label && (
        <StyledLabel className={clsx({ disabled })} {...getLabelProps()}>
          {label}
        </StyledLabel>
      )}

      <div style={{ position: "relative" }}>
        <BaseInput.InputBox
          aria-label={name}
          className={clsx(`size-${size}`, `edges-${edges}`, {
            focused: isOpen,
            disabled,
          })}
          {...getToggleButtonProps()}
        >
          <Value
            className={clsx({ placeholder: !selectedItem, disabled })}
            style={{ height: "26px" }}
          >
            {icon && <IconContainer>{icon}</IconContainer>}
            {selectedItemLabelPrefix ?? ""}{" "}
            <DisplayedText className={clsx({ disabled, blurOnDisable })}>
              {selectedItem?.label ?? placeholder ?? ""}
            </DisplayedText>
          </Value>

          <ColoredChevron className={clsx({ disabled })} />
        </BaseInput.InputBox>

        <DropdownMenu
          aria-label={name}
          className={clsx({ isOpen: isOpen && !disabled })}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((option, index) => (
              <DropdownItem
                className={clsx({
                  selected: selectedItem?.value === option.value,
                })}
                key={option.value}
                {...getItemProps({ item: option, index })}
              >
                {option.label}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </div>

      <input value={selectedItem?.value ?? ""} name={name} type="hidden" />
    </BaseInput.Container>
  );
};

const DisplayedText = styled.span`
  ${theme.typography.sm.semibold}

  &.disabled.blurOnDisable {
    filter: blur(4px);
    pointer-event: none;
  }
`;

const ColoredChevron = styled(ChevronDown)`
  &.disabled {
    color: ${theme.colors.gray[400]};
  }
`;

const IconContainer = styled.div`
  padding-right: 8px;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  flex: 1;
  text-align: start;
  display: flex;
  align-items: center;

  ${(props) => BaseInput.InputStyle(props.theme)};
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: pre;

  &.placeholder {
    font-weight: 300;
    color: ${theme.colors.gray[500]};
  }
  &.disabled {
    color: ${theme.colors.gray[400]};
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  margin-top: 4px;
  border-radius: ${theme.radius.medium};
  max-height: 500px;
  min-width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &:not(.isOpen) {
    display: none;
  }

  ${(props) => scrollbarStyle(props.theme)};
  border: ${theme.borders.primary};
  background: ${theme.colors.base.white};

  box-shadow: ${theme.shadowAndBlur.large};
`;

const DropdownItem = styled.li`
  padding: 10px 8px;
  margin: 0 6px;
  text-align: start;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  font-weight: ${theme.fontWeight.regular};
  line-height: 24px; /* 150% */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${theme.typography.sm.regular}

  background-color: ${theme.colors.base.white};

  &.selected,
  &:hover {
    font-weight: ${theme.fontWeight.medium};
    background-color: ${theme.colors.gray[50]};
  }
`;

const StyledLabel = styled(BaseInput.Label)`
  &.disabled {
    color: ${theme.colors.gray[500]};
  }
`;
