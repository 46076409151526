import type { Grade } from "@emilia/backend/src/common/grade/model/Grade";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";

import { theme } from "../../theme/theme";

type StudentViewGlobalGradeProps = {
  globalGrade: Grade | null;
};

export const ReadOnlyGlobalGrade = ({
  globalGrade,
}: StudentViewGlobalGradeProps) => {
  const { t } = useTranslation();

  return (
    <SidebarBox title={t("obtainedGrade")}>
      <GradeContainer>
        <Badge>{globalGrade?.toString() ?? "-"}</Badge>
      </GradeContainer>
    </SidebarBox>
  );
};

const GradeContainer = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.base.white};
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 3.5rem;
  padding: 0.3875rem 1.1625rem;
  border-radius: ${theme.radius.round};
  border: 2px solid ${theme.colors.secondary[100]};
  background: ${theme.colors.secondary[50]};
  box-shadow: ${theme.shadowAndBlur.xsmall};
  color: ${theme.colors.secondary[700]};
  ${theme.typography.xl.medium}
`;
