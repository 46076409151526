import type { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CategoryIcon } from "@/application/ui/pages/Revision/CategoryIcon.tsx";

import { theme } from "../../../../../theme/theme";
import { useAssignments } from "../../../RevisionStates/useAssignments";

export interface ErrorCategorySummaryProps {
  category: Category;
  count: number;
  children?: ReactNode;
}

export const ErrorCategorySummary = ({
  category,
  count,
  children,
}: ErrorCategorySummaryProps) => {
  const { t } = useTranslation();
  const { showCategory } = useAssignments();

  return (
    <Container
      role="row"
      aria-label={category}
      $visible={showCategory(category)}
    >
      <CategoryColumn>
        <CategoryIcon category={category} />
        <CategoryName>{t(category)}</CategoryName>
      </CategoryColumn>
      <Column>
        <ErrorCount>
          {t("errorWithCount", {
            count,
          })}
        </ErrorCount>
        {children}
      </Column>
    </Container>
  );
};

const Container = styled.div<{ $visible: boolean }>`
  .visibleIcon {
    opacity: 0;
  }

  &:hover {
    .visibleIcon {
      opacity: 100%;
    }
  }

  display: flex;
  justify-content: space-between;
  grid-template-columns: [left] 2fr [right] 2fr;
  color: ${theme.colors.gray[500]};
  opacity: ${(props) => (props.$visible ? "100%" : "60%")};
`;

const Column = styled.div`
  display: flex;
`;

const CategoryColumn = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryName = styled.p`
  margin-left: 8px;
`;

export const ErrorCount = styled.p`
  font-weight: 300;
  margin-right: 8px;
  align-self: center;
`;
