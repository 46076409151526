import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import styled from "styled-components";

import { AssignmentStatus } from "@/application/domain/Assignment";
import { SubmitButton } from "@/application/ui/pages/Revision/components/Sidebar/SubmitButton.tsx";
import type { RevisableAndGradableAssignment } from "@/application/ui/pages/Revision/service/RevisableAndGradableAssignment.ts";

type Props = {
  onConfirmGrade: () => void;
  evaluatedCriteria: EvaluationCriteria;
  revisableAssignment: RevisableAndGradableAssignment;
  assignmentIsSaving: boolean;
  className?: string;
  hasChanged: boolean;
};

export const GlobalGradeSection = ({
  onConfirmGrade,
  evaluatedCriteria,
  revisableAssignment,
  assignmentIsSaving,
  className,
  hasChanged,
}: Props) => {
  return (
    <Container className={className}>
      <SubmitButton
        onClick={onConfirmGrade}
        disabled={
          !revisableAssignment.isFullyGraded(evaluatedCriteria) ||
          (!hasChanged &&
            revisableAssignment.status === AssignmentStatus.GRADED)
        }
        loading={assignmentIsSaving}
        globalGrade={revisableAssignment.getGlobalGrade(evaluatedCriteria)}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
