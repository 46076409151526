import { useQuery } from "@tanstack/react-query";

import { WritingTaskStatusClient } from "../../../../client/WritingTaskStatusClient";

export const useTaskClosingConcernedStudents = (taskId?: string) => {
  const { data: students, isLoading } = useQuery({
    queryKey: ["closeWritingTaskGroupSummary", taskId],
    queryFn: async () =>
      await WritingTaskStatusClient.getTaskClosingConcernedStudents(taskId!),
    enabled: !!taskId,
  });
  return { students, isLoading };
};
