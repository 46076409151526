import type { GradeComputingStrategy } from '../../model/GradeComputingStrategy';
import type { GradingScaleType } from '../../model/GradingScaleType';
import { SecondaryFiveOrthographyGradeComputingStrategy } from './secondaryFive/SecondaryFiveOrthographyGradeComputingStrategy';
import { SecondaryFiveMinistryOrthographyGradeComputingStrategy } from './secondaryFiveMinistry/SecondaryFiveMinistryOrthographyGradeComputingStrategy';
import { SecondaryFourOrthographyGradeComputingStrategy } from './secondaryFour/SecondaryFourOrthographyGradeComputingStrategy';
import { SecondaryOneOrthographyGradeComputingStrategy } from './secondaryOne/SecondaryOneOrthographyGradeComputingStrategy';
import { SecondaryThreeOrthographyGradeComputingStrategy } from './secondaryThree/SecondaryThreeOrthographyGradeComputingStrategy';
import { SecondaryTwoOrthographyGradeComputingStrategy } from './secondaryTwo/SecondaryTwoOrthographyGradeComputingStrategy';
import { ElementaryOrthographyGradeComputingStrategy } from './sixthYear/SixthYearOrthographyGradeComputingStrategy';

export class OrthographyGradeComputingStrategyFactory {
  static create(type: GradingScaleType): GradeComputingStrategy {
    switch (type) {
      case 'secondary_one':
        return new SecondaryOneOrthographyGradeComputingStrategy();
      case 'secondary_two':
        return new SecondaryTwoOrthographyGradeComputingStrategy();
      case 'secondary_three':
        return new SecondaryThreeOrthographyGradeComputingStrategy();
      case 'secondary_four':
        return new SecondaryFourOrthographyGradeComputingStrategy();
      case 'secondary_five':
        return new SecondaryFiveOrthographyGradeComputingStrategy();
      case 'secondary_five_ministry':
        return new SecondaryFiveMinistryOrthographyGradeComputingStrategy();
      case 'third_year':
      case 'fourth_year':
      case 'fifth_year':
      case 'sixth_year':
        return new ElementaryOrthographyGradeComputingStrategy();
      default:
        throw new Error('Not implemented yet');
    }
  }
}
