import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { CompletionFraction, Footer } from "./SummaryContent";

export const SkeletonContent = () => {
  return (
    <>
      <ChartSkeletonContainer>
        <Skeleton height="100%" />
      </ChartSkeletonContainer>
      <Footer>
        <MedianBadgeSkeletonContainer>
          <Skeleton height="100%" borderRadius={16} />
        </MedianBadgeSkeletonContainer>

        <CompletionFraction>
          <Skeleton width={140} />
        </CompletionFraction>
      </Footer>
    </>
  );
};

const ChartSkeletonContainer = styled.div`
  width: 100%;
  height: 140px;
`;

const MedianBadgeSkeletonContainer = styled.div`
  width: 110px;
  height: 32px;
`;
