import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as CheckIcon } from "@/assets/check.svg";
import { ReactComponent as XIcon } from "@/assets/x-close.svg";

import { BoldTrans } from "../../../../../i18n/BoldTrans";

export const Example = ({
  exampleKey,
  subcriteria,
}: {
  exampleKey: string;
  subcriteria: string;
}) => {
  const { t } = useTranslation();
  return (
    <ExampleWrapper>
      {t(`subcriteria:labels.${exampleKey}`)}
      <Examples>
        <Incorrect>
          <IncorrectIcon />
          <BoldTrans
            translationKey={`subcriteria:${subcriteria}.${exampleKey}.incorrect`}
          />
        </Incorrect>
        <Correct>
          <CorrectIcon />
          <BoldTrans
            translationKey={`subcriteria:${subcriteria}.${exampleKey}.correct`}
          />
        </Correct>
      </Examples>
    </ExampleWrapper>
  );
};

const ExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.typography.sm.medium};
  gap: 16px;
`;

const Examples = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IncorrectIcon = styled(XIcon)`
  height: 24px;
  width: 24px;
  * {
    stroke: ${theme.colors.error[500]};
  }
`;

const Incorrect = styled.div`
  display: flex;
  background-color: ${theme.colors.error[50]};
  gap: 8px;
`;

const CorrectIcon = styled(CheckIcon)`
  height: 24px;
  width: 24px;
  * {
    stroke: ${theme.colors.success[500]};
  }
`;

const Correct = styled.div`
  display: flex;
  background-color: ${theme.colors.success[50]};
  gap: 8px;
`;
