import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";

type queueElement = {
  assignmentId: string;
  [k: string]: unknown;
};
export class RevisionsStateClient {
  static getAll = async (): Promise<{
    pendingRequests: queueElement[];
    failedPendingRequests: queueElement[];
    completedRevisions: queueElement[];
    failedCompletedRevisions: queueElement[];
  }> => {
    const { data } = await AuthenticatedHttpClient.get<{
      pendingRequests: queueElement[];
      failedPendingRequests: queueElement[];
      completedRevisions: queueElement[];
      failedCompletedRevisions: queueElement[];
    }>("/revisions/state");
    return {
      pendingRequests: data.pendingRequests,
      failedPendingRequests: data.failedPendingRequests,
      completedRevisions: data.completedRevisions,
      failedCompletedRevisions: data.failedCompletedRevisions,
    };
  };
}
