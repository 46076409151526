/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { Page } from "@/application/ui/lib/Page.tsx";
import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { ImportUserClient } from "@/application/ui/pages/ImportUsers/client/importUserClient.ts";

export interface ImportUsersFieldValues {
  groups: Blob[];
  teachers: Blob[];
  students: Blob[];
}

export const ImportUsersPage = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ImportUsersFieldValues>();
  const navigate = useNavigate();

  const { mutate: importUser } = useMutation(
    (data: ImportUsersFieldValues) => ImportUserClient.importUser(data),
    {
      onSuccess: () => {
        ToastService.showSuccess(t("importSuccessful"));
        navigate("/administrateur");
      },
      onError: () => {
        ToastService.showError(t("importFailure"));
      },
    },
  );

  return (
    <Page>
      <Title>Import</Title>

      <Form onSubmit={handleSubmit((data) => importUser(data))}>
        <label>
          <div>Groupes</div>
          <input
            required
            {...register("groups")}
            type="file"
            accept="text/csv"
          />
        </label>
        <label>
          <div>Étudiants</div>
          <input
            required
            {...register("students")}
            type="file"
            accept="text/csv"
          />
        </label>
        <label>
          <div>Professeurs</div>
          <input
            required
            {...register("teachers")}
            type="file"
            accept="text/csv"
          />
        </label>

        <Button type="submit" variant="contained">
          Import
        </Button>
      </Form>
    </Page>
  );
};

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  maxWidth: 500,
  padding: 12,
  gap: 8,
});
