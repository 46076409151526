import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import { theme } from "../../theme/theme";

export const CustomToastContainer = () => <StyledToastContainer />;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: ${theme.radius.large};
    display: flex;
    align-items: flex-start;
    ${theme.typography.sm.regular}
    padding: 1rem;
    width: 480px;
    height: 100%;
  }

  .Toastify__toast.success {
    border: 1px solid ${theme.colors.success[300]};
  }

  .Toastify__toast.error {
    border: 1px solid ${theme.colors.error[300]};
  }

  .success {
    color: ${theme.colors.success[700]};
    background-color: ${theme.colors.success[25]};
  }

  .error {
    color: ${theme.colors.error[700]};
    background-color: ${theme.colors.error[25]};
  }

  .Toastify__toast-icon {
    display: none;
  }
`;
