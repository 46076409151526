import { useState } from "react";

import type { StudentId } from "@/application/domain/Student.ts";
import type { WritingTaskAssignments } from "@/application/domain/WritingTaskAssignments.ts";
import { RevisableAndGradableAssignment } from "@/application/ui/pages/Revision/service/RevisableAndGradableAssignment.ts";

export const useSelectedRevisableAssignment = (
  writingTaskAssignments: WritingTaskAssignments,
  studentId: string | null = null,
) => {
  const assignmentsByStudents =
    writingTaskAssignments.getLastAssignmentForEachStudent();

  const orderedStudents = assignmentsByStudents.orderStudentsByName();
  const [currentStudent, setCurrentStudent] = useState<StudentId>(() => {
    if (studentId) {
      return (
        orderedStudents.find((studentInfo) => (studentInfo.id = studentId))
          ?.id ?? orderedStudents[0].id
      );
    } else {
      return orderedStudents[0].id;
    }
  });

  const assignment = assignmentsByStudents.getFor(currentStudent);

  const [revisableAssignment, setRevisableAssignment] =
    useState<RevisableAndGradableAssignment>(
      RevisableAndGradableAssignment.init(
        assignment,
        writingTaskAssignments.writingTask.gradingScaleType,
      ),
    );

  const selectStudent = (studentId: StudentId) => {
    setCurrentStudent(studentId);
    setRevisableAssignment(
      RevisableAndGradableAssignment.init(
        assignmentsByStudents.getFor(studentId),
        writingTaskAssignments.writingTask.gradingScaleType,
      ),
    );
  };

  return {
    revisableAssignment,
    currentStudent,
    orderedStudents,
    selectStudent,
    setRevisableAssignment,
  };
};
