import { useCallback, useState } from "react";

import type { OnAudioCompleted } from "@/application/services/AudioRecordingService.ts";
import { AudioRecordingService } from "@/application/services/AudioRecordingService.ts";
import { useTimer } from "@/application/ui/lib/recording/hooks/useTimer.ts";

interface UserAudioRecorder {
  limitInSeconds: number;
}

export const useAudioRecorder = ({ limitInSeconds }: UserAudioRecorder) => {
  const [isRecording, setIsRecording] = useState(false);

  const startRecording = (onAudioCompleted: OnAudioCompleted) => {
    AudioRecordingService.startRecording(onAudioCompleted)
      .then(() => setIsRecording(true))
      .catch(() => {}); // nothing to do
  };

  const stopRecording = useCallback(() => {
    AudioRecordingService.stopRecording();
    setIsRecording(false);
  }, []);

  const timer = useTimer({
    active: isRecording,
    limitInSeconds,
    onStop: stopRecording,
  });

  return {
    timer,
    startRecording,
    stopRecording,
    isRecording,
  };
};
