import type { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import type { ChartEvent, ScaleOptions, TooltipItem } from "chart.js";
import type { AnnotationPluginOptions } from "chartjs-plugin-annotation";

import { theme } from "../../theme/theme";

export const assembleChartData = (
  labels: string[],
  values: number[],
  criteriaColors: string[],
) => {
  return {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: criteriaColors,
        borderRadius: 5,
        maxBarThickness: 40,
      },
    ],
  };
};

export const assembleChartOptions = (
  tooltips: string[],
  averageLabel: string,
  averageValue: number,
  yScale: ScaleOptions<"linear">,
  criterion: EvaluationCriterion[],
  setSelectedCriteria: (criteria: string) => void,
) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<"bar">) => {
            return tooltips[tooltipItem.dataIndex].toString();
          },
          title: () => {
            return "";
          },
          labelColor: (tooltipItem: TooltipItem<"bar">) => {
            return {
              borderRadius: 3,
              borderColor: "rgba(0,0,0,0)",
              backgroundColor: (
                tooltipItem.dataset.backgroundColor as string[]
              )[tooltipItem.dataIndex],
            };
          },
        },
        boxWidth: 20,
        boxHeight: 20,
        boxPadding: 8,
        bodyFont: {
          size: 12,
          weight: 600,
        },
        padding: {
          x: 12,
          y: 8,
        },
        bodyColor: "#344054",
        backgroundColor: "white",
        caretSize: 0,
        borderWidth: 1.5,
        borderColor: "#EAECF0",
      },
      annotation: {
        annotations: {
          thresholdLine: {
            type: "line",
            drawTime: "beforeDatasetsDraw",
            yMin: averageValue,
            yMax: averageValue,
            borderColor: theme.colors.indigo[500],
            borderWidth: 1,
            borderDash: [5, 5],
            label: {
              display: true,
              content: [averageLabel],
              position: "end",
              color: theme.colors.indigo[500],
              backgroundColor: theme.colors.indigo[50],
              borderRadius: 100,
              padding: {
                x: 12,
                y: 8,
              },
              xAdjust: 12,
            },
          },
        },
      } as AnnotationPluginOptions,
    },
    scales: {
      y: yScale,
      x: {
        ticks: {
          padding: 16,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    onClick(_evt: ChartEvent, clickedElements: { index: number }[]) {
      if (clickedElements.length === 0) return;
      setSelectedCriteria(criterion[clickedElements[0].index]);
    },
  };
};
