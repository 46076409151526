import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import { ReactComponent as ChevronLeftDouble } from "@/assets/chevron-left-double.svg";
import { ReactComponent as ChevronRightDouble } from "@/assets/chevron-right-double.svg";

import { theme } from "../../../../theme/theme";

export type RevisionPopupCollapseExpandMode = "collapsed" | "expanded";

type ExpandCollapseButtonProps = {
  viewMode: RevisionPopupCollapseExpandMode;
  switchViewMode: () => void;
};

export const ExpandCollapseButton = ({
  viewMode,
  switchViewMode,
}: ExpandCollapseButtonProps) => {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState(false);

  const toggleViewMode = () => {
    switchViewMode();
    setShowTooltip(false);
  };

  return (
    <Popover
      isOpen={showTooltip}
      content={
        <Tooltip>
          {viewMode === "collapsed" ? t("expandTile") : t("collapseTile")}
        </Tooltip>
      }
    >
      <ExpandButton
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        aria-label="collapse-button"
        onClick={toggleViewMode}
      >
        {viewMode === "collapsed" ? (
          <ChevronRightDouble />
        ) : (
          <ChevronLeftDouble />
        )}
      </ExpandButton>
    </Popover>
  );
};

const ExpandButton = styled.button`
  border: none;
  flex-shrink: 0;
  border-radius: ${theme.radius.round};
  width: 40px;
  height: 40px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.gray[100]};
  }
`;

const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.radius.medium};
  padding: 0.5rem 0.75rem;
  color: ${theme.colors.secondary[700]};
  background-color: ${theme.colors.base.white};
  box-shadow: ${theme.shadowAndBlur.large};
  ${theme.typography.xs.semibold}
`;
