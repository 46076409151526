import { EvaluationCriterion } from "@api/grade/model/GradedCriteria";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Card } from "@/application/ui/lib/Card.tsx";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle";

import { theme } from "../../../../theme/theme";
import { useReportSummary } from "../../hooks/useReportSummary";
import { InformationField } from "./InformationField";
import { SkeletonContent } from "./SkeletonContent";
import { SummaryContent } from "./SummaryContent";

export const InformationCard = ({ taskId }: { taskId: string }) => {
  const { t } = useTranslation();
  const { reportSummary } = useReportSummary(taskId);

  const descriptionContainerRef = useRef<HTMLDivElement>(null);
  const container = descriptionContainerRef.current;
  const isDescriptionOverflowing =
    container &&
    (container.offsetHeight < container.scrollHeight ||
      container.offsetWidth < container.scrollWidth);

  const task = reportSummary?.writingTask;
  let evaluatedCriteriaList: string[] = [];
  if (task) {
    if (task.evaluatedCriteria.C1)
      evaluatedCriteriaList.push(t(EvaluationCriterion.ADAPTATION));
    if (task.evaluatedCriteria.C2)
      evaluatedCriteriaList.push(t(EvaluationCriterion.COHERENCE));
    if (task.evaluatedCriteria.C3)
      evaluatedCriteriaList.push(t(EvaluationCriterion.VOCABULARY));
    if (task.evaluatedCriteria.C4)
      evaluatedCriteriaList.push(t(EvaluationCriterion.SYNTAX));
    if (task.evaluatedCriteria.C5)
      evaluatedCriteriaList.push(t(EvaluationCriterion.ORTHOGRAPHY));

    if (evaluatedCriteriaList.length === 5)
      evaluatedCriteriaList = [t("writingTaskGradeReport.all")];
  }

  return (
    <MainContainer>
      <TopContainer>
        <Header>
          <Title>{task?.name || <Skeleton />}</Title>
          <Description
            id={`taskDescription-${task?.id}`}
            ref={descriptionContainerRef}
          >
            {task?.instructions || <Skeleton />}
          </Description>
          {isDescriptionOverflowing && (
            <Tooltip
              anchorSelect={`#taskDescription-${task?.id}`}
              content={task?.instructions}
              place="bottom"
              style={{ ...tooltipStyle, maxWidth: 400 }}
            />
          )}
        </Header>

        <InformationsContainer>
          <InformationsContainerColumn>
            <InformationField
              title={t("redactionTypeInput")}
              value={task && t(`redactionType.${task.redactionType}`)}
            />
            <InformationField
              title={t("writingTaskGradeReport.evaluation")}
              value={task && t(`taskType.${task.taskType}`)}
            />
          </InformationsContainerColumn>
          <InformationsContainerColumn>
            <InformationField
              title={t("numberOfWords")}
              value={
                task && !task.numberOfWords
                  ? "-"
                  : task?.numberOfWords?.toString()
              }
            />
            <InformationField
              title={t("writingTaskGradeReport.countedCriteria")}
              value={evaluatedCriteriaList.join(", ")}
            />
          </InformationsContainerColumn>
        </InformationsContainer>
      </TopContainer>

      {reportSummary ? (
        <SummaryContent reportSummary={reportSummary} />
      ) : (
        <SkeletonContent />
      )}
    </MainContainer>
  );
};

const MainContainer = styled(Card)`
  width: 360px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  align-self: stretch;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 2px solid ${theme.colors.gray[300]};
`;

const Title = styled.div`
  ${theme.typography.md.medium}
  color: ${theme.colors.gray[900]};
`;

const Description = styled.div`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]};
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const InformationsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InformationsContainerColumn = styled.div`
  width: 50%;
`;
