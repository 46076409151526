import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as OpenIcon } from "@/assets/chevron-down.svg";
import { ReactComponent as CloseIcon } from "@/assets/chevron-up.svg";
import { ReactComponent as XIcon } from "@/assets/x-close.svg";

import { IconButton } from "../../../lib/button/IconButton";
import { theme } from "../../../theme/theme";
import { Example } from "./SubcriteriaPopupExample";

type SubcriteriaPopupProps = {
  onClose: () => void;
  subcriteria: string;
};

export const SubcriteriaPopup = ({
  onClose,
  subcriteria,
}: SubcriteriaPopupProps) => {
  const { t, i18n } = useTranslation();
  const exampleKeys = ["ex2", "ex3"];
  return (
    <Container role="dialog">
      <Section>
        <Header>
          {t("subcriteriaClassment.popup.title")}
          <CloseButton
            variant="text"
            hierarchy="neutral"
            onClick={onClose}
            title={t("closePopup")}
            style={{ padding: 2 }}
          >
            <XIcon />
          </CloseButton>
        </Header>
      </Section>
      <Section>{t(`subcriteria:${subcriteria}.definition`)}</Section>
      {i18n.exists(`subcriteria:${subcriteria}.ex1`) && (
        <Section>
          <ExampleList>
            <Example exampleKey="ex1" subcriteria={subcriteria} />
            <Accordion>
              <AccordionItem
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                header={({ state }) => <AccordionIcon open={state.isEnter} />}
              >
                <SpacedExamples>
                  {exampleKeys.map((x) => {
                    return (
                      <Example
                        key={x}
                        exampleKey={x}
                        subcriteria={subcriteria}
                      />
                    );
                  })}
                </SpacedExamples>
              </AccordionItem>
            </Accordion>
          </ExampleList>
        </Section>
      )}
    </Container>
  );
};

const AccordionIcon = ({ open }: { open: boolean }) => {
  return <CenteredIcon>{open ? <CloseIcon /> : <OpenIcon />}</CenteredIcon>;
};

const SpacedExamples = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CenteredIcon = styled.div`
  cursor: pointer;
  width: 468px; //popup width - side padding, widthL 100% didnt work
  justify-content: center;
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.typography.xs.bold};
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  padding: 8px 16px;
  ${theme.typography.md.regular};
`;

const ExampleList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

const CloseButton = styled(IconButton)`
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const Container = styled.div`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.xlarge};
  width: 500px;
`;
