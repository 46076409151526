import type { FlagsConfig } from "@api/FlagsConfig";
import { useQuery } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { EnvConfigClient } from "../../../authentication/msal/EnvConfigClient";

export const FlagsContext = createContext<FlagsConfig>({
  maintenance: false,
  closeWritingTask: false,
  draftWritingTask: false,
  spavoc: false,
});

export const useFlags = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlags cannot be used outside FlagsProvider");
  }

  return context;
};

export const FlagsProvider = ({ children }: PropsWithChildren) => {
  const { data } = useQuery({
    queryKey: ["flagsConfig"],
    queryFn: () => EnvConfigClient.flagsConfig(),
  });

  return (
    <FlagsContext.Provider
      value={{
        maintenance: data?.maintenance ?? false,
        closeWritingTask: data?.closeWritingTask ?? false,
        draftWritingTask: data?.draftWritingTask ?? false,
        spavoc: data?.spavoc ?? false,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};
