import type { ReportDashboardResponseDto } from "@emilia/backend/src/grade-report/controller/dto/reportDashboardResponseDto.ts";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";

import type { ReportDashboard } from "../domain/reportDashboard";

export const ReportDashboardClient = {
  fetchGroups: async (): Promise<ReportDashboard> => {
    const { data } =
      await AuthenticatedHttpClient.get<ReportDashboardResponseDto>(
        "/grade-report/dashboard/",
        { timeout: 25000 },
      );
    return data;
  },
};
