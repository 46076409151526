import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { LoadingSpinnerRing } from "@/application/ui/lib/spinner/LoadingSpinnerRing.tsx";

import { theme } from "../../theme/theme";

export const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <LoadingSpinnerRing />
      <Label>{t("loading")}</Label>
    </Container>
  );
};
const Label = styled.span`
  ${theme.typography.lg.medium}
  gap: 20px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
