import { WritingTaskCardActions } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/CallToAction/WritingTaskCardActions.tsx";
import { type ContextMenuProps } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/ContextMenu/ContextMenuFactory.tsx";
import { TaskCard } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/TaskCard.tsx";

import { WritingTaskInformation } from "../WritingTaskInformation.tsx";
import { CardContentFactory } from "./Content/CardContentFactory.tsx";
import { TaskInformations } from "./Content/TaskInformations.tsx";
import { HeaderFactory } from "./Header/HeaderFactory.tsx";
type Props = ContextMenuProps & {
  homeroomId: string;
};

export const MaskedTaskCard = ({
  writingTask,
  homeroomId,
  ...props
}: Props) => {
  return (
    <TaskCard color="gray">
      <TaskCard.Header>
        <HeaderFactory writingTask={writingTask} {...props} />
        <WritingTaskInformation.Name>
          {writingTask.name}
        </WritingTaskInformation.Name>
      </TaskCard.Header>
      <TaskCard.Body>
        <TaskInformations writingTask={writingTask} />
        <CardContentFactory writingTask={writingTask} />
      </TaskCard.Body>
      <TaskCard.Footer>
        <WritingTaskCardActions
          homeroomId={homeroomId}
          writingTask={writingTask}
        />
      </TaskCard.Footer>
    </TaskCard>
  );
};
