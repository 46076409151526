import type React from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "@/assets/check.svg";

import { theme } from "../../theme/theme";

type CheckBoxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  borderColor?: string;
  selectedColor?: string;
  backgroundSelectedColor?: string;
};

export const Checkbox = ({
  className = undefined,
  checked = undefined,
  disabled = undefined,
  hidden = undefined,
  borderColor: borderColor = undefined,
  selectedColor: selectedColor = undefined,
  backgroundSelectedColor: backgroundSelectedColor = undefined,
  ...props
}: CheckBoxProps) => (
  <CheckboxContainer className={className} disabled={disabled} hidden={hidden}>
    <HiddenCheckbox
      checked={checked}
      disabled={disabled}
      hidden={hidden}
      {...props}
    />
    {!hidden && (
      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        $borderColor={borderColor}
        $selectedColor={selectedColor}
        $backgroundSelectedColor={backgroundSelectedColor}
      >
        <StyledCheckIcon checked={checked} />
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<CheckBoxProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const StyledCheckIcon = styled(CheckIcon)<{ checked?: boolean }>`
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
`;

const StyledCheckbox = styled.div<{
  checked?: boolean;
  disabled?: boolean;
  $borderColor?: string;
  $selectedColor?: string;
  $backgroundSelectedColor?: string;
}>`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.1875rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  border-radius: ${theme.radius.xsmall};
  border: 1px solid
    ${(props) =>
      props.$borderColor
        ? props.$borderColor
        : props.checked && props.disabled
          ? props.theme.colors.gray[500]
          : props.theme.colors.grayBlue[500]};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray[500]
      : props.checked && props.$selectedColor
        ? props.$selectedColor
        : props.theme.colors.grayBlue[500]};
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray[500]
      : props.checked && props.$backgroundSelectedColor
        ? props.$backgroundSelectedColor
        : props.theme.colors.base.white};
`;
