import { useTranslation } from "react-i18next";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { usePublishWritingTask } from "@/application/ui/pages/Dashboard/hooks/usePublishWritingTask.ts";
import { ReactComponent as SendIcon } from "@/assets/send.svg";

import type { WritingTaskContextMenuAction } from "../../../../WritingTaskContextMenuActions";

export const SendResults = (
  writingTask: TeacherWritingTask,
): WritingTaskContextMenuAction => {
  const { t } = useTranslation();
  const { publish, publishing } = usePublishWritingTask();

  return {
    label: t("teacherWritingTaskCardAction.send_results"),
    children: (
      <>
        <SendIcon />
        {t("teacherWritingTaskCardAction.send_results")}
      </>
    ),
    disabled: writingTask.status !== "ready_to_publish" || publishing,
    callback: () => publish(writingTask.id),
  };
};
