import { useRef, useState } from "react";

type Props = {
  popupIsVisibleBaseValue?: boolean;
  afterClosingAction?: () => void;
};

type UseRevisionPopup = {
  popupIsVisible: boolean;
  showPopupInViewMode: () => void;
  startClosingCountdown: () => void;
  cancelPopupClosing: () => void;
  closePopup: () => void;
};

export const useRevisionPopup = ({
  popupIsVisibleBaseValue,
  afterClosingAction,
}: Props = {}): UseRevisionPopup => {
  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(
    popupIsVisibleBaseValue ?? false,
  );
  const timeoutRef = useRef<NodeJS.Timeout>();

  const showPopupInViewMode = () => {
    setPopupIsVisible(true);
  };

  const startClosingCountdown = () => {
    if (!popupIsVisible) return;

    timeoutRef.current = setTimeout(() => {
      closePopup();
    }, 1000);
  };

  const closePopup = () => {
    clearTimeout(timeoutRef.current);
    setPopupIsVisible(false);
    afterClosingAction?.();
  };

  const cancelPopupClosing = () => {
    if (!popupIsVisible) return;

    clearTimeout(timeoutRef.current);
  };

  return {
    popupIsVisible,
    cancelPopupClosing,
    showPopupInViewMode,
    startClosingCountdown,
    closePopup,
  };
};
