import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { Card } from "@/application/ui/lib/Card.tsx";
import { ReactComponent as AddIcon } from "@/assets/plus-square.svg";

import { theme } from "../../../theme/theme";

interface Props {
  onCreateClick: () => void;
}

export const NoWritingTasks = ({ onCreateClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Container as="section">
      <div>
        <Title>{t("noWritingTaskTitle")}</Title>
        <Subtitle>{t("noWritingTaskSubtitle")}</Subtitle>
      </div>

      <div>
        <Button size="xl" onClick={onCreateClick}>
          {t("createWritingTask")}
          <AddIcon />
        </Button>
      </div>
    </Container>
  );
};

const Container = styled(Card)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
  gap: 24px;
  align-self: stretch;
`;

const Title = styled.h3`
  color: ${theme.colors.gray[900]};
  text-align: center;
  ${theme.typography.md.semibold}
`;

const Subtitle = styled.span`
  color: ${theme.colors.gray[600]};
  text-align: center;
  ${theme.typography.sm.regular}
`;
