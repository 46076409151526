import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../../../theme/theme";
import { useAssignments } from "../../RevisionStates/useAssignments";

export interface ErrorCategorySummaryProps {
  count: number;
  children?: ReactNode;
}

export const CommentsSummary = ({
  count,
  children,
}: ErrorCategorySummaryProps) => {
  const { t } = useTranslation();
  const { showComments } = useAssignments();

  return (
    <Container role="row" aria-label="comments" $visible={showComments}>
      <CommentColumn>
        <CommentIcon />
        <Name>{t("comments")}</Name>
      </CommentColumn>
      <Column>
        <CommentCount>
          {t("commentWithCount", {
            count,
          })}
        </CommentCount>
        {children}
      </Column>
    </Container>
  );
};

const Container = styled.div<{ $visible: boolean }>`
  .visibleIcon {
    opacity: 0;
  }

  &:hover {
    .visibleIcon {
      opacity: 100%;
    }
  }

  display: flex;
  justify-content: space-between;
  grid-template-columns: [left] 2fr [right] 2fr;
  color: ${theme.colors.gray[500]};
  opacity: ${(props) => (props.$visible ? "100%" : "60%")};
`;

const Column = styled.div`
  display: flex;
`;

const CommentColumn = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.p`
  margin-left: 8px;
`;

export const CommentCount = styled.p`
  font-weight: 300;
  margin-right: 8px;
  align-self: center;
`;

const CommentIcon = styled.div`
  background-color: rgba(127, 86, 217, 0.5);
  width: 8px;
  height: 8px;
`;
