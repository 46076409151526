import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Card } from "@/application/ui/lib/Card.tsx";
import { StudentWritingTaskStatusBadge } from "@/application/ui/pages/Dashboard/components/StudentWritingTaskStatus.tsx";
import { ReactComponent as LockIcon } from "@/assets/passcode-lock.svg";
import { ReactComponent as WriteIcon } from "@/assets/write-icon.svg";

import type { StudentWritingTask } from "../../../../domain/StudentDashboard";
import { Button } from "../../../lib/button/Button";
import { tooltipStyle } from "../../../lib/tooltip/tooltipStyle";
import { WritingTaskInformation } from "./WritingTaskInformation";

export const COMPOSITION_PAGE_URL = "/etudiant/composition";
export const ASSIGNMENT_PAGE_URL = "/etudiant/travail";

interface Props {
  writingTask: StudentWritingTask;
}

export const StudentWritingTaskCard = ({ writingTask }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const anchor = `anchor${writingTask.id}`;
  return (
    <WritingTask>
      <TaskHeader>
        <TaskHeaderColumn>
          {!["writing_in_progress", "todo"].includes(writingTask.status) && (
            <StudentWritingTaskStatusBadge status={writingTask.status} />
          )}
          <WritingTaskInformation.Type>
            {t(`redactionType.${writingTask.redactionType}`)}
          </WritingTaskInformation.Type>
        </TaskHeaderColumn>
        <TaskHeaderColumn>
          {writingTask.taskType === "summative" && (
            <>
              <div id={anchor}>
                <LockIcon />
              </div>
              <Tooltip
                anchorSelect={`#${anchor}`}
                place="bottom"
                offset={-5}
                style={tooltipStyle}
              >
                {t("securedModeTooltip")}
              </Tooltip>
            </>
          )}
        </TaskHeaderColumn>
      </TaskHeader>
      <TaskBody>
        <WritingTaskInformation>
          <WritingTaskInformation.Name>
            {writingTask.name}
          </WritingTaskInformation.Name>
          <WritingTaskInformation.Instructions>
            {writingTask.instructions}
          </WritingTaskInformation.Instructions>
        </WritingTaskInformation>

        {["writing_in_progress", "todo"].includes(writingTask.status) && (
          <Button
            hierarchy="primary"
            disabled={!writingTask.isOpen}
            onClick={() =>
              navigate(`${COMPOSITION_PAGE_URL}/${writingTask.id}`)
            }
          >
            <WriteIcon />
            {t("write")}
          </Button>
        )}

        {writingTask.assignmentId &&
          ["reviewed", "wait_for_revision"].includes(writingTask.status) && (
            <Button
              hierarchy="neutral"
              disabled={writingTask.status === "wait_for_revision"}
              onClick={() =>
                navigate(`${ASSIGNMENT_PAGE_URL}/${writingTask.assignmentId}`)
              }
            >
              {t("viewCopy")}
            </Button>
          )}
      </TaskBody>
    </WritingTask>
  );
};

const WritingTask = styled(Card)`
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  padding: 20px 16px;
`;

const TaskHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  gap: 24px;
  align-self: stretch;
`;

const TaskHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const TaskBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
