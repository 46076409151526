export enum Category {
  SPELLING = 'spelling',
  SYNTAX = 'syntax',
  PUNCTUATION = 'punctuation',
  GRAMMAR = 'grammar',
  VOCABULARY = 'vocabulary',
  UNPENALIZED = 'unpenalized',
  UNSPECIFIED = 'unspecified',
}

export enum RevisionCategoryGroups {
  SYNTAX,
  ORTHOGRAPHY,
}

export const CategoryGroups = {
  ORTHOGRAPHY: [Category.GRAMMAR, Category.SPELLING],
  SYNTAX: [Category.SYNTAX, Category.PUNCTUATION],
};
