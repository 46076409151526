import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { cloneDeep } from "lodash";
import { useState } from "react";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";
import type { DeconstructedSelection } from "@/application/ui/pages/Revision/components/TextCorrector/segments/NormalText.tsx";
import { NormalText } from "@/application/ui/pages/Revision/components/TextCorrector/segments/NormalText.tsx";
import { RevisedText } from "@/application/ui/pages/Revision/components/TextCorrector/segments/RevisedText.tsx";
import { UnpenalizedText } from "@/application/ui/pages/Revision/components/TextCorrector/segments/UnpenalizedText.tsx";
import type { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import {
  generateId,
  Revision,
} from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { useAssignments } from "../../RevisionStates/useAssignments";
import { CommentsOverlay } from "./CommentsOverlay";

type TextCorrectorProps = {
  revisedAssignmentContentState: RevisedAssignmentContentState;
  comments: TeacherComment[];
  onContentStateChange: (
    revisedAssignmentContentState: RevisedAssignmentContentState,
  ) => void;
  onCommentsChange: (comments: TeacherComment[]) => void;
  readOnly?: boolean;
};

export const TextCorrector = ({
  revisedAssignmentContentState,
  comments,
  onContentStateChange,
  onCommentsChange,
  readOnly = false,
}: TextCorrectorProps) => {
  const { showCategory, showComments, showRevision, currentMode } =
    useAssignments();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>();

  const insertNewRevision = (
    index: number,
    { before, selection, after }: DeconstructedSelection,
  ) => {
    if (readOnly) {
      return;
    }

    const elements = [];

    if (before.length) {
      elements.push(before);
    }
    elements.push(Revision.create([selection]));
    if (after.length) {
      elements.push(after);
    }

    onContentStateChange(
      revisedAssignmentContentState.insertRevisionAt(index, elements),
    );
  };

  const getSelectionOffsetRelativeTo = (
    parentElement: Node,
    currentNode?: Node,
  ): number => {
    let currentSelection,
      currentRange,
      offset = 0,
      prevSibling,
      nodeContent;

    if (!currentNode) {
      currentSelection = window.getSelection();
      if (!currentSelection) {
        return -1;
      }

      currentRange = currentSelection.getRangeAt(0);
      currentNode = currentRange.startContainer;
      offset += currentRange.startOffset;
    }

    if (currentNode === parentElement) {
      return offset;
    }

    if (!parentElement.contains(currentNode)) {
      return -1;
    }

    while ((prevSibling = (prevSibling || currentNode).previousSibling)) {
      nodeContent = prevSibling.textContent || prevSibling.nodeValue || "";
      offset += nodeContent.length;
    }

    return (
      offset +
      getSelectionOffsetRelativeTo(parentElement, currentNode.parentNode!)
    );
  };

  const addComment = () => {
    if (readOnly || currentMode !== "comment") {
      return;
    }

    const selection = window.getSelection();
    if (selection && selection.toString().length > 0 && containerRef) {
      const start = getSelectionOffsetRelativeTo(containerRef);
      if (start < 0) return;
      const end = start + selection.toString().length;

      comments.push({
        id: generateId(),
        startIndex: start,
        startOffset: 0,
        endIndex: end,
        endOffset: 0,
        text: "",
      });
      onCommentsChange(cloneDeep(comments));

      if (selection.removeAllRanges) {
        selection.removeAllRanges();
      } else if (selection.empty) {
        selection.empty();
      }
    }
  };

  return (
    <Container
      ref={(containerRef) => setContainerRef(containerRef)}
      data-testid="text-corrector"
      onMouseUp={addComment}
    >
      {containerRef && showComments && (
        <CommentsOverlay
          readOnly={readOnly}
          comments={comments}
          onChange={onCommentsChange}
          getParent={() => containerRef}
          mode={currentMode}
        />
      )}
      {revisedAssignmentContentState.segments.map((segment, index) => {
        const isRevision = segment instanceof Revision;
        const shouldDisplay =
          isRevision &&
          ((segment.penalized
            ? showCategory(segment.category)
            : showCategory(Category.UNPENALIZED)) ||
            segment.category === Category.UNSPECIFIED);

        if (shouldDisplay) {
          if (currentMode === "default") {
            return (
              <RevisedText
                key={segment.id}
                revision={segment}
                onChange={(revision) =>
                  onContentStateChange(
                    revisedAssignmentContentState.replaceAt(index, revision),
                  )
                }
                onRemove={() =>
                  onContentStateChange(
                    revisedAssignmentContentState.removeRevisionAt(index),
                  )
                }
                readOnly={readOnly}
                displayRevision={showRevision}
              />
            );
          } else {
            return (
              <UnpenalizedText
                key={segment.id}
                revision={segment}
                onChange={(revision) => {
                  if (currentMode === "unpenalized") {
                    onContentStateChange(
                      revisedAssignmentContentState.replaceAt(index, revision),
                    );
                  }
                }}
                isUnpenalizesMode={currentMode === "unpenalized"}
                displayRevision={showRevision}
                readOnly={readOnly}
                isCommentMode={currentMode === "comment"}
              />
            );
          }
        }

        return (
          <NormalText
            key={`top-${index}`}
            text={segment.toString()}
            onSelect={(deconstructedSelection) =>
              currentMode === "default" &&
              insertNewRevision(index, deconstructedSelection)
            }
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  text-justify: inter-character;
  position: relative;
`;
