import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

import { WritingTaskStatusClient } from "../../../../client/WritingTaskStatusClient";

export function useDistributeWritingTask() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate } = useMutation(
    (writingTaskId: string) =>
      WritingTaskStatusClient.distributeWritingTask(writingTaskId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(t("draftWritingTask.distribute.success"));
      },
      onError: () => {
        ToastService.showError(t("draftWritingTask.distribute.fail"));
      },
    },
  );
  return { distribute: mutate };
}
