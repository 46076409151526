import type { PropsWithChildren } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useAuthenticatingUser } from "@/application/authentication/hooks/useAuthenticatingUser.ts";

import { LoadingPage } from "../ui/lib/spinner/LoadingPage";

export function RequireAuth({ children }: PropsWithChildren) {
  const location = useLocation();
  const { user, isLoading } = useAuthenticatingUser();

  if (isLoading) {
    return (
      <PageWrapper>
        <LoadingPage />
      </PageWrapper>
    );
  }

  if (!user) {
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
}

const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;
